import { AUTH_API_URL } from '@looxr/constants';

const ONELOGIN_PART = '/api/onelogin/user';
const FILES_PART = '/api/files';

const ApiService = {
  /**
   * Change Password of current session user
   * @return {object}
   */
  changePassword: async (newPassword) => {
    const url = `${AUTH_API_URL}${ONELOGIN_PART}/update-password`;
    const body = {
      password: newPassword
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        const jsonResponse = await response.json();

        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  initLeakPDFProcess: async (id) => {
    const url = `${AUTH_API_URL}${FILES_PART}/leak-pdf/${id}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  generateQrCodePDF: async (customer, qrcodes, pdfConfig) => {
    const body = {
      customer: customer.id,
      config: pdfConfig,
      ids: qrcodes.map((qrcode) => qrcode.id)
    };

    const url = `${AUTH_API_URL}${FILES_PART}/qrcode-pdf`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        const jsonResponse = await response.json();

        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  getLabelXMLs: async (customer, qrcodes) => {
    const body = {
      customer: customer.id,
      ids: qrcodes.map((qrcode) => qrcode.id)
    };

    const url = `${AUTH_API_URL}${FILES_PART}/qrcode-labels`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse.labels;
      }

      return [];
    } catch (e) {
      console.log(e);
      return [];
    }
  },

  sendLicenseMail: async (sendToMail) => {
    const body = {
      sendToMail
    };

    const url = `${AUTH_API_URL}${FILES_PART}/send-license-mail`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        return true;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  initLeakComponentExportProcess: async (progressID) => {
    const url = `${AUTH_API_URL}${FILES_PART}/generate-component-list/${progressID}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
};

export default ApiService;
