import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Button, Label, Select, TextInput } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import QrPdfFormVisual from './QrPdfFormVisual';

function QrPdfForm({ onUpdate, onCancel }) {
  const tn = useTranslation();
  const [valid, setValid] = useState(false);
  const [formTarget, setFormTarget] = useState('');

  const [options, setOptions] = useState({
    debug: false,
    emptyStartCols: 0,
    orientation: 'portrait',
    rows: 5,
    columns: 6,
    pageOffsetTop: 2,
    pageOffsetLeft: '0',
    labelWidth: 30,
    labelHeight: 45,
    colSpace: 5.5,
    rowSpace: 17
  });

  // Update valid when form state value updates
  useEffect(() => {
    let valid = true;

    // check if every field is set first
    if (
      options.width === '' ||
      options.height === '' ||
      options.columns === '' ||
      options.rows === '' ||
      options.emptyStartCols === '' ||
      options.colSpace === '' ||
      options.rowSpace === '' ||
      options.pageOffsetLeft === '' ||
      options.pageOffsetTop === ''
    ) {
      valid = false;
    }

    // startOffset cant be >= cls * row
    if (valid && options.emptyStartCols >= options.columns * options.rows) {
      valid = false;
    }
    setValid(valid);
  }, [options]);

  /**
   * send an update of values
   */
  const sendUpdate = (e) => {
    e.preventDefault();
    const update = { ...options };
    onUpdate(update);
  };

  const updateOptions = (key, value) => {
    const update = { ...options };
    update[key] = value;
    setOptions(update);
  };

  return (
    <Box display="flex" width="100%" direction="row">
      <form noValidate onSubmit={sendUpdate}>
        <Box display="flex" direction="row">
          <Box width="50%">
            <Box display="flex" width="100%" wrap="wrap" direction="row">
              <Box display="flex" width="100%" direction="row">
                <Box display="flex" width="100%" wrap="wrap">
                  <Box padding={2} width="100%">
                    <Label htmlFor="orientation">
                      {tn('leak.page.qrcodes.pdfOverlay.format')}
                      {/* Format */}
                    </Label>
                    <Select
                      id="orientation"
                      name="orientation"
                      onChange={(e) => updateOptions('orientation', e.value)}
                      value={options.orientation ? options.orientation : ''}
                      options={[{ label: 'A4', value: 'portrait' }]}
                      size="sm"
                    />
                    {/*
                       { label: 'A4 Hochformat', value: 'portrait' },
                       { label: 'A4 Querformat', value: 'landscape' }
                    */}
                  </Box>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  marginLeft={3}
                  onMouseEnter={() => {
                    setFormTarget('emptyStartCols');
                  }}
                  onMouseLeave={() => {
                    setFormTarget('');
                  }}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="emptyStartCols">
                      {tn('leak.page.qrcodes.pdfOverlay.emptyStartCols')}
                      {/* Leere Labels ab Start */}
                    </Label>
                    <TextInput
                      id="emptyStartCols"
                      name="emptyStartCols"
                      value={options?.emptyStartCols !== undefined ? options?.emptyStartCols : ''}
                      onChange={(e) => updateOptions('emptyStartCols', e.currentTarget.value)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" width="100%" direction="row">
                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  onMouseEnter={() => setFormTarget('rows')}
                  onMouseLeave={() => setFormTarget('')}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="rows">
                      {tn('leak.page.qrcodes.pdfOverlay.rows')}
                      {/* Zeilen */}
                    </Label>
                    <TextInput
                      id="rows"
                      name="rows"
                      type="number"
                      value={options?.rows ? options?.rows : ''}
                      onChange={(e) => updateOptions('rows', e.currentTarget.value)}
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  marginLeft={3}
                  onMouseEnter={() => setFormTarget('columns')}
                  onMouseLeave={() => setFormTarget('')}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="columns">
                      {tn('leak.page.qrcodes.pdfOverlay.cols')}
                      {/* Spalten */}
                    </Label>
                    <TextInput
                      id="columns"
                      name="columns"
                      type="number"
                      value={options?.columns ? options?.columns : ''}
                      onChange={(e) => updateOptions('columns', e.currentTarget.value)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" width="100%" direction="row">
                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  onMouseEnter={() => setFormTarget('pageOffsetTop')}
                  onMouseLeave={() => setFormTarget('')}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="pageOffsetTop">
                      {tn('leak.page.qrcodes.pdfOverlay.pageOffsetTop')}
                      {/* Seitenabstand oben */}
                    </Label>
                    <TextInput
                      id="pageOffsetTop"
                      name="pageOffsetTop"
                      type="number"
                      value={options?.pageOffsetTop ? options?.pageOffsetTop : ''}
                      onChange={(e) => updateOptions('pageOffsetTop', e.currentTarget.value)}
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  marginLeft={3}
                  onMouseEnter={() => setFormTarget('pageOffsetLeft')}
                  onMouseLeave={() => setFormTarget('')}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="pageOffsetLeft">
                      {tn('leak.page.qrcodes.pdfOverlay.pageOffsetLeft')}
                      {/* Seitenabstand links */}
                    </Label>
                    <TextInput
                      id="pageOffsetLeft"
                      name="pageOffsetLeft"
                      type="number"
                      value={options?.pageOffsetLeft ? options?.pageOffsetLeft : ''}
                      onChange={(e) => updateOptions('pageOffsetLeft', e.currentTarget.value)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" width="100%" direction="row">
                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  onMouseEnter={() => setFormTarget('labelWidth')}
                  onMouseLeave={() => setFormTarget('')}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="labelWidth">
                      {tn('leak.page.qrcodes.pdfOverlay.labelWidth')}
                      {/* Label Breite */}
                    </Label>
                    <TextInput
                      id="labelWidth"
                      name="labelWidth"
                      type="number"
                      value={options?.labelWidth ? options?.labelWidth : ''}
                      onChange={(e) => updateOptions('labelWidth', e.currentTarget.value)}
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  marginLeft={3}
                  onMouseEnter={() => setFormTarget('labelHeight')}
                  onMouseLeave={() => setFormTarget('')}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="labelHeight">
                      {tn('leak.page.qrcodes.pdfOverlay.labelHeight')}
                      {/* Label Höhe */}
                    </Label>
                    <TextInput
                      id="labelHeight"
                      name="labelHeight"
                      type="number"
                      value={options?.labelHeight ? options?.labelHeight : ''}
                      onChange={(e) => updateOptions('labelHeight', e.currentTarget.value)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" width="100%" direction="row">
                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  onMouseEnter={() => setFormTarget('colSpace')}
                  onMouseLeave={() => setFormTarget('')}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="colSpace">
                      {tn('leak.page.qrcodes.pdfOverlay.colSpace')}
                      {/* Abstand zwischen Spalten */}
                    </Label>
                    <TextInput
                      id="colSpace"
                      name="colSpace"
                      type="number"
                      value={options?.colSpace ? options?.colSpace : ''}
                      onChange={(e) => updateOptions('colSpace', e.currentTarget.value)}
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  width="100%"
                  wrap="wrap"
                  marginLeft={3}
                  onMouseEnter={() => setFormTarget('rowSpace')}
                  onMouseLeave={() => setFormTarget('')}
                >
                  <Box padding={2} width="100%">
                    <Label htmlFor="rowSpace">
                      {tn('leak.page.qrcodes.pdfOverlay.rowSpace')}
                      {/* Abstand zwischen Zeilen */}
                    </Label>
                    <TextInput
                      id="rowSpace"
                      name="rowSpace"
                      type="number"
                      value={options?.rowSpace ? options?.rowSpace : ''}
                      onChange={(e) => updateOptions('rowSpace', e.currentTarget.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box width="50%">
            <QrPdfFormVisual visualTarget={formTarget} />
          </Box>
        </Box>

        <Box marginTop={3} display="flex" direction="row" justify="center">
          <Button
            disabled={!valid}
            type="submit"
            text={tn('leak.page.qrcodes.pdfOverlay.buttons.create')} // "PDF erstellen"
            size="sm"
            width={200}
          />
          <Box marginLeft={3}>
            <Button
              text={tn('general.cancel')} // "Abbrechen"
              onClick={onCancel}
              size="sm"
              width={200}
              background="purple"
              inline
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
}

QrPdfForm.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default QrPdfForm;
