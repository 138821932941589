import { CUSTOMER_TYPES, LEAK_USER_ROLES, ROLES } from '@looxr/constants';
import { ACTIVE_CUSTOMER } from '../constants/Storage';
import StorageService from './StorageService';

const AppStateService = {
  user: null,
  customer: null,
  activeCustomer: null,

  activeCustomerChangeListner: {},

  registerActiveCustomerChangedListener(handler) {
    const id = `_${Math.random().toString(36).substr(2, 9)}`;
    const listener = {
      id,
      handler,
      remove: () => {
        delete AppStateService.activeCustomerChangeListner[id];
      }
    };

    AppStateService.activeCustomerChangeListner[id] = listener;

    return listener;
  },

  emitActiveCustomerChange() {
    const listenerIDs = Object.keys(AppStateService.activeCustomerChangeListner);

    for (let i = 0; i < listenerIDs.length; i += 1) {
      const listenerID = listenerIDs[i];
      const listener = AppStateService.activeCustomerChangeListner[listenerID];

      listener.handler(AppStateService.activeCustomer);
    }
  },

  /**
   * Reset app state
   */
  reset() {
    this.removeUser();
  },

  /**
   * Set user
   */
  setUser(user) {
    this.user = user;
  },

  /**
   * Remove current user
   */
  removeUser() {
    this.user = null;
    this.customer = null;
    AppStateService.removeActiveCustomer();
  },

  /**
   * Check if a user is present
   */
  hasUser() {
    return this.user !== null;
  },

  /**
   * Set customer
   */
  setCustomer(customer) {
    this.customer = customer;
  },

  /**
   * Set active customer
   */
  setActiveCustomer(customer) {
    this.activeCustomer = customer;
    AppStateService.emitActiveCustomerChange();
    StorageService.save(ACTIVE_CUSTOMER, customer.id);
  },

  /**
   * Remove active customer
   */
  removeActiveCustomer() {
    this.activeCustomer = null;
    StorageService.remove(ACTIVE_CUSTOMER);
  },

  isLOOXR() {
    return this.user && this.user.role === ROLES.looxr;
  },

  isLeakAdmin() {
    return this.user && this.user.leakLogin.role === LEAK_USER_ROLES.admin;
  },

  isServicePartner() {
    return AppStateService.customer.type === CUSTOMER_TYPES.service;
  },

  isOEM() {
    return AppStateService.customer.type === CUSTOMER_TYPES.oem;
  },

  async hasSavedActiveCustomer() {
    const has = await StorageService.has(ACTIVE_CUSTOMER);
    return has;
  },

  async getSavedActiveCustomer() {
    return StorageService.get(ACTIVE_CUSTOMER);
  }
};

export default AppStateService;
