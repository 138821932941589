import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Button, DateRangeInput, Label, Modal } from '@looxr/components';
import { useLanguage, useTranslation } from '@looxr/utils';

function PropPdfModal({ show, onClose, onSubmit }) {
  const tn = useTranslation();
  const language = useLanguage();

  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [propDateRange, setPropDateRange] = useState({
    from: null,
    to: null
  });

  useEffect(() => {
    if (!show) {
      setValid(false);
      setTouched(false);
      setPropDateRange({
        from: null,
        to: null
      });
    }
  }, [show]);

  useEffect(() => {
    try {
      if (
        propDateRange.from !== null &&
        propDateRange.to !== null &&
        propDateRange.from instanceof Date &&
        propDateRange.to instanceof Date &&
        propDateRange.to.getTime() > propDateRange.from.getTime()
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    } catch (e) {
      setValid(false);
    }
  }, [propDateRange]);

  const submit = () => {
    onSubmit(propDateRange);
  };

  const updateDateRange = (newValue) => {
    if (propDateRange.from !== null && propDateRange.to !== null) {
      setTouched(true);
    }

    setPropDateRange(newValue);
  };

  return (
    <Modal
      size={800}
      show={show}
      onClose={onClose}
      closeOnBackdrop={true}
      closeOnEsc={true}
      showModalCloseIcon={true}
    >
      <Box display="flex" width="100%" direction="column" zIndex={100}>
        <Box display="flex" direction="column">
          <Label htmlFor="propPdfDateRange" required valid={!touched || (touched && valid)}>
            {tn('leak.page.dashboard.filterForm.propViewModal.label')}
          </Label>
          <Box display="flex" direction="row">
            <DateRangeInput
              locale={language}
              name="propPdfDateRange"
              range={propDateRange}
              onChange={(dateRange) => updateDateRange(dateRange)}
            />
            <Box paddingX={2}>
              <Button
                background="blue"
                type="button"
                inline
                width={200}
                text={tn('leak.page.dashboard.filterForm.propViewModal.button')}
                onClick={submit}
                disabled={!valid}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

PropPdfModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

PropPdfModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export default PropPdfModal;
