import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Box } from '@looxr/components';

const StyledFormVisualContainer = styled(Box)`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  height: 381px;
  margin-top: 36px;
`;

const StyledFormVisual = styled(Box)``;
const StyledRow = styled(Box)``;

const StyledCol = styled(Box)`
  width: 66px;
  height: 66px;
  background-color: ${(props) => props.theme.colors.grey};
`;

const Visual = styled(Box)`
  background-color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.white};
  width: ${(props) => (props.width ? props.width : '33px')};
  height: ${(props) => (props.height ? props.height : '33px')};
  top: ${(props) => (props.top ? props.top : '0px')};
  left: ${(props) => (props.left ? props.left : '0px')};
  position: absolute;

  ${(props) =>
    props.anim === 'height' &&
    css`
      animation-name: heightAnim;
      animation-duration: 4s;
      animation-iteration-count: infinite;

      @keyframes heightAnim {
        0% {
          height: 66px;
        }
        50% {
          height: 150px;
        }
        100% {
          height: 66px;
        }
      }
    `}

  ${(props) =>
    props.anim === 'width' &&
    css`
      animation-name: widthAnim;
      animation-duration: 4s;
      animation-iteration-count: infinite;

      @keyframes widthAnim {
        0% {
          width: 66px;
        }
        50% {
          width: 150px;
        }
        100% {
          width: 66px;
        }
      }
    `}

    ${(props) =>
    !props.anim &&
    css`
      animation-name: pulseAnim;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      position: absolute;

      @keyframes pulseAnim {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `}
`;

function QrPdfFormVisual({ visualTarget }) {
  const renderVisual = () => {
    let render = null;
    switch (visualTarget) {
      case 'emptyStartCols':
        render = <Visual color="white" width="66px" height="66px" top="16px" left="22px" />;
        break;
      case 'rows':
        render = <Visual color="white" width="66px" height="337px" top="16px" left="22px" />;
        break;
      case 'columns':
        render = <Visual color="white" width="350px" height="66px" top="16px" left="22px" />;
        break;
      case 'pageOffsetTop':
        render = <Visual color="green" width="378px" height="15px" top="1px" left="1px" />;
        break;
      case 'pageOffsetLeft':
        render = <Visual color="green" width="22px" height="377px" top="1px" left="1px" />;
        break;
      case 'colSpace':
        render = <Visual color="green" width="24px" height="66px" top="16px" left="87px" />;
        break;
      case 'rowSpace':
        render = <Visual color="green" width="335px" height="22px" top="83px" left="22px" />;
        break;
      case 'labelWidth':
        render = (
          <Visual color="green" width="66px" height="66px" top="16px" left="22px" anim="width" />
        );
        break;
      case 'labelHeight':
        render = (
          <Visual color="green" width="66px" height="66px" top="16px" left="22px" anim="height" />
        );
        break;
      default:
        render = null;
        break;
    }
    return render;
  };

  return (
    <StyledFormVisualContainer display="flex" width="100%">
      <StyledFormVisual display="flex" width="100%" direction="column">
        <StyledRow display="flex" width="100%" direction="row" justify="space-evenly" marginTop={4}>
          <StyledCol />
          <StyledCol />
          <StyledCol />
          <StyledCol />
        </StyledRow>
        <StyledRow display="flex" width="100%" direction="row" justify="space-evenly" marginTop={6}>
          <StyledCol />
          <StyledCol />
          <StyledCol />
          <StyledCol />
        </StyledRow>
        <StyledRow display="flex" width="100%" direction="row" justify="space-evenly" marginTop={6}>
          <StyledCol />
          <StyledCol />
          <StyledCol />
          <StyledCol />
        </StyledRow>
        <StyledRow display="flex" width="100%" direction="row" justify="space-evenly" marginTop={6}>
          <StyledCol />
          <StyledCol />
          <StyledCol />
          <StyledCol />
        </StyledRow>
      </StyledFormVisual>

      {renderVisual()}
    </StyledFormVisualContainer>
  );
}

QrPdfFormVisual.propTypes = {
  visualTarget: PropTypes.string
};

QrPdfFormVisual.defaultProps = {
  visualTarget: ''
};

export default QrPdfFormVisual;
