import { useCallback, useEffect, useState } from 'react';
import { LEAK_FILTER } from '../constants';
import StorageService from '../services/StorageService';

function useLeakFilter() {
  const [filter, setFilter] = useState({});
  const [loadingFilter, setLoadingFilter] = useState(true);

  const getDefaultFilter = useCallback(() => {
    return {
      serial: '',
      location: '',
      valence: 'all',
      machine: '',
      createdAt: { from: null, to: null },
      repairedAt: { from: null, to: null },
      repaired: 'all',
      check: 'all',
      hasComponents: 'all'
    };
  }, []);

  // Effect to load filter from local storage or use default filter
  useEffect(() => {
    const load = async () => {
      let initialFilter = getDefaultFilter();

      const storageFilter = await loadFilter();

      if (storageFilter !== null) {
        initialFilter = storageFilter;
      }

      setFilter(initialFilter);
      setLoadingFilter(false);
    };

    load();
  }, [getDefaultFilter]);

  const resetFilter = () => {
    const defaultFilter = getDefaultFilter();
    setFilter(defaultFilter);

    StorageService.remove(LEAK_FILTER);
  };

  const updateFilter = (data) => {
    const update = { ...filter, ...data };
    setFilter(update);
    saveFilter(update);
  };

  const loadFilter = async () => {
    const storageFilter = await StorageService.getObject(LEAK_FILTER);

    if (storageFilter !== null) {
      if (storageFilter.createdAt.from !== null) {
        storageFilter.createdAt.from = new Date(storageFilter.createdAt.from);
      }

      if (storageFilter.createdAt.to !== null) {
        storageFilter.createdAt.to = new Date(storageFilter.createdAt.to);
      }

      if (storageFilter.repairedAt.from !== null) {
        storageFilter.repairedAt.from = new Date(storageFilter.repairedAt.from);
      }

      if (storageFilter.repairedAt.to !== null) {
        storageFilter.repairedAt.to = new Date(storageFilter.repairedAt.to);
      }

      return storageFilter;
    }

    return null;
  };

  const saveFilter = (data) => {
    const toSave = { ...data };

    if (toSave.createdAt && toSave.createdAt.from) {
      if (toSave.createdAt.from instanceof Date) {
        toSave.createdAt.from = toSave.createdAt.from.getTime();
      }
    }

    if (toSave.createdAt && toSave.createdAt.to) {
      if (toSave.createdAt.to instanceof Date) {
        toSave.createdAt.to = toSave.createdAt.to.getTime();
      }
    }

    if (toSave.repairedAt.from !== null) {
      if (toSave.repairedAt.from instanceof Date) {
        toSave.repairedAt.from = toSave.repairedAt.from.getTime();
      }
    }

    if (toSave.repairedAt.to !== null) {
      if (toSave.repairedAt.to instanceof Date) {
        toSave.repairedAt.to = toSave.repairedAt.to.getTime();
      }
    }

    StorageService.saveObject(LEAK_FILTER, toSave);
  };

  const isDefaultFilter = () => {
    const defaultFilter = getDefaultFilter();

    if (
      filter.serial !== defaultFilter.serial ||
      filter.location !== defaultFilter.location ||
      filter.valence !== defaultFilter.valence ||
      filter.machine !== defaultFilter.machine ||
      filter.check !== defaultFilter.check ||
      filter.hasComponents !== defaultFilter.hasComponents ||
      filter.repaired !== defaultFilter.repaired ||
      JSON.stringify(filter.createdAt) !== JSON.stringify(defaultFilter.createdAt) ||
      JSON.stringify(filter.repairedAt) !== JSON.stringify(defaultFilter.repairedAt)
    ) {
      return false;
    }

    return true;
  };

  return {
    filter,
    loadingFilter,
    resetFilter,
    updateFilter,
    isDefaultFilter
  };
}

export default useLeakFilter;
