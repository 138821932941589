function useNumberFormat() {
  const formatNumber = (number, digits, round) => {
    // const { currentLocale } = intl.options;
    // @TODO use local dynamic
    const currentLocale = 'de';

    let formatted = number;

    if (round === true) {
      formatted = number.toFixed(digits);
    }

    formatted = new Intl.NumberFormat(currentLocale, {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits
    }).format(number);

    return formatted;
  };

  const parseAsFloat = (string) => {
    // @NOTE if using <input type="number" /> the format will be XX.XX
    let input = string;
    if (input.includes(',')) {
      input = input.replace(',', '');
    }

    return parseFloat(input);
  };

  return {
    formatNumber,
    parseAsFloat
  };
}

export default useNumberFormat;
