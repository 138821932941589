import { useEffect } from 'react';
import AppStateService from '../services/AppStateService';

function useActiveCustomerChanged(handler) {
  useEffect(() => {
    const sub = AppStateService.registerActiveCustomerChangedListener((customer) => {
      handler(customer);
    });

    return () => sub.remove();
  }, [handler]);
}

export default useActiveCustomerChanged;
