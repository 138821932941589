export const getLeakRepairStatusText = (leak) => {
  let statusText = 'general.repairStatus.notRepaired';

  if (leak.repaired === true) {
    statusText = 'general.repairStatus.repaired';
  }

  if (leak.notRepairable === true) {
    statusText = 'general.repairStatus.notRepairable';
  }

  return statusText;
};
