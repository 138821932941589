/* eslint-disable no-promise-executor-return */
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import firebase from 'firebase/app';
import moment from 'moment';
import { Collections, FirebaseConfig } from '@looxr/constants';

const FirebaseService = {
  ref: null,

  /**
   * Create a firebase timestamp
   */
  getTimestamp(srcDate) {
    const fromDate = srcDate !== undefined ? srcDate : new Date();
    return firebase.firestore.Timestamp.fromDate(fromDate);
  },

  /**
   * Get storage image download url by storage pahth / uri
   * @param {*} uri
   */
  async getImageDownloadUrl(uri) {
    try {
      const storageRef = firebase.storage().ref();
      const url = await storageRef.child(uri).getDownloadURL();
      return url;
    } catch (e) {
      console.log('error getting download url => ', e);
      return false;
    }
  },

  /**
   * Get storage image download url by storage pahth / uri
   * @param {*} uri
   */
  async getImageThumbDownloadUrl(uri) {
    try {
      const thumbUri = `${uri.replace('leakImages', 'leakImages/thumbs')}_600x600`;
      const storageRef = firebase.storage().ref();
      const url = await storageRef.child(thumbUri).getDownloadURL();
      return url;
    } catch (e) {
      console.log('error getting download url => ', e);
      return false;
    }
  },

  /**
   * Upload image from Base64 to firebase storage
   * @param {*} path
   * @param {*} base64Img
   */
  async uploadImage(path, base64Img) {
    try {
      const storageRef = firebase.storage().ref();
      const imgRef = storageRef.child(path);
      await imgRef.putString(base64Img, 'data_url');
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async deleteImage(path) {
    try {
      const storageRef = firebase.storage().ref();
      const imgRef = storageRef.child(path);
      await imgRef.delete();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  /**
   * Init Firebase
   */
  async init() {
    if (this.ref === null) {
      FirebaseService.ref = firebase.initializeApp(FirebaseConfig);
    }

    return true;
  },

  /**
   * Sign in with custom token
   */
  async signInWithToken(token) {
    return new Promise((resolve, reject) => {
      return this.ref
        .auth()
        .signInWithCustomToken(token)
        .then((user) => resolve(user))
        .catch((err) => reject(err));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      return this.ref
        .auth()
        .signOut()
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  },

  /**
   * Create / Update Document
   */
  async upsertDoc(id, data, collection) {
    try {
      let result;
      let resultDoc;
      if (id) {
        resultDoc = await this.ref.firestore().collection(collection).doc(id).set(data);
        result = true;
      } else {
        resultDoc = await this.ref.firestore().collection(collection).add(data);

        result = resultDoc.id;
      }

      return result;
    } catch (error) {
      console.log(`Error writing ${collection} with data - [${id}]`, data);
      console.log(error);
      return false;
    }
  },

  /**
   * Get single user by uid
   */
  async getUser(uid) {
    return FirebaseService.ref.firestore().collection(Collections.user).doc(uid).get();
  },

  /**
   * Get single user query by uid
   */
  getUserQuery(uid) {
    return FirebaseService.ref.firestore().collection(Collections.user).doc(uid);
  },

  /**
   * Get all customer documents
   */
  async getLeaks(customer) {
    return FirebaseService.ref
      .firestore()
      .collection(Collections.leak)
      .where('customer', '==', customer.ref)
      .get();
  },

  /**
   * Get all qrcode documents of a customer
   */
  async getQRCodes(customer, loadAll) {
    let queryRef = FirebaseService.ref
      .firestore()
      .collection(Collections.qrcode)
      .where('customer', '==', customer.ref)
      .orderBy('createdAt', 'desc');

    if (loadAll === false) {
      const startDate = moment().subtract(1, 'years').startOf('year').toDate();

      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);

      const startDateTimestamp = FirebaseService.getTimestamp(startDate);
      const endDateTimestamp = FirebaseService.getTimestamp();

      queryRef = queryRef.endAt(startDateTimestamp);
      queryRef = queryRef.startAt(endDateTimestamp);
    }

    return queryRef.get();
  },

  getQRCodeQuery(customer) {
    return FirebaseService.ref
      .firestore()
      .collection(Collections.qrcode)
      .where('customer', '==', customer.ref);
  },

  /**
   * Get query to list Leak PDF's of user for customer
   */
  getLeakPDFQuery(customer, user) {
    return FirebaseService.ref
      .firestore()
      .collection(Collections.leakPDF)
      .where('customer', '==', customer.ref)
      .where('createdBy', '==', user.ref)
      .orderBy('createdAt', 'desc')
      .limit(3);
  },

  /**
   * Get query to list Leak PDF's of user for customer
   */
  getLeakComponentExportQuery(customer, user) {
    return FirebaseService.ref
      .firestore()
      .collection(Collections.leakComponentExport)
      .where('customer', '==', customer.ref)
      .where('createdBy', '==', user.ref)
      .orderBy('createdAt', 'desc')
      .limit(3);
  },

  /**
   * Get customer by id
   */
  async getCustomer(id) {
    return FirebaseService.ref.firestore().collection(Collections.customer).doc(id).get();
  },

  /**
   * Get all customer documents
   */
  async getCustomers() {
    return FirebaseService.ref.firestore().collection(Collections.customer).get();
  },

  /**
   * Update a qr code as printed
   * @param {*} id
   * @returns
   */
  async updateQrCodePrinted(id) {
    return FirebaseService.ref
      .firestore()
      .collection(Collections.qrcode)
      .doc(id)
      .update({ printed: true, printedAt: FirebaseService.getTimestamp() });
  },

  /**
   * Update user profile data
   */
  async updateProfileData(id, data) {
    try {
      const result = await this.ref.firestore().collection(Collections.user).doc(id).update(data);

      return result;
    } catch (error) {
      console.log(`Error updating profile of user - [${id}]`, data);
      console.log(error);
      return false;
    }
  }
};

export default FirebaseService;
