import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Box, Button, LoadingBox, Modal, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { ApiService, AppStateService, DymoService } from '../services';

const ProgressBar = styled(Box)`
  height: 30px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  background-color: ${(props) => props.theme.colors.grey2};
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 1px;
`;

const DoneBar = styled(Box)`
  position: relative;
  top: 0px;
  height: 26px;
  width: ${(props) => `${props.done}%`};
  background-color: ${(props) => props.theme.colors.green};
  transition: width 0.5s;
`;

function QrDymoModal({ show, onClose, qrCodes }) {
  const tn = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dymoRdy, setDymoRdy] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [done, setDone] = useState(0);

  useEffect(() => {
    const initDymo = async () => {
      setLoading(true);
      await DymoService.init();
      setDymoRdy(DymoService.canPrint);
      setLoading(false);
    };

    if (show && qrCodes.length > 0) {
      initDymo();
    }
  }, [show, qrCodes]);

  const saveAndClose = () => {
    onClose(true);
  };

  const initPrint = async () => {
    setLoading(true);

    // sort qr codes by serial
    const sorted = [...qrCodes];

    sorted.sort((a, b) => {
      if (a.serial < b.serial) {
        return 1;
      }

      if (a.serial > b.serial) {
        return -1;
      }

      return 0;
    });

    const customer = AppStateService.activeCustomer;
    const labelXMLs = await ApiService.getLabelXMLs(customer, sorted);

    setDone(0);
    setIsPrinting(true);
    setLoading(false);

    if (DymoService.canPrint && DymoService.printer) {
      for (let i = 0; i < labelXMLs.length; i += 1) {
        const xml = labelXMLs[i];
        // eslint-disable-next-line no-await-in-loop
        await printLabel(xml);
        setDone(i + 1);
      }
    }

    setTimeout(() => {
      setDone(0);
      setIsPrinting(false);
      saveAndClose();
    }, 500);
  };

  const printLabel = async (xml) => {
    return new Promise(async (resolve) => {
      await DymoService.print(xml);
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  return (
    <Modal
      size={500}
      show={show}
      onClose={onClose}
      closeOnBackdrop={false}
      closeOnEsc={false}
      showModalCloseIcon={false}
    >
      <LoadingBox
        loading={loading}
        display="flex"
        width="100%"
        height="250px"
        direction="column"
        justify="center"
        alignItems="center"
      >
        {dymoRdy ? (
          <Box>
            <Text align="center">
              {tn('leak.page.qrcodes.dymoPrintOverlay.status', { done, total: qrCodes.length })}
              {/* Drucke {done} von {qrCodes.length} */}
            </Text>
            <ProgressBar>
              <DoneBar done={isPrinting ? (done / qrCodes.length) * 100 : 0} />
            </ProgressBar>
            <Button
              text={tn('leak.page.qrcodes.dymoPrintOverlay.buttons.print')} // "Druck starten"
              onClick={initPrint}
              size="sm"
              width={200}
              background="green"
              inline
            />
            <Button
              text={tn('general.cancel')}
              onClick={onClose}
              size="sm"
              width={200}
              background="purple"
              style={{ marginLeft: '10px' }}
              inline
            />
          </Box>
        ) : (
          <Box display="flex" justify="center" alignItems="center" direction="column">
            <Text>
              {tn('leak.page.qrcodes.dymoPrintOverlay.notReady')}
              {/* Dymo Drucker nicht erkannt oder nicht bereit */}
            </Text>
            <br />
            <Button
              text={tn('general.close')} // "Schließen"
              onClick={onClose}
              size="sm"
              width={200}
              background="purple"
              inline
            />
          </Box>
        )}
      </LoadingBox>
    </Modal>
  );
}

QrDymoModal.propTypes = {
  qrCodes: PropTypes.array.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func
};

QrDymoModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export default QrDymoModal;
