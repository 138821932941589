import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Banner,
  Box,
  ContentBox,
  LoadingBox,
  MoreMenuIcon,
  Separator,
  SortTable
} from '@looxr/components';
import { Collections } from '@looxr/constants';
import { SORT_DESC, useLanguage, useTranslation } from '@looxr/utils';
import {
  ExportMoreMenu,
  LeakCostChart,
  LeakCostOverTimeChart,
  LeakFilterForm,
  LeakSummary
} from '../../components';
import {
  DOWNLOAD_PROGRESS_STATUS,
  getLeakLocationTableConfig,
  getLeakTableConfig
} from '../../constants';
import {
  useFilterLeaks,
  useFlattenFirebaseDoc,
  useLeakCharts,
  useLeakFilter,
  useLoadLeaks
} from '../../hooks';
import { ApiService, AppStateService, FirebaseService, LeakSummaryService } from '../../services';

function Dashboard() {
  const language = useLanguage();
  const tn = useTranslation();
  const history = useHistory();

  const { flattenLeak, flattenCustomer } = useFlattenFirebaseDoc();
  const { updateFilter, resetFilter, filter, loadingFilter } = useLeakFilter();
  const { getLeaks } = useLoadLeaks();
  const { filterLeaks } = useFilterLeaks();
  const { getCountData, getCostsData, getOverTimeData } = useLeakCharts();

  const [showLeakPDFMessage, setShowLeakPDFMessage] = useState(false);
  const [showCsvExportMessage, setShowCsvExportMessage] = useState(false);

  const [initialFilterApplied, setInitialFilterApplied] = useState(false);
  const [loadingLeaks, setLoadingLeaks] = useState(true);
  const [leaks, setLeaks] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [leakSummary, setLeakSummary] = useState({});
  const [leakLocationMap, setLeakLocationMap] = useState([]);

  const [leakCountChart, setLeakCountChart] = useState([]);
  const [leakCostsChart, setLeakCostsChart] = useState([]);
  const [loadingOverTimeData, setLoadingOverTimeData] = useState(true);
  const [loadingCountChart, setLoadingCountChart] = useState(true);

  const [leakCostOverTimeChartData, setLeakCostOverTimeChartData] = useState([]);

  // Effect to initially load leaks
  useEffect(() => {
    const load = async () => {
      setLoadingLeaks(true);
      const leakDocs = await getLeaks();
      setLeaks(leakDocs);
      setLoadingLeaks(false);
    };

    load();
  }, []);

  // Effect to apply initial filter on load
  useEffect(() => {
    if (!loadingLeaks && !loadingFilter && !initialFilterApplied) {
      const filteredData = filterLeaks(filter, leaks);
      setFiltered(filteredData);
      setInitialFilterApplied(true);
    }
  }, [filter, leaks, loadingLeaks, loadingFilter, initialFilterApplied]);

  // Effect to create the leak summary and chart data
  useEffect(() => {
    const calcOverTime = async () => {
      setLoadingOverTimeData(true);

      const currentCustomer = AppStateService.activeCustomer;
      const flattenedCustomer = flattenCustomer(currentCustomer);
      const flattenedLeaks = filtered.map((leak) => flattenLeak(leak));

      const data = await getOverTimeData(flattenedLeaks, flattenedCustomer);

      setLeakCostOverTimeChartData(data);
      setLoadingOverTimeData(false);
    };

    if (!loadingLeaks && !loadingFilter) {
      setLoadingCountChart(true);
      const currentCustomer = AppStateService.activeCustomer;
      const leakSummary = LeakSummaryService.calcSummaryValues(filtered, currentCustomer);
      const locationMap = LeakSummaryService.generateLocationMapGroup(filtered, currentCustomer);
      const countChart = getCountData(leakSummary);
      const costChart = getCostsData(leakSummary);

      setLeakLocationMap(locationMap);
      setLeakSummary(leakSummary);
      setLeakCountChart(countChart);
      setLeakCostsChart(costChart);

      calcOverTime();

      setTimeout(() => {
        setLoadingCountChart(false);
      }, 500);
    }
  }, [filtered, loadingLeaks, loadingFilter]);

  // currently filter actions on change
  const submitFilter = (filterFormData) => {
    const filteredData = filterLeaks(filterFormData, leaks);
    setFiltered(filteredData);
    updateFilter(filterFormData);
  };

  // Reset filter, change of filter triggers effect to filter leaks
  const resetFilterData = () => {
    resetFilter();
    setFiltered([...leaks]);
  };

  const navigateToLeak = (leak) => {
    history.push(`/leckage/info/${leak.id}`);
  };

  const createPDF = async (filterFormData, propDateRange) => {
    if (filtered.length > 0) {
      setShowLeakPDFMessage(true);

      const leakPDF = {
        language,
        status: DOWNLOAD_PROGRESS_STATUS.progress,
        createdAt: FirebaseService.getTimestamp(),
        createdBy: AppStateService.user.ref,
        customer: AppStateService.activeCustomer.ref,
        filter: { ...filterFormData },
        propDateRange: propDateRange !== undefined ? propDateRange : null,
        leaks: {
          ids: [],
          refs: []
        }
      };

      leakPDF.leaks.ids = filtered.map((leak) => leak.id);
      leakPDF.leaks.refs = filtered.map((leak) => leak.ref);

      const progressID = await FirebaseService.upsertDoc(null, leakPDF, Collections.leakPDF);
      // console.log(progressID);

      ApiService.initLeakPDFProcess(progressID);
    }
  };

  const handleExportAction = async (action) => {
    if (filtered.length > 0) {
      setShowCsvExportMessage(true);

      const leakComponentExport = {
        language,
        status: DOWNLOAD_PROGRESS_STATUS.progress,
        repaired: action === 'repairComponentList',
        createdAt: FirebaseService.getTimestamp(),
        createdBy: AppStateService.user.ref,
        customer: AppStateService.activeCustomer.ref,
        leaks: {
          ids: [],
          refs: []
        }
      };

      leakComponentExport.leaks.ids = filtered.map((leak) => leak.id);
      leakComponentExport.leaks.refs = filtered.map((leak) => leak.ref);

      const progressID = await FirebaseService.upsertDoc(
        null,
        leakComponentExport,
        Collections.leakComponentExport
      );

      ApiService.initLeakComponentExportProcess(progressID);
    }
  };

  return (
    <LoadingBox
      loading={loadingLeaks || loadingFilter}
      renderChildren={!loadingLeaks && !loadingFilter}
      marginBottom={4}
      minHeight="80vh"
    >
      <Banner subtext={AppStateService.activeCustomer.name1} />
      <Box margin={4}>
        <ContentBox title={tn('leak.page.dashboard.filterForm.headline')} noPadding>
          <MoreMenuIcon
            text="Exports"
            menuContent={ExportMoreMenu}
            onMenuItemClick={(action) => handleExportAction(action)}
          />
          <LeakFilterForm
            filterData={filter}
            onFilter={submitFilter}
            onReset={resetFilterData}
            onCreatePDF={createPDF}
            onCreatePropViewPDF={createPDF}
          />
        </ContentBox>
      </Box>

      <Box display="flex" marginTop={7} marginX={4}>
        <ContentBox
          title={tn('leak.page.dashboard.leakCosts.headline')} // "Leckagen & Leckagekosten"
          contentHeight="100%"
          marginRight={2}
          noPadding
        >
          <LoadingBox
            loading={loadingCountChart}
            renderChildren={!loadingCountChart}
            display="flex"
            flexShrink={1}
            height="100%"
            width="100%"
            justify="center"
            alignItems="center"
          >
            <Box width="100%">
              <Box paddingX={7} paddingY={4}>
                <LeakCostChart config={leakCountChart.config} data={leakCountChart.data} />
              </Box>
              <Separator />
              <Box paddingX={7} paddingY={4}>
                <LeakCostChart config={leakCostsChart.config} data={leakCostsChart.data} />
              </Box>
            </Box>
          </LoadingBox>
        </ContentBox>

        <ContentBox
          title={tn('leak.page.dashboard.areas.headline')} // "Leckagekosten nach Bereich"
          contentHeight="100%"
          noPadding
          marginLeft={2}
        >
          <Box minHeight={535} width="100%">
            <SortTable
              tableKey="leakCosts"
              data={leakLocationMap}
              hasPagination={true}
              pagerCondensed={true}
              pagerFixedAtBottom={true}
              perPage={10}
              saveState={true}
              noDataText="Keine Einträge"
              dataName={tn('leak.page.dashboard.areas.tableInfo')}
              columns={getLeakLocationTableConfig()}
              defaulSortBy="id"
              defaultSortOrder={SORT_DESC}
              hasRowHover={false}
              contentHeight={535}
            />
          </Box>
        </ContentBox>
      </Box>

      <ContentBox
        title={tn('leak.page.dashboard.summary.headline')} // "Kumulierte Werte"
        width="100%"
        marginTop={7}
        paddingX={4}
      >
        <LeakSummary summary={leakSummary} />
      </ContentBox>

      <ContentBox
        title={tn('leak.page.dashboard.leakOverTime.headline')} // "Leckagekosten nach Zeit"
        width="100%"
        marginTop={7}
        paddingX={4}
      >
        <LoadingBox
          loading={loadingOverTimeData}
          renderChildren={!loadingOverTimeData}
          width="100%"
          height="100%"
        >
          <LeakCostOverTimeChart data={leakCostOverTimeChartData} />
        </LoadingBox>
      </ContentBox>

      <ContentBox
        title={tn('leak.page.dashboard.leakTable.headline')} // "Leckagen in der Übersicht"
        noPadding
        marginTop={7}
        paddingX={4}
      >
        <SortTable
          tableKey="leaks"
          data={filtered}
          hasPagination={true}
          pagerCondensed={false}
          perPage={20}
          saveState={true}
          noDataText={tn('leak.page.dashboard.leakTable.noData')} // "Keine Leckagen"
          dataName={tn('general.leaks')} // "Leckagen"
          columns={getLeakTableConfig()}
          defaulSortBy="id"
          defaultSortOrder={SORT_DESC}
          hasRowHover={true}
          onRowClick={(data) => navigateToLeak(data)}
        />
      </ContentBox>

      <Alert
        show={showLeakPDFMessage}
        btnAction={() => setShowLeakPDFMessage(false)}
        btnColor="purple"
        message={tn('leak.alert.documentationDownload.text')} // "Die Dokumentation wird erstellt, Sie können den Status im Footer verfolgen"
      />

      <Alert
        show={showCsvExportMessage}
        btnAction={() => setShowCsvExportMessage(false)}
        btnColor="purple"
        message={tn('leak.alert.exportDownload.text')} // "Die Dokumentation wird erstellt, Sie können den Status im Footer verfolgen"
      />
    </LoadingBox>
  );
}

export default Dashboard;
