import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Button, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';

const StyledTable = styled.table`
  th {
    text-align: left;
  }

  tr td:last-child {
    text-align: right;
  }
`;

function ComponentTable({ components, onDelete }) {
  const tn = useTranslation();

  return (
    <StyledTable style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>
            <Text size="sm" bold>
              {tn('general.articleNr')}
              {/* Mader-Artikel-Nr. */}
            </Text>
          </th>
          <th>
            <Text size="sm" bold>
              {tn('general.description')} 1{/* Beschreibung 1 */}
            </Text>
          </th>
          <th>
            <Text size="sm" bold>
              {tn('general.description')} 2{/* Beschreibung 1 */}
            </Text>
          </th>
          <th width="100" aria-label="btn-row">
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody>
        {components.map((component, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={`component-${component.id}-${i}`}>
            <td>
              <Text size="sm">{component.articleNr}</Text>
            </td>
            <td>
              <Text size="sm">{component.description1}</Text>
            </td>
            <td>
              <Text size="sm">{component.description2}</Text>
            </td>
            <td>
              <Button
                aria-label="delete-btn"
                inline
                text={tn('general.delete')} // "Löschen"
                background="red"
                size="sm"
                onClick={() => onDelete(component)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

ComponentTable.propTypes = {
  components: PropTypes.array,
  onDelete: PropTypes.func
};

ComponentTable.defaultProps = {
  components: [],
  onDelete: () => {}
};

export default ComponentTable;
