import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Banner, Box, ContentBox, LoadingOverlay, SortTable, TextInput } from '@looxr/components';
import { filterContains, filterStartsWith, useTranslation } from '@looxr/utils';
import { getCustomerSelectTableConfig } from '../../constants';
import { useLeakFilter, useLoadCustomers } from '../../hooks';
import { AppStateService } from '../../services';

function CustomerSelect() {
  const tn = useTranslation();
  const history = useHistory();
  const { getCustomers } = useLoadCustomers();
  const { resetFilter } = useLeakFilter();

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [customers, setCustomers] = useState([]);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const customerDocs = await getCustomers();

      setCustomers(customerDocs);
      setLoading(false);
    };

    load();
  }, []);

  useEffect(() => {
    if (customers && customers.length > 0) {
      const activeCustomer = customers.filter((c) => c.archived !== true);

      if (query && query.length > 2) {
        const filteredCustomers = activeCustomer.filter((customer) => {
          let contains = false;

          if (
            filterStartsWith(query, customer.id) ||
            filterContains(query, customer.name1) ||
            filterContains(query, customer.zip) ||
            filterContains(query, customer.city) ||
            filterContains(query, customer.country)
          ) {
            contains = true;
          }

          return contains;
        });

        setFiltered(filteredCustomers);
      } else {
        setFiltered(activeCustomer);
      }
    }
  }, [query, customers]);

  const setAsActive = (customer) => {
    // reset leak filter that may be configured for another customer
    resetFilter();

    // set selected customer as active one
    AppStateService.setActiveCustomer(customer);

    // redirect to dashboard
    history.push('/');
  };

  return (
    <>
      <Banner
        subtext={tn('leak.page.locationselect.headline')} // "Suchen und wählen Sie einen Kunden aus"
      />
      <Box maxWidth={970} center marginTop={4} marginBottom={4}>
        <ContentBox
          title={tn('leak.page.locationselect.search.headline')} // "Kundensuche"
        >
          <TextInput
            id="customerSearch"
            name="customerSearch"
            placeholder={tn('leak.page.locationselect.search.placeholder')} //  "Tag, Name, PLZ oder Ort eingeben"
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
          />
        </ContentBox>

        <ContentBox
          title={tn('leak.page.locationselect.table.headline')} // "Kundenliste"
          noPadding
          marginTop={20}
        >
          <SortTable
            tableKey="customers"
            data={filtered}
            hasPagination={true}
            pagerCondensed={true}
            perPage={20}
            saveState={true}
            noDataText={tn('admin.page.customerList.table.noData')} // "Keine Kundendaten zu diesen Kriterien vorhanden"
            dataName={tn('admin.page.customerList.table.title')} // "Kunden"
            columns={getCustomerSelectTableConfig()}
            hasRowHover={true}
            onRowClick={setAsActive}
            defaulSortBy="name1"
            defaultSortOrder="asc"
          />
        </ContentBox>
      </Box>

      <LoadingOverlay isVisible={loading} />
    </>
  );
}

export default CustomerSelect;
