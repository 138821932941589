import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { VALENCY_TYPES } from '../constants/Form';

function ValenceText({ valence, ...rest }) {
  const tn = useTranslation();

  let color = 'green';
  let text = tn('general.valenceText.low');
  if (valence === VALENCY_TYPES.mid) {
    color = 'orange';
    text = tn('general.valenceText.mid');
  }

  if (valence === VALENCY_TYPES.high) {
    color = 'red';
    text = tn('general.valenceText.high');
  }
  return (
    <Text {...rest} color={color}>
      {text}
    </Text>
  );
}

ValenceText.propTypes = {
  valence: PropTypes.string.isRequired
};

export default ValenceText;
