import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, NumberText, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';

const StyledText = styled(Text)`
  width: 150px;
`;

function LeakCostOverTimeTooltip({ payload }) {
  const tn = useTranslation();

  if (payload && payload[0] && payload[0].payload) {
    const pointData = payload[0].payload;
    const { count, repaired, cost, date } = pointData;
    return (
      <Box background="white" padding={4}>
        <Box display="flex" direction="column" marginBottom={1} noElevation>
          <Box display="flex" direction="row" marginBottom={1}>
            <StyledText bold={true} size="sm">
              {tn('leak.page.dashboard.leakOverTime.tooltip.date')}:
            </StyledText>
            <Text size="sm">{date}</Text>
          </Box>
          <Box display="flex" direction="row" marginBottom={1}>
            <StyledText bold={true} size="sm">
              {tn('leak.page.dashboard.leakOverTime.tooltip.energyCosts')}:
            </StyledText>
            <NumberText size="sm" number={cost} digits={2} round={false} suffix="€" />
          </Box>
          <Box display="flex" direction="row" marginBottom={1}>
            <StyledText size="sm" bold={true}>
              {tn('leak.page.dashboard.leakOverTime.tooltip.leaks')}:
            </StyledText>
            <Text size="sm">{count}</Text>
          </Box>
          <Box display="flex" direction="row" marginBottom={1}>
            <StyledText size="sm" bold={true}>
              {tn('leak.page.dashboard.leakOverTime.tooltip.repairedLeaks')}:
            </StyledText>
            <Text size="sm">{repaired}</Text>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
}

LeakCostOverTimeTooltip.propTypes = {
  payload: PropTypes.array
};

LeakCostOverTimeTooltip.defaultProps = {
  payload: []
};

export default LeakCostOverTimeTooltip;
