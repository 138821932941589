import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@looxr/components';
import FirebaseImage from './FirebaseImage';

function ImageGrid({ leak, showEdit, onNewImage }) {
  const updateDate = leak.updatedAt ? leak.updatedAt : leak.createdAt;
  const lastUpdateTime = updateDate.toDate().getTime();

  return (
    <Box display="flex" wrap="wrap" padding={2}>
      <FirebaseImage
        flex="25%"
        minWidth="250px"
        maxWidth="25%"
        minHeight="150px"
        padding={2}
        uri={leak.image1 ? leak.image1 : ''}
        lastUpdate={lastUpdateTime}
        showEdit={showEdit}
        onNewImage={(img) => onNewImage('image1', img)}
      />

      <FirebaseImage
        flex="25%"
        minWidth="250px"
        maxWidth="25%"
        minHeight="150px"
        padding={2}
        uri={leak.image2 ? leak.image2 : ''}
        lastUpdate={lastUpdateTime}
        showEdit={showEdit}
        onNewImage={(img) => onNewImage('image2', img)}
      />
    </Box>
  );
}

ImageGrid.propTypes = {
  leak: PropTypes.any.isRequired,
  showEdit: PropTypes.bool,
  onNewImage: PropTypes.func
};

ImageGrid.defaultProps = {
  showEdit: false,
  onNewImage: () => {}
};

export default ImageGrid;
