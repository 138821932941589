import React from 'react';
import { useHistory } from 'react-router';
import { Banner, Box, Button, ContentBox, NumberText, Text } from '@looxr/components';
import {
  ADMIN_PORTAL_URL,
  MEASURED_DEVICE_TYPES,
  getMeasuredDeviceTypeOptions
} from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { AppStateService } from '../../services';

function Location() {
  const tn = useTranslation();
  const history = useHistory();

  const currentCustomer = AppStateService.activeCustomer;

  const navigateToLocationSearch = () => {
    history.push(`/kunden-auswahl`);
  };

  const navigateToAdminEdit = () => {
    const adminUrl = ADMIN_PORTAL_URL;
    // @TODO only for admins user.role === 'ROLE_ADMIN' etc ...
    if (AppStateService.customer.id !== AppStateService.activeCustomer.id) {
      const url = `${adminUrl}/customer/${currentCustomer.id}`;
      window.location.href = url;
    } else {
      const url = `${adminUrl}`;
      window.location.href = url;
    }
  };

  const getMeasuredDeviceLabel = () => {
    const options = getMeasuredDeviceTypeOptions();
    const match = options.filter(
      (o) => o.value === currentCustomer.parameter.measuredDeviceType
    )[0];
    return match ? match.label : '-';
  };

  const getPowerFactorUnitLabel = () => {
    const deviceConfig = MEASURED_DEVICE_TYPES[currentCustomer.parameter.measuredDeviceType];
    let label = '[(kW/m³)min.]';

    if (deviceConfig && deviceConfig.powerFactorInSec) {
      label = '[(kW/m³)sec.]';
    }

    return label;
  };

  return (
    <>
      <Banner subtext={currentCustomer.name1} />
      <Box maxWidth={970} center marginTop={4} marginBottom={4}>
        <ContentBox
          title={tn('leak.page.locationdetail.locationData')} // "Standortdaten"
        >
          <Box width="25%">
            <Text size="sm" bold>
              #ID
            </Text>
            <Text size="sm">{currentCustomer.id.substring(0, 6).toUpperCase()}</Text>
          </Box>
          <Box width="25%">
            <Text size="sm" bold>
              {tn('general.address')}
            </Text>
            <Text size="sm">{currentCustomer.address1}</Text>
          </Box>
          <Box width="25%">
            <Text size="sm" bold>
              {tn('general.zip')}
            </Text>
            <Text size="sm">{currentCustomer.zip}</Text>
          </Box>
          <Box width="25%">
            <Text size="sm" bold>
              {tn('general.city')}
            </Text>
            <Text size="sm">{currentCustomer.city}</Text>
          </Box>
        </ContentBox>

        <ContentBox
          title={tn('leak.page.locationdetail.parameter')} // "Parameter"
          marginTop={5}
        >
          <Box display="flex" wrap="wrap" direction="row" width="100%" style={{ rowGap: '20px' }}>
            {currentCustomer.parameter.electricPrice !== undefined &&
            currentCustomer.parameter.electricPrice !== '' ? (
              <Box width="25%">
                <Text size="sm" bold>
                  {tn('general.electricPrice')}
                </Text>
                <NumberText
                  number={parseFloat(currentCustomer.parameter.electricPrice)}
                  suffix="€/kWh"
                />
              </Box>
            ) : null}

            <Box width="25%">
              <Text size="sm" bold>
                {tn('general.operatingHours')}
              </Text>
              <NumberText
                number={parseFloat(currentCustomer.parameter.operatingHours)}
                suffix={tn('general.hoursPerYear')}
              />
            </Box>

            {currentCustomer.parameter.emission !== undefined &&
            currentCustomer.parameter.emission !== '' ? (
              <Box width="25%">
                <Text size="sm" bold>
                  {tn('general.emission')}
                  {/* CO₂-Emission */}
                </Text>
                <NumberText
                  number={parseFloat(currentCustomer.parameter.emission)}
                  suffix="kg/kWh"
                />
              </Box>
            ) : null}

            {currentCustomer.parameter.powerFactor !== undefined &&
            currentCustomer.parameter.powerFactor !== '' ? (
              <Box width="25%">
                <Text size="sm" bold>
                  {tn('general.powerFactor')}
                  {/* Leistungsfaktor */}
                </Text>
                <NumberText
                  number={parseFloat(currentCustomer.parameter.powerFactor)}
                  digits={4}
                  minFractions={2}
                  maxFractions={4}
                  suffix={`${getPowerFactorUnitLabel()}`}
                />
              </Box>
            ) : null}

            {currentCustomer.parameter.airPressurePrice !== undefined &&
            currentCustomer.parameter.airPressurePrice !== '' ? (
              <Box width="25%">
                <Text size="sm" bold>
                  {tn('general.airPressurePrice')}
                </Text>
                <NumberText
                  number={parseFloat(currentCustomer.parameter.airPressurePrice)}
                  suffix="€/kWh"
                />
              </Box>
            ) : null}

            <Box width="25%">
              <Text size="sm" bold>
                {tn('general.measuredDeviceType')}
              </Text>
              <Text size="sm">{getMeasuredDeviceLabel()}</Text>
            </Box>
          </Box>
        </ContentBox>

        <Box display="flex" marginTop={3}>
          {(AppStateService.isLOOXR() || AppStateService.isLeakAdmin()) && (
            <Box display="flex" justifiy="flex-start">
              <Button
                width={200}
                text={tn('leak.page.locationdetail.buttons.editInAdmin')} // "Im Admin Portal Bearbeiten"
                onClick={navigateToAdminEdit}
              />
            </Box>
          )}

          <Box display="flex" justifiy="flex-end" marginLeft="auto">
            <Button
              width={200}
              text={tn('leak.page.locationdetail.buttons.chooseOtherLocation')} // "Anderen Standort wählen"
              onClick={navigateToLocationSearch}
              background="purple"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Location;
