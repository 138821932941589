import { Collections } from '@looxr/constants';
import { FirebaseService } from '../services';

function useLoadComponents() {
  const getComponentsOfLeak = async (leak) => {
    const loading = [];
    if (leak.components && leak.components.ids && leak.components.ids.length > 0) {
      for (let i = 0; i < leak.components.ids.length; i += 1) {
        const id = leak.components.ids[i];
        loading.push(getComponentByID(id));
      }
    }

    const docResults = await Promise.all(loading);
    const valid = docResults.filter((doc) => doc !== false);
    return valid.map((doc) => {
      return {
        id: doc.id,
        ref: doc.ref,
        ...doc.data()
      };
    });
  };

  const getRepairComponentsOfLeak = async (leak) => {
    const loading = [];
    if (
      leak.repairComponents &&
      leak.repairComponents.ids &&
      leak.repairComponents.ids.length > 0
    ) {
      for (let i = 0; i < leak.repairComponents.ids.length; i += 1) {
        const id = leak.repairComponents.ids[i];
        loading.push(getComponentByID(id));
      }
    }

    const docResults = await Promise.all(loading);
    const valid = docResults.filter((doc) => doc !== false);
    return valid.map((doc) => {
      return {
        id: doc.id,
        ref: doc.ref,
        ...doc.data()
      };
    });
  };

  const findComponentByArticleNr = async (articleNr) => {
    try {
      const data = [];

      const articleNrQuery = await FirebaseService.ref
        .firestore()
        .collection(Collections.component)
        .where('articleNr', '==', articleNr)
        .get();

      const description1Query = await FirebaseService.ref
        .firestore()
        .collection(Collections.component)
        .where('description1', '==', articleNr)
        .get();

      articleNrQuery.forEach((doc) => {
        if (doc.exists) {
          const docData = doc.data();
          data.push({
            id: doc.id,
            ref: doc.ref,
            ...docData
          });
        }
      });

      description1Query.forEach((doc) => {
        if (doc.exists) {
          const docData = doc.data();
          data.push({
            id: doc.id,
            ref: doc.ref,
            ...docData
          });
        }
      });

      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getComponentByID = async (id) => {
    try {
      const doc = await FirebaseService.ref
        .firestore()
        .collection(Collections.component)
        .doc(id)
        .get();

      return doc;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return {
    getComponentsOfLeak,
    getRepairComponentsOfLeak,
    findComponentByArticleNr,
    getComponentByID
  };
}

export default useLoadComponents;
