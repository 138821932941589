import { Collections } from '@looxr/constants';
import AppStateService from './AppStateService';
import FirebaseService from './FirebaseService';
import LeakCalcService from './LeakCalcService';

const LeakService = {
  prepareForForm: (doc) => {
    const cleaned = { ...doc };

    delete cleaned.components;
    delete cleaned.repairComponents;
    delete cleaned.qrCode;
    delete cleaned.customer;
    delete cleaned.createdBy;
    delete cleaned.updatedBy;
    delete cleaned.repairedBy;

    return cleaned;
  },

  handleSubmit: async (
    leakDoc,
    leakData,
    formData,
    leakComponents,
    leakRepairComponents,
    newImage1,
    newImage2
  ) => {
    const update = { ...leakData, ...formData };

    update.components = {};
    update.components.ids = leakComponents.map((doc) => doc.id);
    update.components.refs = leakComponents.map((doc) => doc.ref);

    update.repairComponents = {};
    update.repairComponents.ids = leakRepairComponents.map((doc) => doc.id);
    update.repairComponents.refs = leakRepairComponents.map((doc) => doc.ref);

    update.updatedAt = FirebaseService.getTimestamp();
    update.updatedBy = AppStateService.user.ref;

    update.valence = LeakCalcService.calculateValence(update);

    // reset time to be a null value when not repaired
    // used to normalize for repaired / not repairable
    if (update.repaired !== true) {
      // update.repairedBy = null;
      // update.repairedAt = null;
      update.repairTime = null;
    }

    if (update.repaired !== true && update.notRepairable !== true) {
      update.repairedAt = null;
    }

    // upload files
    if (newImage1) {
      // delete old ref
      await FirebaseService.deleteImage(update.image1);

      // create new ref path
      const uploadPath1 = `leakImages/${leakDoc.id}_image1`;

      // upload image
      const img1Res = await FirebaseService.uploadImage(uploadPath1, newImage1);

      // save new ref on success
      if (img1Res) {
        update.image1 = uploadPath1;
      }
    }

    if (newImage2) {
      await FirebaseService.deleteImage(update.image2);

      const uploadPath2 = `leakImages/${leakDoc.id}_image2`;
      const img2Res = await FirebaseService.uploadImage(uploadPath2, newImage2);

      if (img2Res) {
        update.image2 = uploadPath2;
      }
    }
    const result = await FirebaseService.upsertDoc(leakDoc.id, update, Collections.leak);

    return result;
  }
};

export default LeakService;
