import { Collections } from '@looxr/constants';
import AppStateService from './AppStateService';
import FirebaseService from './FirebaseService';

const QrCodeService = {
  generateQrCodes: async (amount) => {
    const latestQrCode = await QrCodeService.getQrCodeWithHighestNumber();
    let latestQrNumber = 0;

    if (latestQrCode) {
      latestQrNumber = latestQrCode.serialNumber;
    }

    const inserts = [];
    for (let i = 1; i <= amount; i += 1) {
      const newQrNum = latestQrNumber + i;
      const qrCodeData = QrCodeService.bootstrapQrCode(newQrNum);

      inserts.push(FirebaseService.upsertDoc(null, qrCodeData, Collections.qrcode));
    }

    return Promise.all(inserts);
  },

  bootstrapQrCode: (number) => {
    const today = new Date();
    const year = today.getFullYear();
    const yearNum = `${year}`.substring(2);
    const serialNumber = QrCodeService.generateSerial(number);
    const serial = `${yearNum}-${serialNumber}`;

    const currentUser = AppStateService.user;
    const currentCustomer = AppStateService.activeCustomer;

    return {
      createdAt: FirebaseService.getTimestamp(),
      createdBy: currentUser.ref,
      printed: false,
      printedAt: null,
      used: false,
      usedAt: null,
      serial,
      year,
      serialNumber: number,
      updatedAt: null,
      updatedBy: null,
      image: null,
      label: null,
      customer: currentCustomer.ref,
      customerID: currentCustomer.id
    };
  },

  /**
   * Get the highest qr number from this year
   */
  getQrCodeWithHighestNumber: async () => {
    let latest = null;
    const today = new Date();
    const year = today.getFullYear();

    const currentCustomer = AppStateService.activeCustomer;
    const queryRef = FirebaseService.ref
      .firestore()
      .collection(Collections.qrcode)
      .where('customer', '==', currentCustomer.ref)
      .where('year', '==', year)
      .orderBy('serialNumber', 'desc')
      .limit(1);

    const snapshot = await queryRef.get();

    snapshot.forEach((doc) => {
      if (doc.exists && doc.id) {
        latest = doc.data();
      }
    });

    return latest;
  },

  extractQrNumFromSerial: (serial) => {
    const numberPart = serial.substring(3);
    return parseInt(numberPart, 10);
  },

  generateSerial: (qrNumber) => {
    const qrNumStr = `${qrNumber}`;
    return qrNumStr.padStart(5, '0');
  }
};

export default QrCodeService;
