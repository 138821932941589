import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Button, DateRangeInput, Info, Label, Select, TextInput } from '@looxr/components';
import { useLanguage, useTranslation } from '@looxr/utils';
import useFormOptions from '../hooks/useFormOptions';
import { AppStateService } from '../services';
import PropPdfModal from './PropPdfModal';

function LeakFilterForm({ filterData, onFilter, onReset, onCreatePDF, onCreatePropViewPDF }) {
  const language = useLanguage();
  const tn = useTranslation();
  const formOptions = useFormOptions();

  const [localFilter, setLocalFilter] = useState({});
  const [showPropViewOverlay, setShowPropViewOverlay] = useState(false);

  useEffect(() => {
    setLocalFilter({ ...filterData });
  }, [filterData]);

  const updateFilter = (key, value) => {
    const update = { ...localFilter };
    update[key] = value;

    setLocalFilter(update);
  };

  const submit = (e) => {
    e.preventDefault();
    onFilter(localFilter);
  };

  const canUsePropView = () => {
    if (
      AppStateService.isLOOXR() ||
      AppStateService.isOEM() ||
      AppStateService.isServicePartner()
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <form noValidate onSubmit={submit} style={{ width: '100%' }}>
        <Box display="flex" width="100%" wrap="wrap" direction="column">
          <Box display="flex" width="100%" wrap="wrap" padding={3}>
            <Box padding={2} width="25%">
              <Label htmlFor="filter_id">{tn('general.id')}</Label>
              <TextInput
                id="filter_id"
                name="filter_id"
                value={localFilter.serial ? localFilter.serial : ''}
                onChange={(e) => updateFilter('serial', e.currentTarget.value)}
              />
            </Box>

            <Box padding={2} width="25%">
              <Label htmlFor="filter_location">{tn('general.location')}</Label>
              <TextInput
                id="filter_location"
                name="filter_location"
                value={localFilter.location ? localFilter.location : ''}
                onChange={(e) => updateFilter('location', e.currentTarget.value)}
              />
            </Box>

            <Box padding={2} width="25%">
              <Label htmlFor="filter_machine">{tn('general.machine')}</Label>
              <TextInput
                id="filter_machine"
                name="filter_machine"
                value={localFilter?.machine ? localFilter?.machine : ''}
                onChange={(e) => updateFilter('machine', e.currentTarget.value)}
              />
            </Box>

            <Box padding={2} width="25%">
              <Label htmlFor="filter_components">{tn('general.components')}</Label>
              <Select
                id="filter_components"
                name="filter_components"
                placeholder={tn('general.choose')} // "Bitte wählen"
                onChange={(e) => updateFilter('hasComponents', e.value)}
                value={localFilter.hasComponents ? localFilter.hasComponents : ''}
                options={formOptions.hasComponents}
                size="sm"
              />
            </Box>

            <Box padding={2} width="25%">
              <Label htmlFor="repaired_at">{tn('general.createdAt')}</Label>
              <DateRangeInput
                locale={language}
                name="created_at"
                range={
                  localFilter.createdAt
                    ? localFilter.createdAt
                    : {
                        from: null,
                        to: null
                      }
                }
                onChange={(dateRange) => updateFilter('createdAt', dateRange)}
              />
            </Box>

            <Box padding={2} width="25%">
              <Label htmlFor="repaired_at">{tn('general.repairedAt')}</Label>
              <DateRangeInput
                locale={language}
                name="repaired_at"
                range={
                  localFilter.repairedAt
                    ? localFilter.repairedAt
                    : {
                        from: null,
                        to: null
                      }
                }
                onChange={(dateRange) => updateFilter('repairedAt', dateRange)}
              />
            </Box>

            <Box padding={2} width="16.66667%">
              <Label htmlFor="filter_valence">{tn('general.valence')}</Label>
              <Select
                id="filter_valence"
                name="filter_valence"
                placeholder={tn('general.choose')} // "Bitte wählen"
                onChange={(e) => updateFilter('valence', e.value)}
                value={localFilter.valence ? localFilter.valence : ''}
                options={formOptions.valence}
                size="sm"
              />
            </Box>

            <Box padding={2} width="16.66667%">
              <Label htmlFor="filter_repaired">{tn('general.repaired')}</Label>
              <Select
                id="filter_repaired"
                name="filter_repaired"
                placeholder={tn('general.choose')} // "Bitte wählen"
                onChange={(e) => updateFilter('repaired', e.value)}
                value={localFilter.repaired ? localFilter.repaired : ''}
                options={formOptions.repair}
                size="sm"
              />
            </Box>

            <Box padding={2} width="16.66667%">
              <Label htmlFor="filter_check">{tn('general.check')}</Label>
              <Select
                id="filter_check"
                name="filter_check"
                placeholder={tn('general.choose')} // "Bitte wählen"
                onChange={(e) => updateFilter('check', e.value)}
                value={localFilter.check ? localFilter.check : ''}
                options={formOptions.check}
                size="sm"
              />
            </Box>
          </Box>

          <Box padding={3} display="flex" justify="flex-start" direction="row">
            <Box padding={2} display="flex" justify="flex-start">
              <Button
                type="submit"
                background="green"
                inline
                width={200}
                text={tn('leak.page.dashboard.filterForm.buttons.filter')} // "Filtern"
                onClick={submit}
              />
            </Box>

            <Box display="flex" justify="flex-start" padding={2} marginLeft={2}>
              <Button
                type="button"
                width={200}
                text={tn('leak.page.dashboard.filterForm.buttons.leakPdf')} // "Leckage Dokumentation"
                background="blue"
                onClick={() => onCreatePDF(localFilter)}
              />
            </Box>

            <Box display="flex" justify="flex-start" padding={2} marginLeft={2}>
              <Button
                type="button"
                width={200}
                text={tn('leak.page.dashboard.filterForm.buttons.leakPdfPropView')} // "Anteilige Betrachtung"
                background="blue"
                onClick={() => setShowPropViewOverlay(true)}
                disabled={!canUsePropView()}
              />
              {canUsePropView() ? (
                <Info
                  infoText={tn('leak.page.dashboard.filterForm.propViewInfo')}
                  bubbleWidth={300}
                />
              ) : (
                <Info
                  infoText={tn('leak.page.dashboard.filterForm.propViewInfoDisabled')}
                  bubbleWidth={300}
                />
              )}
            </Box>

            <Box padding={2} display="flex" justify="flex-end" marginLeft="auto">
              <Button
                type="reset"
                inline
                width={200}
                text={tn('general.reset')} // "Zurücksetzen"
                background="purple"
                onClick={onReset}
              />
            </Box>
          </Box>
        </Box>
      </form>

      {canUsePropView() ? (
        <PropPdfModal
          show={showPropViewOverlay}
          onSubmit={(propDateRange) => {
            setShowPropViewOverlay(false);
            onCreatePropViewPDF(localFilter, propDateRange);
          }}
          onClose={() => setShowPropViewOverlay(false)}
        />
      ) : null}
    </>
  );
}

LeakFilterForm.propTypes = {
  filterData: PropTypes.any.isRequired,
  onCreatePropViewPDF: PropTypes.func,
  onFilter: PropTypes.func,
  onReset: PropTypes.func,
  onCreatePDF: PropTypes.func
};

LeakFilterForm.defaultProps = {
  onFilter: () => {},
  onReset: () => {},
  onCreatePDF: () => {},
  onCreatePropViewPDF: () => {}
};

export default LeakFilterForm;
