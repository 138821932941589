import { AppStateService, FirebaseService } from '../services';

function useLoadQRCodes() {
  const getQRCodes = async (loadAll) => {
    try {
      const currentCustomer = AppStateService.activeCustomer;
      const snapshot = await FirebaseService.getQRCodes(currentCustomer, loadAll);
      const data = [];
      snapshot.forEach((doc) => {
        if (doc.exists) {
          const docData = doc.data();
          data.push({
            id: doc.id,
            ref: doc.ref,
            ...docData
          });
        }
      });

      return data;
    } catch (error) {
      console.log('Error getting qrcode data');
      console.log(error);
      return [];
    }
  };

  const getQRCodeQuery = () => {
    const currentCustomer = AppStateService.activeCustomer;
    return FirebaseService.getQRCodeQuery(currentCustomer);
  };

  return {
    getQRCodes,
    getQRCodeQuery
  };
}

export default useLoadQRCodes;
