import { filterBooleanEquals, filterStartsWith } from '@looxr/utils';

function useFilterQrcodes() {
  const filterQrcodes = (filter, qrcodes) => {
    let filtered = [];

    const { serial, printed, used } = filter;

    if (qrcodes !== null && qrcodes !== undefined && qrcodes.length > 0) {
      filtered = qrcodes;

      if (serial && serial.length > 1) {
        filtered = filtered.filter((qrcode) => filterStartsWith(serial, qrcode.serial));
      }

      if (printed && printed !== 'all') {
        filtered = filtered.filter((qrcode) => filterBooleanEquals(printed, qrcode.printed));
      }

      if (used && used !== 'all') {
        filtered = filtered.filter((qrcode) => filterBooleanEquals(used, qrcode.used));
      }
    }

    return filtered;
  };

  return { filterQrcodes };
}

export default useFilterQrcodes;
