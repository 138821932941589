import React from 'react';
import { useTranslation } from '@looxr/utils';
import Box from './Box';
import Text from './Text';

function NoPermissionPage() {
  const tn = useTranslation();

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      alignItems="center"
      justify="center"
      position="absolute"
      left="0"
      top="0"
    >
      <Text size="lg">{tn('general.noPermissionNotice')}</Text>
    </Box>
  );
}

export default NoPermissionPage;
