import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import styled from 'styled-components';
import { Box, Button } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import LeakCostOverTimeTooltip from './LeakCostOverTimeTooltip';

const StyledChartHolder = styled(Box)`
  width: 100%;
  height: 100%;

  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  svg {
    overflow: visible;
  }

  .recharts-cartesian-grid .recharts-cartesian-grid-vertical line {
    stroke: transparent !important;
  }
`;

const StyledReset = styled(Box)`
  width: 120px;
  position: absolute;
  top: -15px;
  right: 0;
  z-index: 10;
`;

function LeakCostOverTimeChart({ data }) {
  const tn = useTranslation();

  const [renderData, setRenderData] = useState(data);
  const [startY, setStartY] = useState(null);
  const [endY, setEndY] = useState(null);

  useEffect(() => {
    setRenderData(data);
  }, [data]);

  const formatXAxis = (tickItem) => {
    return moment.unix(tickItem).format('DD.MM.YYYY');
  };

  const handleZoomStartPoint = (e) => {
    if (e && e.activeLabel) {
      setStartY(e.activeLabel);
    }
  };

  const handleZoomEndPoint = (e) => {
    if (startY && e && e.activeLabel) {
      setEndY(e.activeLabel);
    }
  };

  const applyZoom = () => {
    if (startY && endY) {
      const fromIndex = data.findIndex((d) => d.timestamp === startY);
      const toIndex = data.findIndex((d) => d.timestamp === endY);

      if (fromIndex && toIndex) {
        const start = Math.min(fromIndex, toIndex);
        const end = Math.max(fromIndex, toIndex);

        if (start !== end) {
          const zoomed = data.slice(start, end);
          setRenderData(zoomed);
        }

        setStartY(null);
        setEndY(null);
      }
    }
  };

  const resetZoom = () => {
    setStartY(null);
    setEndY(null);
    setRenderData(data);
  };

  return (
    <StyledChartHolder>
      {data.length !== renderData.length && (
        <StyledReset>
          <Button
            color="purple"
            text={tn('general.reset')} // "Zurücksetzen"
            size="sm"
            onClick={resetZoom}
          />
        </StyledReset>
      )}

      <ResponsiveContainer width="99%" height={250}>
        <ComposedChart
          onMouseDown={handleZoomStartPoint}
          onMouseMove={handleZoomEndPoint}
          onMouseUp={applyZoom}
          data={renderData}
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#841d64" stopOpacity={0.7} />
              <stop offset="55%" stopColor="#46b9a0" stopOpacity={0.7} />
            </linearGradient>
          </defs>
          <CartesianGrid horizontal={true} stroke="#d8d8d8" />
          <XAxis
            axisLine={false}
            tickLine={false}
            dataKey="timestamp"
            fontSize="12"
            stroke="#051014"
            tickFormatter={formatXAxis}
          />
          <YAxis axisLine={false} tickLine={false} fontSize="12" stroke="#051014">
            <Label
              value={tn('leak.page.dashboard.leakOverTime.energyCostsInEuro')} //  "Energiekosten in €"
              offset={5}
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: 'middle' }}
              fontSize={14}
              fill="#606060"
            />
          </YAxis>
          <Tooltip cursor={false} content={<LeakCostOverTimeTooltip />} />
          <Line
            dataKey="cost"
            stroke="#46b9a0"
            dot={false}
            strokeLinecap="round"
            strokeWidth={0.01}
          />

          {startY && endY ? <ReferenceArea x1={startY} x2={endY} strokeOpacity={0.3} /> : null}

          <Area
            type="monotone"
            dataKey="cost"
            stroke="#46b9a0"
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </StyledChartHolder>
  );
}

LeakCostOverTimeChart.propTypes = {
  data: PropTypes.array
};

LeakCostOverTimeChart.defaultProps = {
  data: []
};

export default LeakCostOverTimeChart;
