import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { faRedo as NewImageIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, DummyImage, LoadingBox, Modal, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { FirebaseService } from '../services';

const StyledImageHolder = styled(Box)``;

const StyledUploadBox = styled(Box)`
  cursor: pointer;
  border: 1px dashed ${(props) => props.theme.colors.grey};

  &:hover {
    opacity: 0.8;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  vertical-align: 'middle';
  border: 1px solid rgba(29, 29, 27, 0.12);
`;

const StyledBigImage = styled.img`
  width: 100%;
`;

const StyledUploadImage = styled.img`
  max-height: 500px;
  margin: 0 auto;
  display: block;
  border: 1px solid rgba(29, 29, 27, 0.12);
  border-radius: 6px;
`;

const StyledFloatButton = styled(Box)`
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : '10px')};
  right: 15px;

  background: ${(props) => props.theme.colors.white};
  cursor: pointer;
  color: ${(props) => props.theme.colors.purple};
  font-size: 16px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease;
  border-radius: 50%;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.35);
  width: 40px;
  height: 40px;

  &:hover {
    background: ${(props) => props.theme.colors.purple};
    color: ${(props) => props.theme.colors.white};

    svg {
      color: ${(props) => props.theme.colors.white};
      fill: ${(props) => props.theme.colors.white};
      * {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

function FirebaseImage({ uri, showEdit, onNewImage, lastUpdate, ...rest }) {
  const tn = useTranslation();
  const fileInputRef = useRef();

  const [uploadImage, setUploadImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageThumbSrc, setImageThumbSrc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showBigImg, setShowBigImg] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const thumbUrl = await FirebaseService.getImageThumbDownloadUrl(uri);
      const url = await FirebaseService.getImageDownloadUrl(uri);

      console.log('uri => ', uri);
      console.log('thumb url => ', thumbUrl);
      console.log('url => ', url);

      if (thumbUrl) {
        setImageThumbSrc(thumbUrl);
      } else {
        setImageThumbSrc(null);
      }

      if (url) {
        setImageSrc(url);
      } else {
        setImageThumbSrc(null);
      }

      setLoading(false);
    };

    if (uri && uri.length > 0) {
      load();
    } else {
      setImageSrc(null);
      setLoading(false);
    }
  }, [uri, lastUpdate]);

  // !true && true => false
  // !true && false => true

  const setShowActionButtons = (state) => {
    if (showEdit && !loading) {
      setShowActions(state);
    }
  };

  const openFileDialog = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setUploadImage(reader.result);
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getSrc = (useFullSize) => {
    let srcToUse = imageThumbSrc;

    if (useFullSize || (imageSrc !== null && imageThumbSrc === null)) {
      srcToUse = imageSrc;
    }

    if (uploadImage) {
      srcToUse = uploadImage;
    }

    // no src = dummy image
    if (imageSrc === null || imageSrc.length === 0 || imageSrc === undefined) {
      return DummyImage;
    }

    // url src => add timestamp to avoid caching
    if (srcToUse && srcToUse.includes('https://')) {
      return `${srcToUse}&t=${lastUpdate}`;
    }

    return srcToUse;
  };

  const handleClick = () => {
    if (imageSrc === null || imageSrc.length === 0 || imageSrc === undefined) {
      return false;
    }

    setShowBigImg(true);
    return true;
  };

  return (
    <StyledImageHolder
      {...rest}
      onMouseEnter={() => setShowActionButtons(true)}
      onMouseLeave={() => setShowActionButtons(false)}
    >
      <LoadingBox loading={loading} renderChildren={!loading}>
        <StyledImage src={getSrc()} alt="img" onClick={handleClick} />

        {showEdit && (
          <StyledFloatButton
            onClick={() => setShowUploadModal(true)}
            show={showActions}
            display="flex"
            justify="center"
            alignContent="center"
            paddingX={3}
            paddingY={3}
          >
            <FontAwesomeIcon icon={NewImageIcon} />
          </StyledFloatButton>
        )}
      </LoadingBox>

      <Modal
        size={800}
        show={showBigImg}
        onClose={() => setShowBigImg(false)}
        closeOnBackdrop={true}
        closeOnEsc={true}
        showModalCloseIcon={true}
      >
        <Box>
          <StyledBigImage src={getSrc(true)} alt="img" />
        </Box>
      </Modal>

      {/*
          https://jamesooi.design/Croppr.js/
          https://github.com/react-cropper/react-cropper
        */}
      <Modal
        size={800}
        show={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        closeOnBackdrop={false}
        closeOnEsc={false}
        showModalCloseIcon={true}
      >
        <Box display="flex" alignItems="center" justify="center" padding={4}>
          {!uploadImage && (
            <StyledUploadBox
              display="flex"
              alignItems="center"
              justify="center"
              padding={12}
              onClick={() => openFileDialog()}
            >
              <input
                ref={fileInputRef}
                accept="image/*"
                type="file"
                style={{ display: 'none', visibility: 'hidden' }}
                onChange={onFileChange}
              />
              <Text size="sm">
                {tn('leak.page.leakform.addImage')}
                {/* Hier Klicken und Bild auswählen */}
              </Text>
            </StyledUploadBox>
          )}

          {uploadImage && (
            <Box display="flex" alignItems="center" justify="center" direction="column">
              <StyledUploadImage src={uploadImage} alt="img" />
              <Box
                marginTop={3}
                display="flex"
                alignItems="center"
                justify="center"
                direction="row"
              >
                <Button
                  width={200}
                  type="button"
                  background="purple"
                  text={tn('leak.page.leakform.useImage')} //  "Übernehmen"
                  onClick={() => {
                    setShowUploadModal(false);
                    setImageSrc(uploadImage);
                    onNewImage(uploadImage);
                  }}
                />
                <Box marginLeft={3}>
                  <Button
                    width={200}
                    type="button"
                    background="red"
                    text={tn('general.cancel')} // "Abbrechen"
                    onClick={() => {
                      setShowUploadModal(false);
                      onNewImage(null);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </StyledImageHolder>
  );
}

FirebaseImage.propTypes = {
  uri: PropTypes.string.isRequired,
  showEdit: PropTypes.bool,
  onNewImage: PropTypes.func,
  lastUpdate: PropTypes.number
};

FirebaseImage.defaultProps = {
  showEdit: false,
  onNewImage: () => {},
  lastUpdate: null
};

export default FirebaseImage;
