import PropTypes from 'prop-types';
import React from 'react';
import { Box, NumberText, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { ReactComponent as SeparatorVertical } from '../assets/separator.svg';

function LeakSummary({ summary }) {
  const tn = useTranslation();
  return (
    <Box display="flex" width="100%" alignItems="center">
      <Box width="25%">
        <Text size="sm" align="center" bold>
          {tn('general.airLoss')}
          {/* Druckluftverlust */}
        </Text>
        <NumberText
          align="center"
          number={summary.airLoss ? summary.airLoss : 0}
          digits={2}
          round={false}
          suffix="m³/h"
        />
      </Box>
      <SeparatorVertical />
      <Box width="25%">
        <Text size="sm" align="center" bold>
          {tn('general.energyConsumption')}
          {/* Energieverbrauch */}
        </Text>
        <NumberText
          align="center"
          number={summary.energyConsumption ? summary.energyConsumption : 0}
          digits={2}
          round={false}
          suffix={tn('general.kwhPerYear')} // "kWh / Jahr"
        />
      </Box>
      <SeparatorVertical />
      <Box width="25%">
        <Text size="sm" align="center" bold>
          {tn('general.energyCosts')}
          {/* Energiekosten */}
        </Text>
        <NumberText
          align="center"
          number={summary.energyCosts ? summary.energyCosts : 0}
          digits={2}
          round={false}
          suffix={tn('general.euroPerYear')} // "€ / Jahr"
        />
      </Box>
      <SeparatorVertical />
      <Box width="25%">
        <Text size="sm" align="center" bold>
          {tn('general.emission')}
          {/* CO₂-Emission */}
        </Text>
        <NumberText
          align="center"
          number={summary.emission ? summary.emission : 0}
          digits={2}
          round={false}
          suffix={tn('general.kgPerYear')} // "kg / Jahr"
        />
      </Box>
    </Box>
  );
}

LeakSummary.propTypes = {
  summary: PropTypes.any
};

LeakSummary.defaultProps = {
  summary: null
};

export default LeakSummary;
