import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  ContentBox,
  Label,
  LoadingBox,
  Modal,
  SortTable,
  Text,
  TextInput
} from '@looxr/components';
import { SORT_DESC, useTranslation } from '@looxr/utils';
import { QrDymoModal, QrPdfModal } from '../../components';
import { getQrCodeTableConfig } from '../../constants';
import { useFilterQrcodes, useLoadQRCodes } from '../../hooks';
import { AppStateService, FirebaseService, QrCodeService } from '../../services';
import QRCodeFilter from './QRCodeFilter';

function QRCodes() {
  const tn = useTranslation();

  const { getQRCodes } = useLoadQRCodes();
  const { filterQrcodes } = useFilterQrcodes();
  const [loading, setLoading] = useState(true);
  const [qrCodes, setQrCodes] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [selected, setSelected] = useState([]);
  const [generateAmount, setGenerateAmount] = useState(10);
  const [showQrPdfModal, setShowQrPdfModal] = useState(false);
  const [showQrDymoModal, setShowQrDymoModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [loadAll, setLoadAll] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const docs = await getQRCodes(loadAll);

      setQrCodes(docs);
      setFiltered(docs);
      setSelected([]);
      setLoading(false);
    };

    load();
  }, [loadAll]);

  const applyQrcodeFilter = (filter) => {
    const filteredQrcodes = filterQrcodes(filter, qrCodes);
    setFiltered(filteredQrcodes);
    setSelected([]);
  };

  const generateQrCodes = async () => {
    setShowGenerateModal(false);
    setLoading(true);

    await QrCodeService.generateQrCodes(generateAmount);

    const docs = await getQRCodes(false);

    setQrCodes(docs);
    setFiltered(docs);

    setSelected([]);

    setLoading(false);
  };

  const postPrintAction = async (hasPrinted) => {
    setShowQrPdfModal(false);
    setShowQrDymoModal(false);

    if (hasPrinted === true) {
      for (let i = 0; i < selected.length; i += 1) {
        const qrCode = selected[i];

        if (!qrCode.printed) {
          FirebaseService.updateQrCodePrinted(qrCode.id);
        }
      }

      const docs = await getQRCodes(false);
      setQrCodes(docs);
      setSelected([]);
    }
  };

  const updateSelectedRows = ({ checked, data }) => {
    let update = [...selected];
    if (checked) {
      update.push(data);
    } else {
      update = update.filter((row) => row.id !== data.id);
    }

    setSelected(update);
  };

  const handleAllSelectUpdate = ({ checked }) => {
    if (checked) {
      setSelected(filtered);
    } else {
      setSelected([]);
    }
  };

  return (
    <LoadingBox loading={loading} renderChildren={!loading} minHeight="80vh">
      <Banner
        text={AppStateService.activeCustomer.name1}
        subtext={tn('leak.page.qrcodes.headline')} // "QR-Codes verwalten"
      />
      <Box maxWidth={970} center marginTop={4} marginBottom={4}>
        {!loadAll && (
          <ContentBox>
            <Box
              display="flex"
              justify="center"
              alignItems="center"
              width="100%"
              direction="column"
            >
              <Text size="sm" color="grey" center>
                {tn('leak.page.qrcodes.loadNotice')}
              </Text>
              {AppStateService.isLOOXR() && (
                <Box display="flex" justify="center" alignItems="center" marginTop={3}>
                  <Button
                    width={200}
                    inline
                    text={tn('leak.page.qrcodes.loadAll')}
                    onClick={() => setLoadAll(true)}
                  />
                </Box>
              )}
            </Box>
          </ContentBox>
        )}

        <ContentBox
          title={tn('leak.page.qrcodes.filterForm.headline')} // "Filter"
          noPadding
          marginTop={5}
        >
          <QRCodeFilter onUpdate={applyQrcodeFilter} />
        </ContentBox>
        <ContentBox
          title={tn('leak.page.qrcodes.table.headline')} // "Qr-Codes"
          marginTop={5}
          noPadding
        >
          <SortTable
            resetPageOnDataUpdate={false}
            tableKey="leakQRCodes"
            data={filtered}
            hasPagination={true}
            pagerCondensed={false}
            perPage={25}
            saveState={true}
            noDataText={tn('leak.page.qrcodes.table.noData')} // "Keine Daten vorhanden"
            dataName={tn('general.qrcodes')} // "QR-Codes"
            columns={getQrCodeTableConfig()}
            defaulSortBy="serial"
            defaultSortOrder={SORT_DESC}
            hasRowHover={false}
            hasSelectableRows={true}
            onRowSelectChange={updateSelectedRows}
            onSelectAllChange={handleAllSelectUpdate}
            selectedRows={selected}
          />
        </ContentBox>

        <Box display="flex" justify="flex-start" marginTop={3} direction="row">
          <Box display="flex" justify="flex-start">
            <Button
              width={200}
              text={tn('leak.page.qrcodes.buttons.dymoPrint')} // "Mit Dymo-Drucker drucken"
              disabled={selected.length === 0}
              onClick={() => setShowQrDymoModal(true)}
            />
          </Box>

          <Box display="flex" justify="flex-start" marginLeft={2}>
            <Button
              width={200}
              background="purple"
              text={tn('leak.page.qrcodes.buttons.createPdf')} // "PDF Erstellen"
              disabled={selected.length === 0}
              onClick={() => setShowQrPdfModal(true)}
            />
          </Box>

          {(AppStateService.isLOOXR() || AppStateService.isLeakAdmin()) && (
            <Box display="flex" justify="flex-end" marginLeft="auto">
              <Button
                width={200}
                background="blue"
                text={tn('leak.page.qrcodes.buttons.createQrcodes')} //  "QR-Codes erstellen"
                onClick={() => setShowGenerateModal(true)}
              />
            </Box>
          )}
        </Box>
      </Box>

      <QrPdfModal
        show={showQrPdfModal}
        onClose={(hasPrinted) => postPrintAction(hasPrinted)}
        qrCodes={selected}
      />

      <QrDymoModal
        show={showQrDymoModal}
        onClose={(hasPrinted) => postPrintAction(hasPrinted)}
        qrCodes={selected}
      />

      <Modal
        size={800}
        show={showGenerateModal}
        onClose={() => setShowGenerateModal(false)}
        closeOnBackdrop={true}
        closeOnEsc={true}
        showModalCloseIcon={true}
        blockScroll={true}
      >
        <Box>
          <Label htmlFor="amount" required>
            {tn('leak.page.qrcodes.createModal.label')}
            {/*  Anzahl der QR-Codes (max. 50) */}
          </Label>
          <TextInput
            id="amount"
            name="amount"
            type="number"
            value={generateAmount}
            onChange={(e) => setGenerateAmount(e.target.value)}
          />
          <Box display="flex" marginTop={6} justify="center" alignItems="center">
            <Button
              width={200}
              inline
              text={tn('leak.page.qrcodes.createModal.buttonCreate')} //  "Erstellen"
              disabled={generateAmount <= 0 || generateAmount > 50 || generateAmount === ''}
              onClick={generateQrCodes}
            />

            <Box display="inline" marginLeft={2}>
              <Button
                width={200}
                background="red"
                inline
                text={tn('general.cancel')} // "Abbrechen"
                onClick={() => setShowGenerateModal(false)}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </LoadingBox>
  );
}

export default QRCodes;
