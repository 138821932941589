import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  Alert,
  Banner,
  Box,
  Button,
  CheckboxRH,
  ContentBox,
  DateInput,
  Label,
  LoadingBox,
  Popup,
  SelectRH,
  Text,
  TextAreaRH,
  TextInputRH
} from '@looxr/components';
import { MEASURED_DEVICE_TYPES } from '@looxr/constants';
import { useLanguage, useTranslation } from '@looxr/utils';
import { ComponentAdd, ImageGrid } from '../../components';
import { useFormOptions, useLoadComponents, useLoadLeaks, useNumberFormat } from '../../hooks';
import { FirebaseService, LeakService } from '../../services';

function LeakEdit() {
  const language = useLanguage();
  const tn = useTranslation();
  const formOptions = useFormOptions();

  const { register, handleSubmit, errors, reset, unregister, setValue, formState } = useForm({
    defaultValues: {}
  });

  const history = useHistory();
  const { id } = useParams();
  const { parseAsFloat } = useNumberFormat();
  const { getLeakByID } = useLoadLeaks();
  const { getRepairComponentsOfLeak, getComponentsOfLeak } = useLoadComponents();

  // custom form date value
  const [repairedAtValue, setRepairedAtValue] = useState(null);

  // state booleans
  const [loading, setLoading] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // data
  const [leakDoc, setLeakDoc] = useState(null);
  const [leakData, setLeakData] = useState(null);
  const [leakComponents, setLeakComponents] = useState([]);
  const [leakRepairComponents, setLeakRepairComponents] = useState([]);

  // image caching until submit
  const [newImage1, setNewImage1] = useState(null);
  const [newImage2, setNewImage2] = useState(null);

  // non react hook form data changed
  const [nonFormDataChanged, setNonFormDataChanged] = useState(false);

  // effect to load leak and leak data
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const doc = await getLeakByID(id);
      const data = doc.data();
      const formData = LeakService.prepareForForm(data);

      setLeakDoc({
        id: doc.id,
        ref: doc.ref,
        ...data
      });

      const componentDocs = await getComponentsOfLeak(data);
      const repairComponentDocs = await getRepairComponentsOfLeak(data);

      setLeakData(data);
      setLeakComponents(componentDocs);
      setLeakRepairComponents(repairComponentDocs);

      // reset react hook form with initial data
      reset({ ...formData });

      // setting custom form date
      const repairedAtDate =
        data.repairedAt !== null && data.repairedAt !== undefined ? data.repairedAt.toDate() : null;
      setValue('repairedAt', repairedAtDate);
      setRepairedAtValue(repairedAtDate);

      setLoading(false);
    };

    load();
  }, [id]);

  // Effect to register custom date input
  useEffect(() => {
    if (leakData) {
      register({ name: 'repairedAt' }, { required: leakData.repaired || leakData.notRepairable });
    }

    return () => {
      unregister('repairedAt');
    };
  }, [leakData, register]);

  // update custom from date
  const updateRepairedAt = (date) => {
    // form update & trigger validation
    setValue('repairedAt', date, true);
  };

  // add component to leak
  const addComponent = (srcKey, componentDoc) => {
    if (srcKey === 'components') {
      const update = [...leakComponents];
      update.push(componentDoc);
      setLeakComponents(update);
    }

    if (srcKey === 'repairComponents') {
      const update = [...leakRepairComponents];
      update.push(componentDoc);
      setLeakRepairComponents(update);
    }

    setNonFormDataChanged(true);
  };

  // delete component from  leak
  const deleteComponent = (srcKey, componentDoc) => {
    const srcArray = srcKey === 'components' ? leakComponents : leakRepairComponents;
    const sameComponent = srcArray.filter((component) => component.id === componentDoc.id);
    const withoutComponent = srcArray.filter((component) => component.id !== componentDoc.id);
    sameComponent.pop();
    const update = [...withoutComponent, ...sameComponent];

    if (srcKey === 'components') {
      setLeakComponents(update);
    }

    if (srcKey === 'repairComponents') {
      setLeakRepairComponents(update);
    }

    setNonFormDataChanged(true);
  };

  // cancel form
  const cancel = () => {
    history.push(`/leckage/info/${leakDoc.id}`);
  };

  /**
   * Save customer form data
   * @param {*} data
   */
  const save = async (data) => {
    setLoading(true);

    const formData = { ...data };
    formData.measuredValue = parseAsFloat(formData.measuredValue);
    formData.operatingPressure = parseAsFloat(formData.operatingPressure);

    // transform date to firebase timestamp
    if (formData.repairedAt && formData.repairedAt.toDate === undefined) {
      formData.repairedAt = FirebaseService.getTimestamp(formData.repairedAt);
    }

    const result = await LeakService.handleSubmit(
      leakDoc,
      leakData,
      formData,
      leakComponents,
      leakRepairComponents,
      newImage1,
      newImage2
    );

    if (result) {
      reset({ ...formData });
      setNonFormDataChanged(false);
      setNewImage1(null);
      setNewImage2(null);

      setTimeout(() => {
        history.push(`/leckage/info/${leakDoc.id}`);
      }, 500);
    } else {
      setLoading(false);
      setShowErrorAlert(true);
    }
  };

  const saveImageForUpload = (key, img) => {
    if (key === 'image1') {
      setNewImage1(img);
    }

    if (key === 'image2') {
      setNewImage2(img);
    }

    setNonFormDataChanged(true);
  };

  const renderConfirmLeaveModal = (onConfirm, onCancel) => {
    return (
      <Popup
        show
        size={400}
        title={tn('general.popup.unsaved.title')} // "Ungespeicherte Daten"
        message={tn('general.popup.unsaved.text')} // "Möchten Sie die ungeänderten Daten verwerfen"
        confirmColor="red"
        confirmText={tn('general.popup.unsaved.buttonYes')} // "Ja"
        confirmAction={onConfirm}
        abortAction={onCancel}
        abortText={tn('general.popup.unsaved.buttonNo')} // "Nein"
        abortColor="green"
      />
    );
  };

  const getUnitSuffixByMeasuredDevice = () => {
    let unit = 'dBµV';
    if (leakData) {
      const deviceTypes = MEASURED_DEVICE_TYPES;
      const match = deviceTypes[leakData.measuredDeviceType];

      if (match) {
        unit = match.measuredUnit;
      }
    }
    return unit;
  };

  return (
    <LoadingBox loading={loading} renderChildren={!loading}>
      {leakDoc && (
        <>
          <Banner
            subtext={tn('leak.page.leakform.headline', { id: leakDoc.qrCodeSerial })} // {`Leckage ${leakDoc.qrCodeSerial} bearbeiten`}
          />
          <Box maxWidth={1280} center marginTop={4} marginBottom={4}>
            <form onSubmit={handleSubmit(save)} noValidate>
              <ContentBox
                title={tn('leak.page.leakform.generalData')} // "Leckage Daten"
                noPadding
              >
                <Box display="flex" width="100%" wrap="wrap" padding={3}>
                  <Box padding={2} width="33%">
                    <Label htmlFor="location" required valid={errors.location === undefined}>
                      {tn('general.location')}
                    </Label>
                    <TextInputRH
                      id="location"
                      name="location"
                      ref={register({ required: true, maxLength: 60 })}
                    />
                  </Box>

                  <Box padding={2} width="33%">
                    <Label htmlFor="machine" required valid={errors.machine === undefined}>
                      {tn('general.machine')}
                    </Label>
                    <TextInputRH
                      id="machine"
                      name="machine"
                      ref={register({ required: true, maxLength: 60 })}
                    />
                  </Box>

                  <Box padding={2} width="33%">
                    <Label
                      htmlFor="localization"
                      required
                      valid={errors.localization === undefined}
                    >
                      {tn('general.localization')}
                    </Label>
                    <TextInputRH
                      id="localization"
                      name="localization"
                      ref={register({ required: true, maxLength: 60 })}
                    />
                  </Box>

                  <Box padding={2} width="33%">
                    <Label
                      htmlFor="operatingPressure"
                      required
                      valid={errors.operatingPressure === undefined}
                    >
                      {tn('general.operatingPressure')} in bar
                    </Label>
                    <TextInputRH
                      id="operatingPressure"
                      name="operatingPressure"
                      type="number"
                      ref={register({ required: true })}
                    />
                  </Box>

                  <Box padding={2} width="33%">
                    <Label
                      htmlFor="measuredValue"
                      required
                      valid={errors.measuredValue === undefined}
                    >
                      {getUnitSuffixByMeasuredDevice()}
                      {tn('general.measuredValue')} in dBµV
                    </Label>
                    <TextInputRH
                      id="measuredValue"
                      name="measuredValue"
                      type="number"
                      ref={register({ required: true })}
                    />
                  </Box>

                  <Box padding={2} width="33%">
                    <Label htmlFor="needsCustomerCheck">{tn('general.check')}</Label>
                    <CheckboxRH
                      checked={leakData?.needsCustomerCheck}
                      id="needsCustomerCheck"
                      name="needsCustomerCheck"
                      ref={register({ required: false })}
                    />
                  </Box>
                </Box>
              </ContentBox>

              <ContentBox
                title={tn('general.description')} // "Beschreibung"
                marginTop={6}
              >
                <TextAreaRH
                  id="description"
                  name="description"
                  placeholder=""
                  ref={register({ required: false, maxLength: 250 })}
                />
              </ContentBox>

              <ContentBox
                title={tn('general.components')} // "Mader-Komponenten"
                marginTop={6}
              >
                <ComponentAdd
                  onAdd={(doc) => addComponent('components', doc)}
                  onDelete={(doc) => deleteComponent('components', doc)}
                  components={leakComponents}
                />
              </ContentBox>

              {(leakDoc.repaired || leakDoc.notRepairable) && (
                <>
                  <ContentBox
                    title={tn('general.repairData')} // "Reparaturdaten"
                    marginTop={6}
                  >
                    <Box padding={2} width="25%">
                      <Label
                        htmlFor="repairTime"
                        required={leakDoc.repaired}
                        valid={errors.repairTime === undefined}
                      >
                        {tn('general.repairTime')}
                      </Label>
                      <SelectRH
                        disabled={leakDoc.notRepairable}
                        id="repairTime"
                        name="repairTime"
                        placeholder={tn('general.choose')} // "Bitte wählen"
                        ref={register({ required: leakDoc.repaired })}
                        options={formOptions.repairTime}
                        size="sm"
                      />
                    </Box>

                    <Box padding={2} width="25%">
                      <Label htmlFor="repairedAt" required valid={errors.repairedAt === undefined}>
                        {tn('general.repairedAtShort')}
                      </Label>
                      <DateInput
                        locale={language}
                        id="repairedAt"
                        name="repairedAt"
                        value={repairedAtValue}
                        showR
                        onChange={(date) => updateRepairedAt(date)}
                      />
                    </Box>
                  </ContentBox>

                  <ContentBox
                    title={tn('general.repairDescription')} // "Reparaturbeschreibung"
                    marginTop={6}
                  >
                    <Box display="flex" direction="column" width="100%">
                      <TextAreaRH
                        id="repairDescription"
                        name="repairDescription"
                        placeholder=""
                        ref={register({ required: true, maxLength: 250 })}
                      />
                      {errors.repairDescription !== undefined && (
                        <Text size="sm" color="red">
                          {tn('general.validation.pleaseFillValue')}
                        </Text>
                      )}
                    </Box>
                  </ContentBox>

                  {leakDoc.repaired && (
                    <ContentBox
                      title={tn('general.repairComponents')} // "Mader-Komponenten (Reparatur)"
                      marginTop={6}
                    >
                      <ComponentAdd
                        onAdd={(doc) => addComponent('repairComponents', doc)}
                        onDelete={(doc) => deleteComponent('repairComponents', doc)}
                        components={leakRepairComponents}
                      />
                    </ContentBox>
                  )}
                </>
              )}

              <ContentBox
                title={tn('general.images')} // "Leckage Bilder"
                marginTop={6}
                noPadding
              >
                <ImageGrid leak={leakDoc} showEdit={true} onNewImage={saveImageForUpload} />
              </ContentBox>

              <Box marginTop={5}>
                <Button
                  width={200}
                  type="submit"
                  inline
                  background="green"
                  text={tn('general.save')} // "Speichern"
                />

                <Box display="inline" marginLeft={2}>
                  <Button
                    width={200}
                    background="purple"
                    inline
                    text={tn('general.cancel')} //  "Abbrechen"
                    onClick={() => cancel()}
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </>
      )}

      <Alert
        show={showErrorAlert}
        size={400}
        title={tn('leak.alert.errorOnLeakSave.title')} // "Fehler"
        message={tn('leak.alert.errorOnLeakSave.text')} // "Es ist ein Fehler beim speichern aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Support."
        btnColor="red"
        btnText={tn('leak.alert.errorOnLeakSave.button')} // "OK"
        btnAction={() => setShowErrorAlert(false)}
      />

      <NavigationPrompt when={formState.isDirty || nonFormDataChanged}>
        {({ onConfirm, onCancel }) => renderConfirmLeaveModal(onConfirm, onCancel)}
      </NavigationPrompt>
    </LoadingBox>
  );
}

export default LeakEdit;
