import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Box, Text } from '@looxr/components';
import { AUTH_API_URL } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { DOWNLOAD_PROGRESS_STATUS } from '../constants';
import { useActiveCustomerChanged } from '../hooks';
import { AppStateService, FirebaseService } from '../services';

const StyledText = styled(Text)`
  color: ${(props) => props.theme.colors.grey};
  font-weight: 500;
  text-decoration: none;

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.colors.green} !important;
      font-weight: 600;
    `}

  ${(props) =>
    props.hover !== false &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: none;
        color: ${(props) => props.theme.colors.green};

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    `}
`;

const StyledMenuBox = styled(Box)`
  position: absolute;
  bottom: 45px;
  right: 0;
  min-height: 150px;
  width: 450px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);

  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const StyledMenuContent = styled(Box)`
  width: 100%;
  height: 100%;
`;

const StyledMenuItem = styled(Box)`
  &:hover {
    background-color: ${(props) => props.theme.colors.borderColor};
  }
`;

const StyledHolder = styled(Box)`
  position: relative;
`;

function DownloadContainer() {
  const tn = useTranslation();
  const menueRef = useRef();

  const [unseenActivity, setUnseenActivity] = useState(false);
  const [showMenue, setShowMenue] = useState(false);

  const [sessionUser, setSessionUser] = useState(null);
  const [sessionCustomer, setSessionCustomer] = useState(null);

  const [pdfItems, setPdfItems] = useState([]);
  const [exportItems, setExportItems] = useState([]);

  useActiveCustomerChanged((activeCustomer) => {
    const { user } = AppStateService;

    setSessionCustomer(activeCustomer);
    setSessionUser(user);
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menueRef.current && !menueRef.current.contains(event.target)) {
        setShowMenue(false);
      }
    };

    const { user, activeCustomer } = AppStateService;

    setSessionCustomer(activeCustomer);
    setSessionUser(user);

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const updateDownloadItems = async (type, querySnapshot) => {
      const updatedItems = [];
      const changes = querySnapshot.docChanges();
      let unseen = false;

      querySnapshot.forEach((doc) => {
        const { createdAt, status } = doc.data();

        updatedItems.push({
          id: doc.id,
          type,
          status,
          createdAt
        });
      });

      for (let i = 0; i < changes.length; i += 1) {
        const change = changes[i];

        if (change.type === 'modified') {
          unseen = true;
        }
      }

      if (type === 'pdf') {
        setPdfItems(updatedItems);
      }

      if (type === 'export') {
        setExportItems(updatedItems);
      }

      setUnseenActivity(unseen);
    };

    let pdfUnsub = null;
    let exportUnsub = null;

    if (sessionUser !== null && sessionCustomer !== null) {
      pdfUnsub = FirebaseService.getLeakPDFQuery(sessionCustomer, sessionUser).onSnapshot(
        (snapshot) => {
          updateDownloadItems('pdf', snapshot);
        }
      );

      exportUnsub = FirebaseService.getLeakComponentExportQuery(
        sessionCustomer,
        sessionUser
      ).onSnapshot((snapshot) => {
        updateDownloadItems('export', snapshot);
      });
    }

    return () => {
      if (pdfUnsub) {
        pdfUnsub();
      }
      if (exportUnsub) {
        exportUnsub();
      }
    };
  }, [sessionUser, sessionCustomer]);

  const getLink = ({ id, type }) => {
    if (type === 'pdf') {
      return `${AUTH_API_URL}/generated/${id}/Leakagedocumentation.pdf`;
    }
    if (type === 'export') {
      return `${AUTH_API_URL}/generated/csv-${id}/Export.csv`;
    }

    return null;
  };

  if (sessionCustomer === null || sessionUser === null) {
    return null;
  }

  const getItems = () => {
    const items = [...pdfItems, ...exportItems];

    items.sort((a, b) => {
      if (a.createdAt.toMillis() < b.createdAt.toMillis()) {
        return 1;
      }

      if (a.createdAt.toMillis() > b.createdAt.toMillis()) {
        return -1;
      }

      return 0;
    });

    return items;
  };

  return (
    <StyledHolder>
      <Box>
        <StyledText
          align="center"
          onClick={() => {
            setShowMenue(true);
            setUnseenActivity(false);
          }}
          active={unseenActivity}
        >
          {tn('general.footer.downloads')}
          {/* Downloads */}
        </StyledText>
      </Box>

      <StyledMenuBox ref={menueRef} visible={showMenue}>
        <StyledMenuContent display="flex" justify="center" align="center" direction="column">
          {getItems().map((item) => (
            <StyledMenuItem
              key={item.id}
              display="flex"
              justify="space-between"
              direction="row"
              paddingY={2}
              paddingX={6}
            >
              <Box width="150px">
                <Text size="sm">
                  {moment(item.createdAt.toDate()).format('DD.MM.YYYY - HH:mm:ss')}
                </Text>
              </Box>

              <Box width="150px">
                <Text size="sm">{tn(`general.footer.downloadOverlay.types.${item.type}`)}</Text>
              </Box>

              <Box width="150px">
                <StyledText size="sm" hover={item.status === DOWNLOAD_PROGRESS_STATUS.done}>
                  {item.status === DOWNLOAD_PROGRESS_STATUS.done && (
                    <a
                      href={getLink(item)}
                      alt="download"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tn('general.footer.downloadOverlay.download')}
                      {/* Herunterladen */}
                    </a>
                  )}

                  {/* 'Wird erstellt ...' */}
                  {item.status === DOWNLOAD_PROGRESS_STATUS.progress &&
                    tn('general.footer.downloadOverlay.inProgress')}
                  {/* 'Fehler beim Erstellen' */}
                  {item.status === DOWNLOAD_PROGRESS_STATUS.error &&
                    tn('general.footer.downloadOverlay.hasError')}
                </StyledText>
              </Box>
            </StyledMenuItem>
          ))}

          {pdfItems.length === 0 && exportItems.length === 0 && (
            <Box display="flex" justify="center" alignItems="center" height="150px">
              <Text size="sm" color="grey">
                {tn('general.footer.downloadOverlay.noData')}
              </Text>
            </Box>
          )}
        </StyledMenuContent>
      </StyledMenuBox>
    </StyledHolder>
  );
}

export default DownloadContainer;
