import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Button, LoadingBox, Modal } from '@looxr/components';
import { AUTH_API_URL } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { ApiService, AppStateService } from '../services';
import QrPdfForm from './QrPdfForm';

function QrPdfModal({ show, onClose, qrCodes }) {
  const tn = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();

  const generatePDF = async (config) => {
    setLoading(true);
    const currentCustomer = AppStateService.activeCustomer;
    const response = await ApiService.generateQrCodePDF(currentCustomer, qrCodes, config);

    if (response && response.uri) {
      // setLoadingPDF(true);
      const url = `${AUTH_API_URL}${response.uri}`;
      setPdfUrl(url);
    } else {
      // @TODO handle error
      setLoading(false);
    }
  };

  const saveAndClose = () => {
    setPdfUrl(null);
    onClose(true);
  };

  const onFrameLoaded = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const onFrameError = () => {
    setPdfUrl(null);
    setLoading(false);
  };

  return (
    <Modal
      size={800}
      show={show}
      onClose={onClose}
      closeOnBackdrop={false}
      closeOnEsc={false}
      showModalCloseIcon={false}
    >
      <LoadingBox display="flex" width="100%" loading={loading}>
        {pdfUrl ? (
          <Box display="flex" width="100%" height="500px" direction="column">
            <iframe
              title="preview"
              src={pdfUrl}
              width="100%"
              onLoad={onFrameLoaded}
              onError={onFrameError}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              style={{ flex: '1', display: 'flex', height: '350px' }}
            />
            <Box marginTop={8} display="flex" direction="row" justify="center">
              <Button
                text={tn('general.back')} // "Zurück"
                onClick={() => setPdfUrl(null)}
                size="sm"
                width={200}
                inline
              />
              <Box marginLeft={3}>
                <Button
                  text={tn('general.close')} //  "Schließen"
                  onClick={() => saveAndClose()}
                  size="sm"
                  width={200}
                  background="purple"
                  inline
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <QrPdfForm onUpdate={generatePDF} onCancel={onClose} />
          </Box>
        )}
      </LoadingBox>
    </Modal>
  );
}

QrPdfModal.propTypes = {
  qrCodes: PropTypes.array.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func
};

QrPdfModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export default QrPdfModal;
