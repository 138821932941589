import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';

const StyledMoreMenu = styled(Box)``;

const StyledMoreMenuItem = styled(Box)`
  cursor: pointer;
  span {
    cursor: pointer;
    color: ${(props) => props.theme.colors.grey};
    font-size: 16px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.purple};
    span {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

function ExportMoreMenu({ onItemClick }) {
  const tn = useTranslation();

  return (
    <StyledMoreMenu
      background="white"
      display="flex"
      direction="column"
      justify="left"
      alignItems="left"
      noElevation={false}
    >
      <StyledMoreMenuItem paddingX={3} paddingY={3} onClick={() => onItemClick('componentList')}>
        <Text>
          {tn('leak.page.dashboard.exports.componentList')}
          {/* Komponenten Liste CSV */}
        </Text>
      </StyledMoreMenuItem>

      <StyledMoreMenuItem
        paddingX={3}
        paddingY={3}
        onClick={() => onItemClick('repairComponentList')}
      >
        <Text>
          {tn('leak.page.dashboard.exports.repairComponentList')}
          {/* Reparatur Komponenten Liste CSV */}
        </Text>
      </StyledMoreMenuItem>
    </StyledMoreMenu>
  );
}

ExportMoreMenu.propTypes = {
  onItemClick: PropTypes.func
};

ExportMoreMenu.defaultProps = {
  onItemClick: () => {}
};

export default ExportMoreMenu;
