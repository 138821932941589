import {
  getCheckOptions,
  getHasComponentOptions,
  getLeakRepairTimeOptions,
  getQrPrintedOptions,
  getQrUsedOptions,
  getRepairOptions,
  getValenceOptions
} from '../constants';

function useFormOptions() {
  const translatedOptions = [];

  // mapping name to the option constant
  const formOptionsMap = {
    hasComponents: {
      options: getHasComponentOptions(),
      sort: false
    },
    valence: {
      options: getValenceOptions(),
      sort: false
    },
    repair: {
      options: getRepairOptions(),
      sort: false
    },
    check: {
      options: getCheckOptions(),
      sort: false
    },
    qrPrinted: {
      options: getQrPrintedOptions(),
      sort: false
    },
    qrUsed: {
      options: getQrUsedOptions(),
      sort: false
    },
    repairTime: {
      options: getLeakRepairTimeOptions(),
      sort: false
    }
  };

  const availableOptions = Object.keys(formOptionsMap);

  availableOptions.map((optionName) => {
    const { options, sort } = formOptionsMap[optionName];
    const translatedOptionSet = options.map((option) => {
      const i18nOption = {};
      i18nOption.value = option.value;
      i18nOption.label = option.label;
      return i18nOption;
    });

    // Sort option set by translated label name
    if (sort) {
      translatedOptionSet.sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    translatedOptions[optionName] = translatedOptionSet;

    return optionName;
  });

  return translatedOptions;
}

export default useFormOptions;
