import { useCallback } from 'react';
import { useTranslation } from '@looxr/utils';
// import useNumberFormat from './useNumberFormat';

// @TODO wrap in use callback ?
function useLeakCharts() {
  const tn = useTranslation();

  // 0.001 will be rounded to 0 for the label
  // but it will show the bar for some pixels to appear
  // otherwhise 0 value will not have a bar and nothing will be rendered
  const closeToZeroValue = 0;

  const getCountData = useCallback((summary) => {
    return {
      config: [
        {
          key: 'count',
          name: tn('leak.page.dashboard.barChart.total'), // 'Gesamt'
          color: '#46b9a0',
          labelDigits: 0,
          labelRound: false,
          labelOffsetX: 100
        },
        {
          key: 'countRepaired',
          name: tn('leak.page.dashboard.barChart.repaired'), // 'Repariert'
          color: '#841d64',
          labelDigits: 0,
          labelRound: false,
          labelOffsetX: 100
        }
      ],
      data: [
        {
          count: summary.count > 0 ? summary.count : closeToZeroValue,
          countRepaired: summary.repairedCount > 0 ? summary.repairedCount : closeToZeroValue,
          name: tn('leak.page.dashboard.barChart.total'), // 'Gesamt'
          nameRepaired: tn('leak.page.dashboard.barChart.repaired') // 'Repariert'
        }
      ]
    };
  });

  const getCostsData = useCallback((summary) => {
    const hasEnergyCosts = summary.energyCosts && summary.energyCosts > 0;
    const hasRepairEnergyCosts = summary.energyCostsRepaired && summary.energyCostsRepaired > 0;

    return {
      config: [
        {
          key: 'costs',
          name: tn('leak.page.dashboard.barChart.total'), // 'Gesamt'
          color: '#46b9a0',
          labelDigits: hasEnergyCosts ? 2 : 0,
          labelRound: true,
          labelSuffix: '€',
          labelOffsetX: 120
        },
        {
          key: 'costsRepaired',
          name: tn('leak.page.dashboard.barChart.repaired'), // 'Repariert'
          color: '#841d64',
          labelDigits: hasRepairEnergyCosts ? 2 : 0,
          labelSuffix: '€',
          labelOffsetX: 120
        }
      ],
      data: [
        {
          costs: hasEnergyCosts ? summary.energyCosts : closeToZeroValue,
          costsRepaired: hasRepairEnergyCosts ? summary.energyCostsRepaired : closeToZeroValue,
          name: tn('leak.page.dashboard.barChart.total'), // 'Gesamt'
          nameRepaired: tn('leak.page.dashboard.barChart.repaired') // 'Repariert'
        }
      ]
    };
  });

  const getOverTimeData = async (leaks, customer) => {
    return new Promise((resolve) => {
      const worker = new Worker('worker/costOverTimeWorker.worker.js');

      worker.addEventListener(
        'message',
        (event) => {
          resolve(event.data);
        },
        false
      );

      // Give the worker the data needed to process the data
      worker.postMessage({ leaks, customer });
    });
  };

  return {
    getOverTimeData,
    getCountData,
    getCostsData
  };
}

export default useLeakCharts;
