import React, { useEffect } from 'react';
import { Box, LoadingOverlay } from '@looxr/components';
import { AppStateService, AuthService, FirebaseService, StorageService } from '../../services';

function Logout() {
  useEffect(() => {
    const performLogout = async () => {
      AppStateService.removeUser();

      await StorageService.removeAll();
      await FirebaseService.logout();

      AuthService.logout();
    };

    performLogout();
  }, []);

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      alignItems="center"
      justify="center"
      position="absolute"
      left="0"
      top="0"
    >
      <LoadingOverlay isVisible={true} translucent={false} fullOverlay={true} />
    </Box>
  );
}

export default Logout;
