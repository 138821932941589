import moment from 'moment';
/* eslint-disable react/display-name */
import React from 'react';
import { NumberText, Text } from '@looxr/components';
import { getCountryOptions } from '@looxr/constants';
import { SORT_NUMBER, SORT_STRING, Translate } from '@looxr/utils';
import { getLeakRepairStatusText } from '@looxr/utils/src/LeakUtils';
import QrIcon from '../components/QrIcon';
import ValenceText from '../components/ValenceText';
import { AppStateService } from '../services';
import LeakCalcService from '../services/LeakCalcService';
import { VALENCY_TYPES } from './Form';

export const getLeakLocationTableConfig = () => {
  return [
    {
      key: 'location',
      label: Translate.tn('general.location'), // Bereich
      sortValue: 'location',
      outputValue: (dataRow) => <Text size="sm">{dataRow.location}</Text>,
      sortable: true,
      sortType: SORT_STRING,
      headerStyle: {
        width: '200px',
        textAlign: 'left'
      }
    },
    {
      key: 'costs',
      label: Translate.tn('leak.page.dashboard.leakCostTable.cost'), // Kosten
      width: '100px',
      sortValue: 'costs',
      outputValue: (dataRow) => (
        <NumberText
          number={dataRow.costs}
          digits={2}
          round={true}
          suffix="€"
          size="sm"
          align="right"
        />
      ),
      sortable: true,
      sortType: SORT_NUMBER,
      headerStyle: {
        width: '150px',
        textAlign: 'right'
      }
    }
  ];
};

// ====================================================
// LEAKS
// ====================================================
export const getLeakTableConfig = () => {
  return [
    {
      key: 'id',
      label: Translate.tn('general.id'), // ID
      sortValue: 'qrCodeSerial',
      outputValue: (dataRow) => <Text size="sm">{dataRow.qrCodeSerial}</Text>,
      sortable: true,
      sortType: SORT_NUMBER
    },
    {
      key: 'location',
      label: Translate.tn('general.location'), // Bereich
      sortValue: 'location',
      outputValue: (dataRow) => <Text size="sm">{dataRow.location}</Text>,
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'machine',
      label: Translate.tn('general.machine'), // Maschine
      sortValue: 'machine',
      outputValue: (dataRow) => <Text size="sm">{dataRow.machine}</Text>,
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'energy_costs',
      label: Translate.tn('leak.page.dashboard.leakTable.energyCosts'), // Energiekosten €/a
      sortValue: (dataRow) => {
        const currentCustomer = AppStateService.activeCustomer;
        return LeakCalcService.energyCostsPerYear(dataRow, currentCustomer);
      },
      headerStyle: {
        textAlign: 'right'
      },
      outputValue: (dataRow) => {
        const currentCustomer = AppStateService.activeCustomer;
        const costs = LeakCalcService.energyCostsPerYear(dataRow, currentCustomer);
        return (
          <NumberText number={costs} digits={2} round={true} size="sm" align="right" suffix="€" />
        );
      },
      sortable: true,
      sortType: SORT_NUMBER
    },
    {
      key: 'valence',
      label: Translate.tn('general.valence'), // Wertigkeit
      sortValue: (dataRow) => {
        switch (dataRow.valence) {
          case VALENCY_TYPES.low:
            return 1;
          case VALENCY_TYPES.mid:
            return 2;
          case VALENCY_TYPES.high:
            return 3;
          default:
            return 0;
        }
      },
      headerStyle: {
        textAlign: 'center'
      },
      outputValue: (dataRow) => {
        return <ValenceText valence={dataRow.valence} size="sm" align="center" />;
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'hasComponents',
      label: Translate.tn('general.components'),
      sortValue: (dataRow) => {
        return dataRow.components.ids.length;
      },
      headerStyle: {
        textAlign: 'center'
      },
      outputValue: (dataRow) => (
        <Text size="sm" align="center">
          {dataRow.components.ids.length}
        </Text>
      ),
      sortable: true,
      sortType: SORT_NUMBER
    },
    {
      key: 'needsCustomerCheck',
      label: Translate.tn('general.check'),
      sortValue: 'needsCustomerCheck',
      headerStyle: {
        textAlign: 'center'
      },
      outputValue: (dataRow) => (
        <Text size="sm" align="center">
          {dataRow.needsCustomerCheck ? Translate.tn('general.yes') : Translate.tn('general.no')}
        </Text>
      ),
      sortable: true,
      sortType: SORT_NUMBER
    },
    {
      key: 'createdAt',
      label: Translate.tn('general.createdAt'), // Erfasst am
      sortValue: 'createdAt',
      headerStyle: {
        textAlign: 'center'
      },
      outputValue: (dataRow) => {
        let dateStr = '';
        if (dataRow.createdAt) {
          dateStr = moment(dataRow.createdAt.toDate()).format('DD.MM.YYYY');
        }
        return (
          <Text size="sm" align="center">
            {dateStr}
          </Text>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'repaired',
      label: Translate.tn('general.repairStatusText'), // Repariert
      sortValue: (dataRow) => {
        if (dataRow.repaired === true) {
          return 1;
        }

        if (dataRow.notRepairable === true) {
          return 2;
        }

        return 0;
      },
      headerStyle: {
        width: '140px',
        textAlign: 'right',
        borderLeft: '1px solid rgba(29,29,27,.12)'
      },
      columnStyle: {
        borderLeft: '1px solid rgba(29,29,27,.12)'
      },
      outputValue: (dataRow) => (
        <Text size="sm" align="right">
          {Translate.tn(getLeakRepairStatusText(dataRow))}
        </Text>
      ),
      sortable: true,
      sortType: SORT_NUMBER
    },
    {
      key: 'repairedAt',
      label: Translate.tn('general.repairedAtShort'), // Repariert am
      sortValue: 'repairedAt',
      headerStyle: {
        width: '120px',
        textAlign: 'right'
      },
      outputValue: (dataRow) => {
        let dateStr = '';
        if (dataRow.repairedAt !== undefined && dataRow.repairedAt !== null) {
          dateStr = moment(dataRow.repairedAt.toDate()).format('DD.MM.YYYY');
        }
        return (
          <Text size="sm" align="right">
            {dateStr}
          </Text>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    }
  ];
};

// ====================================================
// QR CODES
// ====================================================
export const getQrCodeTableConfig = () => {
  return [
    {
      key: 'serial',
      label: Translate.tn('general.id'), // ID
      sortValue: 'serial',
      outputValue: (dataRow) => {
        return <Text size="sm">{dataRow.serial}</Text>;
      },
      sortable: true,
      sortType: SORT_NUMBER
    },
    {
      key: 'printedAt',
      label: Translate.tn('general.printedAt'), // Gedruckt am
      sortValue: 'printedAt',
      headerStyle: {
        textAlign: 'center'
      },
      outputValue: (dataRow) => {
        let dateStr = '';
        if (dataRow.printedAt) {
          dateStr = moment(dataRow.printedAt.toDate()).format('DD.MM.YYYY');
        }
        return (
          <Text size="sm" align="center">
            {dateStr}
          </Text>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'usedAt',
      label: Translate.tn('general.usedAt'), // Benutzt am
      sortValue: 'usedAt',
      headerStyle: {
        textAlign: 'center'
      },
      outputValue: (dataRow) => {
        let dateStr = '';
        if (dataRow.used && dataRow.usedAt) {
          dateStr = moment(dataRow.usedAt.toDate()).format('DD.MM.YYYY');
        }
        return (
          <Text size="sm" align="center">
            {dateStr}
          </Text>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'dataUrl',
      label: '',
      sortable: false,
      headerStyle: {
        textAlign: 'right',
        width: '50px'
      },
      outputValue: (dataRow) => <QrIcon data={dataRow} />
    }
  ];
};

// ====================================================
// CUSTOMER SELECT
// ====================================================
export const getCustomerSelectTableConfig = () => {
  return [
    {
      key: 'id',
      label: Translate.tn('general.id'), // 'ID',
      sortValue: 'id',
      outputValue: (dataRow) => {
        return <Text size="sm">#{dataRow.id.substring(0, 5).toUpperCase()}</Text>;
      },
      sortable: false
    },
    {
      key: 'name1',
      label: Translate.tn('general.name'), // 'Name',
      sortValue: 'name1',
      outputValue: (dataRow) => {
        return <Text size="sm">{dataRow.name1}</Text>;
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'zip',
      label: Translate.tn('general.zip'), // 'PLZ',
      sortValue: 'zip',
      outputValue: (dataRow) => {
        return <Text size="sm">{dataRow.zip}</Text>;
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'city',
      label: Translate.tn('general.city'), // 'Stadt',
      sortValue: 'city',
      outputValue: (dataRow) => {
        return <Text size="sm">{dataRow.city}</Text>;
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'country',
      label: Translate.tn('general.country'), // 'Land',
      sortValue: 'country',
      outputValue: (dataRow) => {
        const option = getCountryOptions().filter((option) => option.value === dataRow.country)[0];
        return <Text size="sm">{option.label}</Text>;
      },
      sortable: true,
      sortType: SORT_STRING
    }
  ];
};
