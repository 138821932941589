import { Translate } from '@looxr/utils';

export const DOWNLOAD_PROGRESS_STATUS = {
  progress: 'IN_PROGRESS',
  error: 'ERROR',
  done: 'DONE'
};

export const VALENCY_TYPES = {
  low: 'VALENCE_LOW',
  mid: 'VALENCE_MID',
  high: 'VALENCE_HIGH'
};

export const getValenceOptions = () => {
  return [
    { label: Translate.tn('leak.formOptions.valence.all'), value: 'all' },
    { label: Translate.tn('general.valenceText.low'), value: VALENCY_TYPES.low },
    { label: Translate.tn('general.valenceText.mid'), value: VALENCY_TYPES.mid },
    { label: Translate.tn('general.valenceText.high'), value: VALENCY_TYPES.high }
  ];
};

export const getRepairOptions = () => {
  return [
    { label: Translate.tn('leak.formOptions.repaired.all'), value: 'all' },
    { label: Translate.tn('leak.formOptions.repaired.repaired'), value: 'repaired' },
    { label: Translate.tn('leak.formOptions.repaired.notRepaired'), value: 'notrepaired' },
    { label: Translate.tn('leak.formOptions.repaired.notRepairable'), value: 'notrepairable' }
  ];
};

export const getHasComponentOptions = () => {
  return [
    { label: Translate.tn('leak.formOptions.hasComponents.all'), value: 'all' },
    { label: Translate.tn('leak.formOptions.hasComponents.hasComponents'), value: 'true' },
    { label: Translate.tn('leak.formOptions.hasComponents.hasNoComponents'), value: 'false' }
  ];
};

export const getCheckOptions = () => {
  return [
    { label: Translate.tn('leak.formOptions.check.all'), value: 'all' },
    { label: Translate.tn('leak.formOptions.check.needsCheck'), value: 'true' },
    { label: Translate.tn('leak.formOptions.check.noCheck'), value: 'false' }
  ];
};

export const getQrPrintedOptions = () => {
  return [
    { label: Translate.tn('leak.formOptions.qrPrinted.all'), value: 'all' },
    { label: Translate.tn('leak.formOptions.qrPrinted.printed'), value: 'true' },
    { label: Translate.tn('leak.formOptions.qrPrinted.notPrinted'), value: 'false' }
  ];
};

export const getQrUsedOptions = () => {
  return [
    { label: Translate.tn('leak.formOptions.qrUsed.all'), value: 'all' },
    { label: Translate.tn('leak.formOptions.qrUsed.used'), value: 'true' },
    { label: Translate.tn('leak.formOptions.qrUsed.notUsed'), value: 'false' }
  ];
};

export const getLeakRepairTimeOptions = () => {
  return [
    { label: Translate.tn('leak.formOptions.repairTime', { time: 1 }), value: 1 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 2 }), value: 2 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 3 }), value: 3 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 4 }), value: 4 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 5 }), value: 5 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 6 }), value: 6 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 7 }), value: 7 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 8 }), value: 8 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 9 }), value: 9 },
    { label: Translate.tn('leak.formOptions.repairTime', { time: 10 }), value: 10 }
  ];
};
