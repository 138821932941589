import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Banner,
  Box,
  Button,
  ContentBox,
  LoadingBox,
  MoreMenuIcon,
  NumberText,
  Popup,
  Text
} from '@looxr/components';
import { Collections, MEASURED_DEVICE_TYPES, getMeasuredDeviceTypeOptions } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { getLeakRepairStatusText } from '@looxr/utils/src/LeakUtils';
import { ImageGrid, TextBlock, ValenceText } from '../../components';
import { useFormOptions, useLoadComponents, useLoadLeaks } from '../../hooks';
import { AppStateService, FirebaseService, LeakCalcService } from '../../services';
import LeakMoreMenu from './LeakMoreMenu';

function Leak() {
  const tn = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const formOptions = useFormOptions();

  const { getLeakByIDQuery } = useLoadLeaks();
  const { getRepairComponentsOfLeak, getComponentsOfLeak } = useLoadComponents();

  const [loading, setLoading] = useState(true);
  const [leak, setLeak] = useState(null);
  const [leakComponents, setLeakComponents] = useState([]);
  const [leakRepairComponents, setLeakRepairComponents] = useState([]);
  const [leakSummary, setLeakSummary] = useState(null);

  const [showRepairPopup, setShowRepairPopup] = useState(false);
  const [showUnRepairPopup, setShowUnRepairPopup] = useState(false);

  useEffect(() => {
    let unsub = null;

    const updateData = async ({ doc, customer }) => {
      const data = doc.data();
      const summary = LeakCalcService.calculateValues(data, customer);

      const componentDocs = await getComponentsOfLeak(data);
      const repairComponentDocs = await getRepairComponentsOfLeak(data);

      setLeakSummary(summary);
      setLeakComponents(componentDocs);
      setLeakRepairComponents(repairComponentDocs);

      setLeak({
        id: doc.id,
        ref: doc.ref,
        ...data
      });

      setLoading(false);
    };

    if (id) {
      unsub = getLeakByIDQuery(id).onSnapshot((doc) => {
        updateData({ doc, customer: AppStateService.activeCustomer });
      });
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [id]);

  const onEditButtonClick = () => history.push(`/leckage/edit/${leak.id}`);

  const repairLeak = async () => {
    setShowRepairPopup(false);
    setLoading(true);
    const update = { ...leak };

    update.notRepairable = false;
    update.repaired = true;
    update.repairTime = null;
    update.repairedBy = AppStateService.user.ref;
    update.repairedByName = `${AppStateService.user.firstname} ${AppStateService.user.lastname}`;
    update.repairedAt = FirebaseService.getTimestamp();

    const fireUpdate = { ...update };
    delete fireUpdate.id;
    delete fireUpdate.ref;

    // const result = await FirebaseService.upsertDoc(leak.id, update, Collections.leak);
    await FirebaseService.upsertDoc(leak.id, fireUpdate, Collections.leak);

    setLeak(update);

    setLoading(false);
    /*
    if (!result) {
      setShowErrorAlert(true);
    }
    */
  };

  const unrepairLeak = async () => {
    setShowUnRepairPopup(false);
    setLoading(true);
    const update = { ...leak };

    update.notRepairable = false;
    update.repaired = false;
    update.repairTime = null;
    update.repairComponents = { ids: [], refs: [] };
    update.repairedBy = null;
    update.repairedByName = null;
    update.repairedAt = null;

    const fireUpdate = { ...update };
    delete fireUpdate.id;
    delete fireUpdate.ref;
    // const result = await FirebaseService.upsertDoc(leak.id, update, Collections.leak);
    await FirebaseService.upsertDoc(leak.id, fireUpdate, Collections.leak);

    setLeak(update);
    setLoading(false);
    /*
    if (!result) {
      setShowErrorAlert(true);
    }
    */
  };

  const getMeasuredDeviceLabel = () => {
    const options = getMeasuredDeviceTypeOptions();
    const match = options.filter((o) => o.value === leak.measuredDeviceType)[0];
    return match ? match.label : '-';
  };

  const getUnitSuffixByMeasuredDevice = () => {
    const deviceTypes = MEASURED_DEVICE_TYPES;
    const match = deviceTypes[leak.measuredDeviceType];
    let unit = 'dBµV';
    if (match) {
      unit = match.measuredUnit;
    }
    return unit;
  };

  const handleMoreActionClick = async (action) => {
    // not needed to set a loading since the leak listener will trigger upon change
    const update = { ...leak };

    const notRepairableStatus = action === 'setnotrepairable';
    // set not repair able status flag
    update.notRepairable = notRepairableStatus;

    update.repaired = false;
    update.repairTime = null;
    update.repairedBy = AppStateService.user.ref;
    update.repairedByName = `${AppStateService.user.firstname} ${AppStateService.user.lastname}`;
    update.repairedAt = FirebaseService.getTimestamp();

    const fireUpdate = { ...update };
    delete fireUpdate.id;
    delete fireUpdate.ref;

    await FirebaseService.upsertDoc(leak.id, fireUpdate, Collections.leak);

    setLeak(update);
  };

  return (
    <LoadingBox loading={loading} renderChildren={!loading}>
      {leak && (
        <>
          <Banner
            subtext={tn('leak.page.leakdetail.headline', { id: leak.qrCodeSerial })} // {`Leckage ${leak.qrCodeSerial}`}
          />
          <Box maxWidth={1280} center marginTop={4} marginBottom={4}>
            <ContentBox
              title={tn('leak.page.leakdetail.generalData')} // "Leckage Daten"
            >
              {AppStateService.isLOOXR() ? (
                <MoreMenuIcon
                  text={tn('leak.page.moreMenu.title')}
                  menuContent={LeakMoreMenu}
                  data={leak}
                  onMenuItemClick={(action) => handleMoreActionClick(action)}
                />
              ) : null}
              <Box width="100%" wrap="wrap">
                <Box display="flex" width="100%">
                  <TextBlock
                    label={tn('general.location')} // "Bereich"
                    value={leak.location}
                  />
                  <TextBlock
                    label={tn('general.machine')} // "Maschine"
                    value={leak.machine}
                  />
                  <TextBlock
                    label={tn('general.localization')} // "Lokalisierung"
                    value={leak.localization}
                  />
                  <TextBlock
                    label={tn('general.check')} // "Prüfung"
                    value={leak.needsCustomerCheck ? tn('general.yes') : tn('general.no')}
                  />
                  <TextBlock
                    label={tn('general.repairStatusText')} // "Repariert"
                    value={tn(getLeakRepairStatusText(leak))}
                  />
                </Box>

                <Box display="flex" width="100%" marginTop={6}>
                  <TextBlock
                    label={tn('general.createdAt')} // "Erfasst am"
                  >
                    <Text size="sm">
                      {moment(leak.createdAt.toDate()).format('DD.MM.YYYY HH:mm:ss')}
                    </Text>
                  </TextBlock>

                  <TextBlock
                    label={tn('general.operatingPressure')} // "Betriebsdruck"
                  >
                    <NumberText
                      number={parseFloat(leak.operatingPressure)}
                      digits={2}
                      round={false}
                      suffix="bar"
                      size="sm"
                    />
                  </TextBlock>

                  <TextBlock
                    label={tn('general.measuredValue')} // "Messwert"
                  >
                    <NumberText
                      number={parseFloat(leak.measuredValue)}
                      digits={2}
                      round={false}
                      suffix={getUnitSuffixByMeasuredDevice()}
                      size="sm"
                    />
                  </TextBlock>

                  <TextBlock
                    label={tn('general.measuredDeviceType')} // "Messgerät"
                  >
                    <Text size="sm">{getMeasuredDeviceLabel()}</Text>
                  </TextBlock>

                  <TextBlock
                    label={tn('general.valence')} // "Wertigkeit"
                  >
                    <ValenceText valence={leak.valence} size="sm" />
                  </TextBlock>
                </Box>
              </Box>
            </ContentBox>

            <ContentBox title={tn('leak.page.leakdetail.summary')} marginTop={6}>
              <Box width="100%" wrap="wrap">
                <Box display="flex" width="100%">
                  <TextBlock
                    label={tn('general.airLoss')} // "Luftverlust"
                  >
                    <NumberText
                      number={leakSummary.airLoss}
                      digits={2}
                      round={false}
                      suffix="l/h"
                      size="sm"
                    />
                  </TextBlock>
                  <TextBlock
                    label={tn('general.energyConsumption')} // "Stromverbrauch"
                  >
                    <NumberText
                      number={leakSummary.energyConsumption}
                      digits={2}
                      round={false}
                      suffix="kWh"
                      size="sm"
                    />
                  </TextBlock>
                  <TextBlock
                    label={tn('general.energyConsumption')} // "Stromverbrauch"
                  >
                    <NumberText
                      number={leakSummary.energyConsumptionYear}
                      digits={2}
                      round={false}
                      suffix={tn('general.kwhPerYear')} // "kWh / Jahr"
                      size="sm"
                    />
                  </TextBlock>
                  <TextBlock
                    label={tn('general.energyCosts')} // "Energiekosten"
                  >
                    <NumberText
                      number={leakSummary.energyCosts}
                      digits={2}
                      round={false}
                      suffix={tn('general.euroPerYear')}
                      size="sm"
                    />
                  </TextBlock>

                  <TextBlock
                    label={tn('general.emissionOnly')} // "Emission"
                  >
                    <NumberText
                      number={leakSummary.emissionPerYear}
                      digits={2}
                      round={false}
                      suffix={tn('general.emissionPerYear')} // "CO₂ kg / Jahr"
                      size="sm"
                    />
                  </TextBlock>
                </Box>
              </Box>
            </ContentBox>

            <ContentBox
              title={tn('general.description')} // "Beschreibung"
              marginTop={6}
            >
              <Text size="sm">{leak.description ? leak.description : ''}</Text>
            </ContentBox>

            {leakComponents.length > 0 && (
              <ContentBox
                title={tn('general.components')} // "Mader-Komponenten"
                marginTop={6}
                noPadding
              >
                <Box display="flex" direction="column" width="100%" paddingY={2}>
                  {leakComponents.map((component, i) => (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={`component-${component.id}-${i}`}
                      display="flex"
                      width="100%"
                      paddingY={2}
                      paddingX={4}
                    >
                      <TextBlock
                        label={tn('general.articleNr')} // "Mader-Artikel-Nr"
                        value={component.articleNr}
                      />
                      <TextBlock
                        label={`${tn('general.description')} 1`} // "Beschreibung 1"
                        value={component.description1}
                        width="40%"
                      />
                      <TextBlock
                        label={`${tn('general.description')} 2`} // "Beschreibung 1"
                        value={component.description2}
                      />
                    </Box>
                  ))}
                </Box>
              </ContentBox>
            )}

            {(leak.repaired || leak.notRepairable) && (
              <>
                <ContentBox
                  title={tn('general.repairData')} // "Reparaturdaten"
                  marginTop={6}
                >
                  <TextBlock
                    label={tn('general.repairedByShort')} // "Repariert durch"
                    value={leak.repairedByName}
                  />
                  <TextBlock
                    label={tn('general.repairedAtShort')} // "Repariert am"
                  >
                    <Text size="sm">
                      {leak.repairedAt ? moment(leak.repairedAt.toDate()).format('DD.MM.YYYY') : ''}
                    </Text>
                  </TextBlock>
                  <TextBlock
                    label={tn('general.repairTime')} // "Zeit"
                  >
                    <Text size="sm">
                      {leak.repairTime
                        ? formOptions.repairTime.find((o) => `${o.value}` === `${leak.repairTime}`)
                            .label
                        : '-'}
                    </Text>
                  </TextBlock>
                  <Box width="25%" />
                </ContentBox>

                <ContentBox
                  title={tn('general.repairDescription')} // "Reparaturbeschreibung"
                  marginTop={6}
                >
                  <Text size="sm">{leak.repairDescription}</Text>
                </ContentBox>

                {leakRepairComponents.length > 0 && (
                  <ContentBox
                    title={tn('general.repairComponents')} // "Mader-Komponenten (Reparatur)"
                    marginTop={6}
                    noPadding
                  >
                    <Box display="flex" direction="column" width="100%" paddingY={2}>
                      {leakRepairComponents.map((component, i) => (
                        <Box
                          // eslint-disable-next-line react/no-array-index-key
                          key={`component-${component.id}-${i}`}
                          display="flex"
                          width="100%"
                          paddingY={2}
                          paddingX={4}
                        >
                          <TextBlock
                            label={tn('general.articleNr')} // "Mader-Artikel-Nr"
                            value={component.articleNr}
                          />
                          <TextBlock
                            label={`${tn('general.description')} 1`} // "Beschreibung 1"
                            value={component.description1}
                            width="40%"
                          />
                          <TextBlock
                            label={`${tn('general.description')} 2`} // "Beschreibung 2"
                            value={component.description2}
                          />
                        </Box>
                      ))}
                    </Box>
                  </ContentBox>
                )}
              </>
            )}

            <ContentBox
              title={tn('general.images')} // "Leckage Bilder"
              marginTop={6}
              noPadding
            >
              <ImageGrid leak={leak} showEdit={false} />
            </ContentBox>

            {(AppStateService.isLOOXR() || AppStateService.isLeakAdmin()) && (
              <Box marginTop={5}>
                <Button
                  width={200}
                  onClick={onEditButtonClick}
                  inline
                  background="blue"
                  text={tn('general.edit')} // "Leckage bearbeiten"
                />

                {leak.repaired ? (
                  <Box display="inline" marginLeft={2}>
                    <Button
                      width={200}
                      background="purple"
                      inline
                      text={tn('general.reset')} // "Reparatur zurücksetzen"
                      onClick={() => setShowUnRepairPopup(true)}
                    />
                  </Box>
                ) : (
                  <Box display="inline" marginLeft={2}>
                    <Button
                      width={200}
                      background="purple"
                      inline
                      text={tn('general.repair')} // "Reparatur durchführen"
                      onClick={() => setShowRepairPopup(true)}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </>
      )}

      <Popup
        show={showRepairPopup}
        title={tn('leak.popup.repair.title')} // "Leckage reparieren"
        message={tn('leak.popup.repair.text')} // "Möchten Sie diese Leckage reparieren ?"
        confirmAction={() => repairLeak()}
        confirmText={tn('leak.popup.repair.buttonYes')} // "Jetzt reparieren"
        abortAction={() => setShowRepairPopup(false)}
        abortText={tn('leak.popup.repair.buttonNo')} // "Nicht reparieren"
      />

      <Popup
        show={showUnRepairPopup}
        title={tn('leak.popup.reset.title')} // "Leckage zurücksetzen"
        message={tn('leak.popup.reset.text')} // "Möchten Sie diese Leckage zurücksetzen ?"
        confirmAction={() => unrepairLeak()}
        confirmText={tn('leak.popup.reset.buttonYes')} // "Jetzt zurücksetzen"
        abortAction={() => setShowUnRepairPopup(false)}
        abortText={tn('leak.popup.reset.buttonNo')} // "Nicht zurücksetzen"
      />
    </LoadingBox>
  );
}

export default Leak;
