import PropTypes from 'prop-types';
import React from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { Box } from '@looxr/components';
import NumberSvg from './NumberSvg';

const StyledChartHolder = styled(Box)`
  svg {
    overflow: visible;
  }
`;

function LeakCostChart({ config, data, offset }) {
  const barLabel = (labelData, rowConfig) => {
    return (
      <NumberSvg
        x={labelData.width + rowConfig.labelOffsetX}
        y={labelData.y + labelData.height / 2 + 5}
        fill="#051014"
        textAnchor="middle"
        number={labelData.value}
        digits={rowConfig.labelDigits}
        round={rowConfig.labelRound}
        suffix={rowConfig.labelSuffix ? rowConfig.labelSuffix : ''}
        fontSize={14}
        showZero={true}
      />
    );
  };

  return (
    <StyledChartHolder>
      <ResponsiveContainer width="99%" height={150}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{ right: offset.right ? offset.right : 50, left: offset.left ? offset.left : 20 }}
        >
          <XAxis hide axisLine={false} type="number" />
          <YAxis dataKey="name1" type="category" axisLine={false} tickLine={false} />
          {config.map((rowConfig) => (
            <Bar
              label={(labelData) => barLabel(labelData, rowConfig)}
              key={rowConfig.key}
              dataKey={rowConfig.key}
              fill={rowConfig.color}
              minPointSize={2}
              maxBarSize={40}
            >
              <LabelList
                dataKey={rowConfig.key.toLowerCase().includes('repaired') ? 'nameRepaired' : 'name'}
                position="left"
                fontSize={14}
                fill="#606060"
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </StyledChartHolder>
  );
}

LeakCostChart.propTypes = {
  offset: PropTypes.any,
  data: PropTypes.array,
  config: PropTypes.array
};

LeakCostChart.defaultProps = {
  offset: {},
  data: [],
  config: []
};

export default LeakCostChart;

/*
          tick={({ y, payload }) => (
            <text x={0} y={y + 3} textAnchor="start" fill="#606060" fontSize={11}>
              {payload.value}
            </text>
          )}

<LabelList dataKey="amount" position="right" style={{ fontSize: 14 }} />

dy={-6}

            <NumberSvg
              number={payload.value}
              digits={2}
              round={true}
              x={0}
              y={y + 3}
              textAnchor="start"
              fill="#606060"
              fontSize={11}
            />
*/
