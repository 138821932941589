import { Translate } from '@looxr/utils';

export const CUSTOMER_TYPES = {
  looxr: 'TYPE_LOOXR',
  oem: 'TYPE_OEM',
  service: 'TYPE_SERVICE',
  customer: 'TYPE_CUSTOMER'
};

export const COUNTRY_TYPES = {
  ad: 'COUNTRY_AD', // Translate.tn('general.formOptions.country.ad'),
  ae: 'COUNTRY_AE', // Translate.tn('general.formOptions.country.ae'),
  ag: 'COUNTRY_AG', // Translate.tn('general.formOptions.country.ag'),
  ai: 'COUNTRY_AI', // Translate.tn('general.formOptions.country.ai'),
  al: 'COUNTRY_AL', // Translate.tn('general.formOptions.country.al'),
  am: 'COUNTRY_AM', // Translate.tn('general.formOptions.country.am'),
  an: 'COUNTRY_AN', // Translate.tn('general.formOptions.country.an'),
  ao: 'COUNTRY_AO', // Translate.tn('general.formOptions.country.ao'),
  ar: 'COUNTRY_AR', // Translate.tn('general.formOptions.country.ar'),
  at: 'COUNTRY_AT', // Translate.tn('general.formOptions.country.at'),
  au: 'COUNTRY_AU', // Translate.tn('general.formOptions.country.au'),
  aw: 'COUNTRY_AW', // Translate.tn('general.formOptions.country.aw'),
  az: 'COUNTRY_AZ', // Translate.tn('general.formOptions.country.az'),
  ba: 'COUNTRY_BA', // Translate.tn('general.formOptions.country.ba'),
  bb: 'COUNTRY_BB', // Translate.tn('general.formOptions.country.bb'),
  bd: 'COUNTRY_BD', // Translate.tn('general.formOptions.country.bd'),
  be: 'COUNTRY_BE', // Translate.tn('general.formOptions.country.be'),
  bf: 'COUNTRY_BF', // Translate.tn('general.formOptions.country.bf'),
  bg: 'COUNTRY_BG', // Translate.tn('general.formOptions.country.bg'),
  bh: 'COUNTRY_BH', // Translate.tn('general.formOptions.country.bh'),
  bi: 'COUNTRY_BI', // Translate.tn('general.formOptions.country.bi'),
  bj: 'COUNTRY_BJ', // Translate.tn('general.formOptions.country.bj'),
  bm: 'COUNTRY_BM', // Translate.tn('general.formOptions.country.bm'),
  bn: 'COUNTRY_BN', // Translate.tn('general.formOptions.country.bn'),
  bo: 'COUNTRY_BO', // Translate.tn('general.formOptions.country.bo'),
  br: 'COUNTRY_BR', // Translate.tn('general.formOptions.country.br'),
  bs: 'COUNTRY_BS', // Translate.tn('general.formOptions.country.bs'),
  bt: 'COUNTRY_BT', // Translate.tn('general.formOptions.country.bt'),
  bw: 'COUNTRY_BW', // Translate.tn('general.formOptions.country.bw'),
  by: 'COUNTRY_BY', // Translate.tn('general.formOptions.country.by'),
  bz: 'COUNTRY_BZ', // Translate.tn('general.formOptions.country.bz'),
  ca: 'COUNTRY_CA', // Translate.tn('general.formOptions.country.ca'),
  cf: 'COUNTRY_CF', // Translate.tn('general.formOptions.country.cf'),
  cg: 'COUNTRY_CG', // Translate.tn('general.formOptions.country.cg'),
  ch: 'COUNTRY_CH', // Translate.tn('general.formOptions.country.ch'),
  ck: 'COUNTRY_CK', // Translate.tn('general.formOptions.country.ck'),
  cl: 'COUNTRY_CL', // Translate.tn('general.formOptions.country.cl'),
  cm: 'COUNTRY_CM', // Translate.tn('general.formOptions.country.cm'),
  cn: 'COUNTRY_CN', // Translate.tn('general.formOptions.country.cn'),
  co: 'COUNTRY_CO', // Translate.tn('general.formOptions.country.co'),
  cr: 'COUNTRY_CR', // Translate.tn('general.formOptions.country.cr'),
  cv: 'COUNTRY_CV', // Translate.tn('general.formOptions.country.cv'),
  cy: 'COUNTRY_CY', // Translate.tn('general.formOptions.country.cy'),
  cz: 'COUNTRY_CZ', // Translate.tn('general.formOptions.country.cz'),
  de: 'COUNTRY_DE', // Translate.tn('general.formOptions.country.de'),
  dk: 'COUNTRY_DK', // Translate.tn('general.formOptions.country.dk'),
  do: 'COUNTRY_DO', // Translate.tn('general.formOptions.country.do'),
  dz: 'COUNTRY_DZ', // Translate.tn('general.formOptions.country.dz'),
  ec: 'COUNTRY_EC', // Translate.tn('general.formOptions.country.ec'),
  ee: 'COUNTRY_EE', // Translate.tn('general.formOptions.country.ee'),
  eec: 'COUNTRY_EEC', // Translate.tn('general.formOptions.country.eec'),
  eg: 'COUNTRY_EG', // Translate.tn('general.formOptions.country.eg'),
  es: 'COUNTRY_ES', // Translate.tn('general.formOptions.country.es'),
  et: 'COUNTRY_ET', // Translate.tn('general.formOptions.country.et'),
  eu: 'COUNTRY_EU', // Translate.tn('general.formOptions.country.eu'),
  fi: 'COUNTRY_FI', // Translate.tn('general.formOptions.country.fi'),
  fj: 'COUNTRY_FJ', // Translate.tn('general.formOptions.country.fj'),
  fr: 'COUNTRY_FR', // Translate.tn('general.formOptions.country.fr'),
  ga: 'COUNTRY_GA', // Translate.tn('general.formOptions.country.ga'),
  gb: 'COUNTRY_GB', // Translate.tn('general.formOptions.country.gb'),
  ge: 'COUNTRY_GE', // Translate.tn('general.formOptions.country.ge'),
  gf: 'COUNTRY_GF', // Translate.tn('general.formOptions.country.gf'),
  gh: 'COUNTRY_GH', // Translate.tn('general.formOptions.country.gh'),
  gi: 'COUNTRY_GI', // Translate.tn('general.formOptions.country.gi'),
  gm: 'COUNTRY_GM', // Translate.tn('general.formOptions.country.gm'),
  gn: 'COUNTRY_GN', // Translate.tn('general.formOptions.country.gn'),
  gp: 'COUNTRY_GP', // Translate.tn('general.formOptions.country.gp'),
  gq: 'COUNTRY_GQ', // Translate.tn('general.formOptions.country.gq'),
  gr: 'COUNTRY_GR', // Translate.tn('general.formOptions.country.gr'),
  gt: 'COUNTRY_GT', // Translate.tn('general.formOptions.country.gt'),
  gu: 'COUNTRY_GU', // Translate.tn('general.formOptions.country.gu'),
  gw: 'COUNTRY_GW', // Translate.tn('general.formOptions.country.gw'),
  gy: 'COUNTRY_GY', // Translate.tn('general.formOptions.country.gy'),
  hk: 'COUNTRY_HK', // Translate.tn('general.formOptions.country.hk'),
  hn: 'COUNTRY_HN', // Translate.tn('general.formOptions.country.hn'),
  hr: 'COUNTRY_HR', // Translate.tn('general.formOptions.country.hr'),
  ht: 'COUNTRY_HT', // Translate.tn('general.formOptions.country.ht'),
  hu: 'COUNTRY_HU', // Translate.tn('general.formOptions.country.hu'),
  id: 'COUNTRY_ID', // Translate.tn('general.formOptions.country.id'),
  ie: 'COUNTRY_IE', // Translate.tn('general.formOptions.country.ie'),
  il: 'COUNTRY_IL', // Translate.tn('general.formOptions.country.il'),
  in: 'COUNTRY_IN', // Translate.tn('general.formOptions.country.in'),
  iq: 'COUNTRY_IQ', // Translate.tn('general.formOptions.country.iq'),
  ir: 'COUNTRY_IR', // Translate.tn('general.formOptions.country.ir'),
  is: 'COUNTRY_IS', // Translate.tn('general.formOptions.country.is'),
  it: 'COUNTRY_IT', // Translate.tn('general.formOptions.country.it'),
  jo: 'COUNTRY_JO', // Translate.tn('general.formOptions.country.jo'),
  jp: 'COUNTRY_JP', // Translate.tn('general.formOptions.country.jp'),
  ke: 'COUNTRY_KE', // Translate.tn('general.formOptions.country.ke'),
  kg: 'COUNTRY_KG', // Translate.tn('general.formOptions.country.kg'),
  kh: 'COUNTRY_KH', // Translate.tn('general.formOptions.country.kh'),
  km: 'COUNTRY_KM', // Translate.tn('general.formOptions.country.km'),
  kr: 'COUNTRY_KR', // Translate.tn('general.formOptions.country.kr'),
  kw: 'COUNTRY_KW', // Translate.tn('general.formOptions.country.kw'),
  ky: 'COUNTRY_KY', // Translate.tn('general.formOptions.country.ky'),
  kz: 'COUNTRY_KZ', // Translate.tn('general.formOptions.country.kz'),
  la: 'COUNTRY_LA', // Translate.tn('general.formOptions.country.la'),
  lb: 'COUNTRY_LB', // Translate.tn('general.formOptions.country.lb'),
  lc: 'COUNTRY_LC', // Translate.tn('general.formOptions.country.lc'),
  li: 'COUNTRY_LI', // Translate.tn('general.formOptions.country.li'),
  lk: 'COUNTRY_LK', // Translate.tn('general.formOptions.country.lk'),
  lr: 'COUNTRY_LR', // Translate.tn('general.formOptions.country.lr'),
  ls: 'COUNTRY_LS', // Translate.tn('general.formOptions.country.ls'),
  lt: 'COUNTRY_LT', // Translate.tn('general.formOptions.country.lt'),
  lu: 'COUNTRY_LU', // Translate.tn('general.formOptions.country.lu'),
  lv: 'COUNTRY_LV', // Translate.tn('general.formOptions.country.lv'),
  ma: 'COUNTRY_MA', // Translate.tn('general.formOptions.country.ma'),
  mc: 'COUNTRY_MC', // Translate.tn('general.formOptions.country.mc'),
  md: 'COUNTRY_MD', // Translate.tn('general.formOptions.country.md'),
  me: 'COUNTRY_ME', // Translate.tn('general.formOptions.country.me'),
  mg: 'COUNTRY_MG', // Translate.tn('general.formOptions.country.mg'),
  mk: 'COUNTRY_MK', // Translate.tn('general.formOptions.country.mk'),
  ml: 'COUNTRY_ML', // Translate.tn('general.formOptions.country.ml'),
  mm: 'COUNTRY_MM', // Translate.tn('general.formOptions.country.mm'),
  mn: 'COUNTRY_MN', // Translate.tn('general.formOptions.country.mn'),
  mo: 'COUNTRY_MO', // Translate.tn('general.formOptions.country.mo'),
  mq: 'COUNTRY_MQ', // Translate.tn('general.formOptions.country.mq'),
  mr: 'COUNTRY_MR', // Translate.tn('general.formOptions.country.mr'),
  ms: 'COUNTRY_MS', // Translate.tn('general.formOptions.country.ms'),
  mt: 'COUNTRY_MT', // Translate.tn('general.formOptions.country.mt'),
  mu: 'COUNTRY_MU', // Translate.tn('general.formOptions.country.mu'),
  mv: 'COUNTRY_MV', // Translate.tn('general.formOptions.country.mv'),
  mw: 'COUNTRY_MW', // Translate.tn('general.formOptions.country.mw'),
  mx: 'COUNTRY_MX', // Translate.tn('general.formOptions.country.mx'),
  my: 'COUNTRY_MY', // Translate.tn('general.formOptions.country.my'),
  mz: 'COUNTRY_MZ', // Translate.tn('general.formOptions.country.mz'),
  na: 'COUNTRY_NA', // Translate.tn('general.formOptions.country.na'),
  nc: 'COUNTRY_NC', // Translate.tn('general.formOptions.country.nc'),
  ne: 'COUNTRY_NE', // Translate.tn('general.formOptions.country.ne'),
  ng: 'COUNTRY_NG', // Translate.tn('general.formOptions.country.ng'),
  ni: 'COUNTRY_NI', // Translate.tn('general.formOptions.country.ni'),
  nl: 'COUNTRY_NL', // Translate.tn('general.formOptions.country.nl'),
  no: 'COUNTRY_NO', // Translate.tn('general.formOptions.country.no'),
  np: 'COUNTRY_NP', // Translate.tn('general.formOptions.country.np'),
  nz: 'COUNTRY_NZ', // Translate.tn('general.formOptions.country.nz'),
  om: 'COUNTRY_OM', // Translate.tn('general.formOptions.country.om'),
  pa: 'COUNTRY_PA', // Translate.tn('general.formOptions.country.pa'),
  pe: 'COUNTRY_PE', // Translate.tn('general.formOptions.country.pe'),
  pg: 'COUNTRY_PG', // Translate.tn('general.formOptions.country.pg'),
  ph: 'COUNTRY_PH', // Translate.tn('general.formOptions.country.ph'),
  pk: 'COUNTRY_PK', // Translate.tn('general.formOptions.country.pk'),
  pl: 'COUNTRY_PL', // Translate.tn('general.formOptions.country.pl'),
  pr: 'COUNTRY_PR', // Translate.tn('general.formOptions.country.pr'),
  pt: 'COUNTRY_PT', // Translate.tn('general.formOptions.country.pt'),
  py: 'COUNTRY_PY', // Translate.tn('general.formOptions.country.py'),
  qa: 'COUNTRY_QA', // Translate.tn('general.formOptions.country.qa'),
  ro: 'COUNTRY_RO', // Translate.tn('general.formOptions.country.ro'),
  rs: 'COUNTRY_RS', // Translate.tn('general.formOptions.country.rs'),
  ru: 'COUNTRY_RU', // Translate.tn('general.formOptions.country.ru'),
  rw: 'COUNTRY_RW', // Translate.tn('general.formOptions.country.rw'),
  sa: 'COUNTRY_SA', // Translate.tn('general.formOptions.country.sa'),
  sb: 'COUNTRY_SB', // Translate.tn('general.formOptions.country.sb'),
  sc: 'COUNTRY_SC', // Translate.tn('general.formOptions.country.sc'),
  sd: 'COUNTRY_SD', // Translate.tn('general.formOptions.country.sd'),
  se: 'COUNTRY_SE', // Translate.tn('general.formOptions.country.se'),
  sg: 'COUNTRY_SG', // Translate.tn('general.formOptions.country.sg'),
  si: 'COUNTRY_SI', // Translate.tn('general.formOptions.country.si'),
  sk: 'COUNTRY_SK', // Translate.tn('general.formOptions.country.sk'),
  sl: 'COUNTRY_SL', // Translate.tn('general.formOptions.country.sl'),
  sm: 'COUNTRY_SM', // Translate.tn('general.formOptions.country.sm'),
  sn: 'COUNTRY_SN', // Translate.tn('general.formOptions.country.sn'),
  so: 'COUNTRY_SO', // Translate.tn('general.formOptions.country.so'),
  sr: 'COUNTRY_SR', // Translate.tn('general.formOptions.country.sr'),
  st: 'COUNTRY_ST', // Translate.tn('general.formOptions.country.st'),
  sv: 'COUNTRY_SV', // Translate.tn('general.formOptions.country.sv'),
  sy: 'COUNTRY_SY', // Translate.tn('general.formOptions.country.sy'),
  sz: 'COUNTRY_SZ', // Translate.tn('general.formOptions.country.sz'),
  tc: 'COUNTRY_TC', // Translate.tn('general.formOptions.country.tc'),
  td: 'COUNTRY_TD', // Translate.tn('general.formOptions.country.td'),
  tg: 'COUNTRY_TG', // Translate.tn('general.formOptions.country.tg'),
  th: 'COUNTRY_TH', // Translate.tn('general.formOptions.country.th'),
  tj: 'COUNTRY_TJ', // Translate.tn('general.formOptions.country.tj'),
  tm: 'COUNTRY_TM', // Translate.tn('general.formOptions.country.tm'),
  tn: 'COUNTRY_TN', // Translate.tn('general.formOptions.country.tn'),
  to: 'COUNTRY_TO', // Translate.tn('general.formOptions.country.to'),
  tr: 'COUNTRY_TR', // Translate.tn('general.formOptions.country.tr'),
  tt: 'COUNTRY_TT', // Translate.tn('general.formOptions.country.tt'),
  tw: 'COUNTRY_TW', // Translate.tn('general.formOptions.country.tw'),
  tz: 'COUNTRY_TZ', // Translate.tn('general.formOptions.country.tz'),
  ua: 'COUNTRY_UA', // Translate.tn('general.formOptions.country.ua'),
  ug: 'COUNTRY_UG', // Translate.tn('general.formOptions.country.ug'),
  us: 'COUNTRY_US', // Translate.tn('general.formOptions.country.us'),
  uy: 'COUNTRY_UY', // Translate.tn('general.formOptions.country.uy'),
  uz: 'COUNTRY_UZ', // Translate.tn('general.formOptions.country.uz'),
  va: 'COUNTRY_VA', // Translate.tn('general.formOptions.country.va'),
  vc: 'COUNTRY_VC', // Translate.tn('general.formOptions.country.vc'),
  ve: 'COUNTRY_VE', // Translate.tn('general.formOptions.country.ve'),
  vn: 'COUNTRY_VN', // Translate.tn('general.formOptions.country.vn'),
  vu: 'COUNTRY_VU', // Translate.tn('general.formOptions.country.vu'),
  ws: 'COUNTRY_WS', // Translate.tn('general.formOptions.country.ws'),
  ye: 'COUNTRY_YE', // Translate.tn('general.formOptions.country.ye'),
  yu: 'COUNTRY_YU', // Translate.tn('general.formOptions.country.yu'),
  za: 'COUNTRY_ZA', // Translate.tn('general.formOptions.country.za'),
  zm: 'COUNTRY_ZM', // Translate.tn('general.formOptions.country.zm'),
  zr: 'COUNTRY_ZR', // Translate.tn('general.formOptions.country.zr'),
  zw: 'COUNTRY_ZW' // Translate.tn('general.formOptions.country.zw'),
};

export const LEAK_USER_ROLES = {
  app: 'ROLE_LEAK_APP',
  portal: 'ROLE_LEAK_PORTAL',
  admin: 'ROLE_LEAK_ADMIN'
};

export const PRESSURE_USER_ROLES = {
  user: 'USER',
  analytics: 'ANALYTICS',
  admin: 'ADMIN',
  techAdmin: 'TECH_ADMIN',
  superAdmin: 'SUPERADMIN'
};

export const MEASURED_VALUE_CALC_METHODS = {
  airBaseWithPressure: 'airBaseWithPressure',
  airBaseWithElectric: 'airBaseWithElectric',
  dbmvWithElectric: 'dbmvWithElectric'
};

export const MEASURED_DEVICE_TYPES = {
  csPressure: {
    id: 'csPressure',
    calcMethod: MEASURED_VALUE_CALC_METHODS.airBaseWithPressure,
    measuredUnit: 'l/min',
    powerFactorInSec: true,
    neededParameter: {
      airPressurePrice: true,
      powerFactor: false,
      emission: false,
      electricPrice: false,
      operatingHours: true
    }
  },
  csElectric: {
    id: 'csElectric',
    calcMethod: MEASURED_VALUE_CALC_METHODS.airBaseWithElectric,
    measuredUnit: 'l/min',
    powerFactorInSec: true,
    neededParameter: {
      airPressurePrice: false,
      powerFactor: true,
      emission: true,
      electricPrice: true,
      operatingHours: true
    }
  },
  sutoS531: {
    id: 'sutoS531',
    calcMethod: MEASURED_VALUE_CALC_METHODS.airBaseWithPressure,
    measuredUnit: 'l/min',
    powerFactorInSec: true,
    neededParameter: {
      airPressurePrice: true,
      powerFactor: false,
      emission: false,
      electricPrice: false,
      operatingHours: true
    }
  },
  sonotecSonaphone: {
    id: 'sonotecSonaphone',
    calcMethod: MEASURED_VALUE_CALC_METHODS.airBaseWithElectric,
    measuredUnit: 'l/min',
    powerFactorInSec: true,
    neededParameter: {
      airPressurePrice: false,
      powerFactor: true,
      emission: true,
      electricPrice: true,
      operatingHours: true
    }
  },
  sdt200: {
    id: 'sdt200',
    calcMethod: MEASURED_VALUE_CALC_METHODS.dbmvWithElectric,
    measuredUnit: 'dBµV',
    powerFactorInSec: false,
    neededParameter: {
      airPressurePrice: false,
      powerFactor: true,
      emission: true,
      electricPrice: true,
      operatingHours: true
    }
  },
  sdt270: {
    id: 'sdt270',
    calcMethod: MEASURED_VALUE_CALC_METHODS.dbmvWithElectric,
    measuredUnit: 'dBµV',
    powerFactorInSec: false,
    neededParameter: {
      airPressurePrice: false,
      powerFactor: true,
      emission: true,
      electricPrice: true,
      operatingHours: true
    }
  },
  sdt340: {
    id: 'sdt340',
    calcMethod: MEASURED_VALUE_CALC_METHODS.dbmvWithElectric,
    measuredUnit: 'dBµV',
    powerFactorInSec: false,
    neededParameter: {
      airPressurePrice: false,
      powerFactor: true,
      emission: true,
      electricPrice: true,
      operatingHours: true
    }
  },
  sdtLeakChecker: {
    id: 'sdtLeakChecker',
    calcMethod: MEASURED_VALUE_CALC_METHODS.dbmvWithElectric,
    measuredUnit: 'dBµV',
    powerFactorInSec: false,
    neededParameter: {
      airPressurePrice: false,
      powerFactor: true,
      emission: true,
      electricPrice: true,
      operatingHours: true
    }
  }
};

export const ROLES = {
  looxr: 'LOOXR',
  user: 'USER',
  admin: 'ADMIN'
};

export const getRoleOptions = () => {
  return [
    { label: Translate.tn('general.formOptions.role.admin'), value: ROLES.admin },
    { label: Translate.tn('general.formOptions.role.user'), value: ROLES.user }
  ];
};

export const getCountryOptions = () => {
  const countryTypes = { ...COUNTRY_TYPES };
  const options = [];

  const keys = Object.keys(countryTypes);

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = countryTypes[key];

    options.push({
      label: Translate.tn(`general.formOptions.country.${key}`),
      value
    });
  }

  return options;
};

export const getLeakRoleOptions = () => {
  return [
    { label: Translate.tn('general.formOptions.leakRole.appUser'), value: LEAK_USER_ROLES.app },
    {
      label: Translate.tn('general.formOptions.leakRole.portalUser'),
      value: LEAK_USER_ROLES.portal
    },
    { label: Translate.tn('general.formOptions.leakRole.admin'), value: LEAK_USER_ROLES.admin }
  ];
};

export const getPressureRoleOptions = () => {
  return [
    {
      label: Translate.tn('general.formOptions.pressureRole.user'),
      value: PRESSURE_USER_ROLES.user
    },
    {
      label: Translate.tn('general.formOptions.pressureRole.analytics'),
      value: PRESSURE_USER_ROLES.analytics
    },
    {
      label: Translate.tn('general.formOptions.pressureRole.admin'),
      value: PRESSURE_USER_ROLES.admin
    },
    {
      label: Translate.tn('general.formOptions.pressureRole.techAdmin'),
      value: PRESSURE_USER_ROLES.techAdmin
    }
  ];
};

export const getSalutationOptions = () => {
  return [
    { label: Translate.tn('general.formOptions.salutation.male'), value: 'MALE' },
    { label: Translate.tn('general.formOptions.salutation.female'), value: 'FEMALE' }
  ];
};

export const getLangOptions = () => {
  return [
    { label: Translate.tn('general.formOptions.language.german'), value: 'de' },
    { label: Translate.tn('general.formOptions.language.english'), value: 'en' }
  ];
};

export const getCustomerTypeOptions = () => {
  return [
    { label: Translate.tn('general.formOptions.customerType.oem'), value: CUSTOMER_TYPES.oem },
    {
      label: Translate.tn('general.formOptions.customerType.service'),
      value: CUSTOMER_TYPES.service
    },
    {
      label: Translate.tn('general.formOptions.customerType.customer'),
      value: CUSTOMER_TYPES.customer
    }
  ];
};

export const getMeasuredDeviceTypeOptions = () => {
  return [
    {
      label: Translate.tn('admin.formOptions.measuredDeviceType.csPressure'),
      value: MEASURED_DEVICE_TYPES.csPressure.id
    },
    {
      label: Translate.tn('admin.formOptions.measuredDeviceType.csElectric'),
      value: MEASURED_DEVICE_TYPES.csElectric.id
    },
    {
      label: Translate.tn('admin.formOptions.measuredDeviceType.sutoS531'),
      value: MEASURED_DEVICE_TYPES.sutoS531.id
    },
    {
      label: Translate.tn('admin.formOptions.measuredDeviceType.sonotecSonaphone'),
      value: MEASURED_DEVICE_TYPES.sonotecSonaphone.id
    },
    {
      label: Translate.tn('admin.formOptions.measuredDeviceType.sdt200'),
      value: MEASURED_DEVICE_TYPES.sdt200.id
    },
    {
      label: Translate.tn('admin.formOptions.measuredDeviceType.sdt270'),
      value: MEASURED_DEVICE_TYPES.sdt270.id
    },
    {
      label: Translate.tn('admin.formOptions.measuredDeviceType.sdt340'),
      value: MEASURED_DEVICE_TYPES.sdt340.id
    },
    {
      label: Translate.tn('admin.formOptions.measuredDeviceType.sdtLeakChecker'),
      value: MEASURED_DEVICE_TYPES.sdtLeakChecker.id
    }
  ];
};
