const StorageService = {
  has: async (key) => {
    const local = await StorageService.get(key);
    return local !== false && local !== undefined && local !== null;
  },

  saveObject: async (key, object) => {
    return localStorage.setItem(key, JSON.stringify(object));
  },

  getObject: async (key) => {
    const stored = localStorage.getItem(key);

    if (stored !== null) {
      return JSON.parse(stored);
    }

    return null;
  },

  save: async (key, string) => {
    return localStorage.setItem(key, string);
  },

  get: async (key) => {
    return localStorage.getItem(key);
  },

  remove: async (key) => {
    return localStorage.removeItem(key);
  },

  removeAll: async () => {
    return localStorage.clear();
  }
};

export default StorageService;
