import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';

const StyledMoreMenu = styled(Box)``;

const StyledMoreMenuItem = styled(Box)`
  cursor: pointer;
  span {
    cursor: pointer;
    color: ${(props) => props.theme.colors.grey};
    font-size: 16px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.purple};
    span {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

// @TODO create config to render more menu with static names to reference
function LeakMoreMenu({ data, onItemClick }) {
  const tn = useTranslation();
  if (data.notRepairable === true) {
    return (
      <StyledMoreMenu
        background="white"
        display="flex"
        direction="column"
        justify="left"
        alignItems="left"
        noElevation={false}
      >
        <StyledMoreMenuItem
          paddingX={3}
          paddingY={3}
          onClick={() => onItemClick('unsetnotrepairable')}
        >
          <Text>{tn('leak.page.unrepairablestatus.unset')}</Text>
        </StyledMoreMenuItem>
      </StyledMoreMenu>
    );
  }

  return (
    <StyledMoreMenu
      background="white"
      display="flex"
      direction="column"
      justify="left"
      alignItems="left"
      noElevation={false}
    >
      <StyledMoreMenuItem paddingX={3} paddingY={3} onClick={() => onItemClick('setnotrepairable')}>
        <Text>{tn('leak.page.unrepairablestatus.set')}</Text>
      </StyledMoreMenuItem>
    </StyledMoreMenu>
  );
}

LeakMoreMenu.propTypes = {
  data: PropTypes.any,
  onItemClick: PropTypes.func
};

LeakMoreMenu.defaultProps = {
  data: null,
  onItemClick: () => {}
};

export default LeakMoreMenu;
