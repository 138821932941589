import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Button, Label, Select, TextInput } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { useFormOptions } from '../../hooks';

function QRCodeFilter({ onUpdate }) {
  const tn = useTranslation();
  const formOptions = useFormOptions();
  const [localFilter, setLocalFilter] = useState({});

  const updateFilter = (key, value) => {
    const update = { ...localFilter };
    update[key] = value;

    setLocalFilter(update);
    onUpdate(update);
  };

  const onReset = () => {
    setLocalFilter({});
    onUpdate({});
  };

  return (
    <>
      <Box padding={2} width="25%">
        <Label htmlFor="filter_serial">{tn('general.id')}</Label>
        <TextInput
          id="filter_serial"
          name="filter_serial"
          value={localFilter.serial ? localFilter.serial : ''}
          onChange={(e) => updateFilter('serial', e.currentTarget.value)}
        />
      </Box>

      <Box padding={2} width="25%">
        <Label htmlFor="filter_printed">{tn('leak.page.qrcodes.filterForm.printed')}</Label>
        <Select
          id="filter_printed"
          name="filter_printed"
          placeholder={tn('general.choose')} // "Bitte wählen"
          onChange={(e) => updateFilter('printed', e.value)}
          value={localFilter.printed ? localFilter.printed : ''}
          options={formOptions.qrPrinted}
          size="sm"
        />
      </Box>

      <Box padding={2} width="25%">
        <Label htmlFor="filter_used">{tn('leak.page.qrcodes.filterForm.used')}</Label>
        <Select
          id="filter_used"
          name="filter_used"
          placeholder={tn('general.choose')} // "Bitte wählen"
          onChange={(e) => updateFilter('used', e.value)}
          value={localFilter.used ? localFilter.used : ''}
          options={formOptions.qrUsed}
          size="sm"
        />
      </Box>

      <Box padding={2} width="25%" marginTop={7}>
        <Box display="flex" justify="center">
          <Button
            type="button"
            inline
            width={150}
            text={tn('general.reset')} // "Zurücksetzen"
            background="purple"
            onClick={onReset}
          />
        </Box>
      </Box>
    </>
  );
}

QRCodeFilter.propTypes = {
  onUpdate: PropTypes.func
};

QRCodeFilter.defaultProps = {
  onUpdate: () => {}
};

export default QRCodeFilter;
