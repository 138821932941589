import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, LoadingOverlay } from '@looxr/components';
import { useLeakFilter } from '../../hooks';
import { AppStateService, FirebaseService } from '../../services';

function OptIn() {
  const { id } = useParams();
  const history = useHistory();
  const { resetFilter } = useLeakFilter();

  useEffect(() => {
    const load = async () => {
      try {
        const customerDoc = await FirebaseService.getCustomer(id);

        if (customerDoc && customerDoc.exists) {
          // reset leak filter that may be configured for another customer
          resetFilter();

          const customer = {
            id: customerDoc.id,
            ref: customerDoc.ref,
            ...customerDoc.data()
          };

          // set selected customer as active one
          AppStateService.setActiveCustomer(customer);

          // redirect to dashboard
          history.push('/');
        }
      } catch (e) {
        // @TODO show error
        console.log('error during opt in => ', e);
      }
    };

    load();
  }, [id]);

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      alignItems="center"
      justify="center"
      position="absolute"
      left="0"
      top="0"
    >
      <LoadingOverlay isVisible={true} translucent={false} fullOverlay={true} />
    </Box>
  );
}

export default OptIn;
