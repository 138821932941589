import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { faQrcode as QrCodeIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Modal, Text } from '@looxr/components';
import { BaseTheme } from '@looxr/theme';

const StyledHolder = styled(Box)`
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  vertical-align: 'middle';
  border: 1px solid rgba(29, 29, 27, 0.12);
`;

function QrIcon({ data }) {
  const [showQrImage, setShowQrImage] = useState(false);

  if (!data.dataUrl) {
    return null;
  }
  return (
    <>
      <StyledHolder onClick={() => setShowQrImage(true)}>
        <Text size="sm" align="center">
          <FontAwesomeIcon icon={QrCodeIcon} color={BaseTheme.colors.purple} size="lg" />
        </Text>
      </StyledHolder>

      <Modal
        size={350}
        show={showQrImage}
        onClose={() => setShowQrImage(false)}
        closeOnBackdrop={true}
        closeOnEsc={true}
        showModalCloseIcon={true}
      >
        <Box display="flex" alignItems="center" justify="center" padding={4}>
          <StyledImage src={data.dataUrl} alt="img" />
        </Box>
      </Modal>
    </>
  );
}

QrIcon.propTypes = {
  data: PropTypes.object.isRequired
};

export default QrIcon;
