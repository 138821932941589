const DymoService = {
  doneInit: false,
  canPrint: false,
  printer: null,

  init: () => {
    return new Promise((resolve) => {
      try {
        if (!DymoService.doneInit) {
          window.dymo.label.framework.init(() => {
            const result = window.dymo.label.framework.checkEnvironment();

            if (result.isBrowserSupported && result.isFrameworkInstalled) {
              const printers = window.dymo.label.framework.getPrinters();

              if (printers.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                DymoService.printer = printers[0];
                DymoService.canPrint = true;
              }
            }

            DymoService.doneInit = true;
            resolve();
          });
        } else {
          resolve();
        }
      } catch (e) {
        resolve();
      }
    });
  },

  /**
   * print a label using the dymo printer
   * @return {Promise}
   */
  print: async (xml) => {
    return new Promise((resolve) => {
      try {
        const label = window.dymo.label.framework.openLabelXml(xml);
        label.print(DymoService.printer.name);

        setTimeout(() => {
          resolve(true);
        }, 500);
      } catch (e) {
        console.log('Failed to print label');
        console.log(e);
        resolve(false);
      }
    });
  }
};

export default DymoService;
