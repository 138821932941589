import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { faLinkedin as LinkedInIcon, faXing as XingIcon } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Footer, FooterLink } from '@looxr/components';
import { useLanguage, useTranslation } from '@looxr/utils';
import { DownloadContainer, Header } from './components';
import {
  CustomerSelect,
  Dashboard,
  Leak,
  LeakEdit,
  Location,
  Logout,
  NoMatch,
  OptIn,
  Profile,
  QRCodes
} from './pages';
import { AppStateService } from './services';

export default function Routes() {
  const language = useLanguage();
  const tn = useTranslation();

  const renderWhenActiveCustomer = (props, Component) => {
    const active = AppStateService.activeCustomer;

    if (active !== null) {
      return <Component currentLocale={language} {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/kunden-auswahl'
        }}
      />
    );
  };

  return (
    <Router>
      <Header currentLocale={language} />
      <Switch>
        <Route path="/" exact render={(props) => renderWhenActiveCustomer(props, Dashboard)} />
        <Route
          path="/uebersicht"
          exact
          render={(props) => renderWhenActiveCustomer(props, Dashboard)}
        />
        <Route
          path="/standort-daten"
          exact
          render={(props) => renderWhenActiveCustomer(props, Location)}
        />
        <Route
          path="/qrcodes-verwalten"
          exact
          render={(props) => renderWhenActiveCustomer(props, QRCodes)}
        />
        <Route
          path="/leckage/info/:id"
          exact
          render={(props) => renderWhenActiveCustomer(props, Leak)}
        />

        {(AppStateService.isLOOXR() || AppStateService.isLeakAdmin()) && (
          <Route
            path="/leckage/edit/:id"
            exact
            render={(props) => renderWhenActiveCustomer(props, LeakEdit)}
          />
        )}

        <Route
          path="/profile"
          exact
          render={(props) => <Profile currentLocale={language} {...props} />}
        />

        <Route
          path="/optin/:id"
          exact
          render={(props) => <OptIn currentLocale={language} {...props} />}
        />

        <Route
          path="/kunden-auswahl"
          exact
          render={(props) => <CustomerSelect currentLocale={language} {...props} />}
        />

        <Route
          path="/logout"
          exact
          render={(props) => <Logout currentLocale={language} {...props} />}
        />

        <Route path="*">
          <NoMatch currentLocale={language} />
        </Route>
      </Switch>
      <Footer sticky>
        <Box display="flex" alignItems="center" justify="flex-start">
          <FooterLink linkTo="https://www.looxr.de/impressum">
            {tn('general.footer.imprint')}
            {/* Impressum */}
          </FooterLink>
          <FooterLink linkTo="https://www.looxr.de/j/privacy">
            {tn('general.footer.privacy')}
            {/* Datenschutz */}
          </FooterLink>
          <FooterLink linkTo="https://www.looxr.de/agb/">
            {tn('general.footer.agb')}
            {/* AGB */}
          </FooterLink>
        </Box>
        <Box display="flex" alignItems="center" justify="flex-end">
          <Box marginRight={5}>
            <DownloadContainer />
          </Box>

          <FooterLink linkTo="https://www.linkedin.com/company/looxr">
            <FontAwesomeIcon icon={LinkedInIcon} size="lg" />
          </FooterLink>
          <FooterLink linkTo="https://www.xing.com/pages/looxrgmbh">
            <FontAwesomeIcon icon={XingIcon} size="lg" />
          </FooterLink>
        </Box>
      </Footer>
    </Router>
  );
}
