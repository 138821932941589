import { Collections } from '@looxr/constants';
import { AppStateService, FirebaseService } from '../services';

function useLoadLeaks() {
  const getLeaks = async () => {
    try {
      const currentCustomer = AppStateService.activeCustomer;
      const snapshot = await FirebaseService.getLeaks(currentCustomer);
      const data = [];
      snapshot.forEach((doc) => {
        if (doc.exists) {
          const docData = doc.data();
          data.push({
            id: doc.id,
            ref: doc.ref,
            ...docData
          });
        }
      });

      return data;
    } catch (error) {
      console.log('Error getting customer data');
      console.log(error);
      return [];
    }
  };

  const getLeakByID = async (id) => {
    try {
      const doc = await FirebaseService.ref.firestore().collection(Collections.leak).doc(id).get();

      return doc;
    } catch (error) {
      console.log('Error getting leak data');
      console.log(error);
      return [];
    }
  };

  const getLeakByIDQuery = (id) => {
    return FirebaseService.ref.firestore().collection(Collections.leak).doc(id);
  };

  return {
    getLeaks,
    getLeakByID,
    getLeakByIDQuery
  };
}

export default useLoadLeaks;
