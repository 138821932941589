import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, Box, Button, TextInput } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import useLoadComponents from '../hooks/useLoadComponents';
import ComponentTable from './ComponentTable';

function ComponentAdd({ components, onDelete, onAdd }) {
  const tn = useTranslation();

  const { findComponentByArticleNr } = useLoadComponents();
  const [articleNumber, setArticleNumber] = useState('');
  const [componentNotFound, setComponentNotFound] = useState(false);

  const searchAndAdd = async () => {
    const componentDocs = await findComponentByArticleNr(articleNumber);

    if (componentDocs && componentDocs.length > 0) {
      const component = componentDocs[0];
      onAdd(component);
      setArticleNumber('');
    } else {
      setComponentNotFound(true);
    }
  };

  return (
    <Box width="100%">
      <Box width="100%" display="flex">
        <Box width="20%">
          <TextInput
            id="location"
            name="location"
            placeholder={tn('general.articleNr')} // "Artikel-Nr."
            value={articleNumber}
            onChange={(e) => setArticleNumber(e.currentTarget.value)}
          />
        </Box>
        <Box marginLeft={4}>
          <Button
            inline
            text={tn('general.add')} // "Hinzufügen"
            background="blue"
            onClick={() => searchAndAdd()}
          />
        </Box>
      </Box>
      {components.length > 0 && (
        <Box display="flex" width="100%" marginTop={5}>
          <ComponentTable components={components} onDelete={onDelete} />
        </Box>
      )}

      <Alert
        show={componentNotFound}
        size={400}
        title={tn('leak.alert.noComponentFound.title')} // "Fehler"
        message={tn('leak.alert.noComponentFound.text')} // "Keine Komponente mit dieser Artikel-Nr. gefunden"
        btnColor="red"
        btnText={tn('leak.alert.noComponentFound.button')} // "OK"
        btnAction={() => setComponentNotFound(false)}
      />
    </Box>
  );
}

ComponentAdd.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  components: PropTypes.array
};

ComponentAdd.defaultProps = {
  onAdd: '',
  onDelete: () => {},
  components: []
};

export default ComponentAdd;
