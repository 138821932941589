import {
  filterBooleanEquals,
  filterContains,
  filterEqualsDate,
  filterIsInDateRange,
  filterStartsWith
} from '@looxr/utils';

function useFilterLeaks() {
  const filterLeaks = (filter, leaks) => {
    let filtered = [];

    const {
      serial,
      location,
      valence,
      machine,
      repaired,
      check,
      hasComponents,
      repairedAt,
      createdAt
    } = filter;

    if (leaks !== null && leaks !== undefined && leaks.length > 0) {
      filtered = leaks;

      if (serial) {
        filtered = filtered.filter((leak) => filterStartsWith(serial, leak.qrCodeSerial));
      }

      if (location) {
        filtered = filtered.filter((leak) => filterContains(location, leak.location));
      }

      if (machine) {
        filtered = filtered.filter((leak) => filterStartsWith(machine, leak.machine));
      }

      if (valence !== 'all') {
        filtered = filtered.filter((leak) => filterContains(valence, leak.valence));
      }

      if (check !== 'all') {
        filtered = filtered.filter((leak) => filterBooleanEquals(check, leak.needsCustomerCheck));
      }

      if (repaired !== 'all') {
        if (repaired === 'repaired') {
          filtered = filtered.filter((leak) => filterBooleanEquals(true, leak.repaired));
        }

        if (repaired === 'notrepaired') {
          filtered = filtered.filter((leak) => filterBooleanEquals(false, leak.repaired));
          // use !true here as check since it could be undefined
          filtered = filtered.filter((leak) => !filterBooleanEquals(true, leak.notRepairable));
        }

        if (repaired === 'notrepairable') {
          filtered = filtered.filter((leak) => filterBooleanEquals(true, leak.notRepairable));
        }
      }

      if (createdAt) {
        if (createdAt.from !== null && createdAt.to === null) {
          filtered = filtered.filter((leak) => filterEqualsDate(createdAt.from, leak.createdAt));
        } else if (createdAt.from !== null && createdAt.to !== null) {
          filtered = filtered.filter((leak) => filterIsInDateRange(createdAt, leak.createdAt));
        }
      }

      if (repairedAt) {
        if (repairedAt.from !== null && repairedAt.to === null) {
          filtered = filtered.filter((leak) => filterEqualsDate(repairedAt.from, leak.repairedAt));
        } else if (repairedAt.from !== null && repairedAt.to !== null) {
          filtered = filtered.filter((leak) => filterIsInDateRange(repairedAt, leak.repairedAt));
        }
      }

      if (hasComponents !== 'all') {
        if (
          hasComponents &&
          (hasComponents === 1 || hasComponents === true || hasComponents === 'true')
        ) {
          filtered = filtered.filter((leak) => leak.components.ids.length > 0);
        } else {
          filtered = filtered.filter((leak) => leak.components.ids.length === 0);
        }
      }
    }

    return filtered;
  };

  return { filterLeaks };
}

export default useFilterLeaks;
