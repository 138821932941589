import LeakCalcService from './LeakCalcService';

const LeakSummaryService = {
  getSummaryDefaults: () => {
    return {
      count: 0,
      repairedCount: 0,
      airLoss: 0,
      energyConsumption: undefined,
      emission: undefined,
      energyCosts: undefined,
      energyCostsRepaired: undefined
    };
  },

  calcSummaryValues: (leaks, customer) => {
    const summary = LeakSummaryService.getSummaryDefaults();
    let airLossRaw = 0;

    summary.count = leaks.length;

    leaks.forEach((leak) => {
      airLossRaw += LeakCalcService.airLossBase(leak, customer);

      const energyConsumption = LeakCalcService.energyConsumptionPerYear(leak, customer);
      const emission = LeakCalcService.emissionPerYear(leak, customer);
      const energyCosts = LeakCalcService.energyCostsPerYear(leak, customer);
      const energyCostsRepaired = LeakCalcService.energyCostsPerYear(leak, customer);

      if (energyConsumption !== undefined) {
        if (summary.energyConsumption === undefined) {
          summary.energyConsumption = 0;
        }

        summary.energyConsumption += energyConsumption;
      }

      if (emission !== undefined) {
        if (summary.emission === undefined) {
          summary.emission = 0;
        }

        summary.emission += emission;
      }

      if (energyCosts !== undefined) {
        if (summary.energyCosts === undefined) {
          summary.energyCosts = 0;
        }

        summary.energyCosts += energyCosts;
      }

      if (leak.repaired) {
        if (energyCostsRepaired !== undefined) {
          if (summary.energyCostsRepaired === undefined) {
            summary.energyCostsRepaired = 0;
          }

          summary.energyCostsRepaired += energyCostsRepaired;
        }

        summary.repairedCount += 1;
      }
    });

    summary.airLoss = airLossRaw / 1000;

    return summary;
  },

  generateLocationMapGroup: (leaks, customer) => {
    const map = [];

    for (let i = 0; i < leaks.length; i += 1) {
      const leak = leaks[i];
      if (leak && leak.location && leak.location.length > 0) {
        const { location } = leak;
        const compareable = location.toLowerCase().trim();
        const energyCosts = LeakCalcService.energyCostsPerYear(leak, customer);
        const entryIndex = map.findIndex((entry) => entry.compareable === compareable);

        if (entryIndex >= 0) {
          if (energyCosts) {
            map[entryIndex].costs += energyCosts;
          }
        } else {
          const locationUppercasedFirst = location.charAt(0).toUpperCase() + location.slice(1);
          const entry = {};
          entry.location = locationUppercasedFirst;
          entry.compareable = compareable;
          entry.costs = energyCosts !== undefined ? energyCosts : 0;

          map.push(entry);
        }
      }
    }

    return map;
  }
};

export default LeakSummaryService;
