import { MEASURED_DEVICE_TYPES } from '@looxr/constants';
import { VALENCY_TYPES } from '../constants/Form';

const LeakCalcDecibelService = {
  /**
   * Power factor can be entered in sec or min
   * in order to return the correct value we compare the config value of leak and customer to adjust the value if needed
   *
   * @param {*} leak
   * @param {*} customer
   * @returns powerFactor
   */
  getPowerFactorValue: (leak, customer) => {
    let powerFactor;

    const leakDeviceType = leak.measuredDeviceType;
    const customerDeviceType = customer.parameter.measuredDeviceType;

    const leakDeviceConfig = MEASURED_DEVICE_TYPES[leakDeviceType];
    const customerDeviceConfig = MEASURED_DEVICE_TYPES[customerDeviceType];

    if (customer.parameter.powerFactor !== undefined && customer.parameter.powerFactor !== '') {
      powerFactor = parseFloat(customer.parameter.powerFactor);

      // if not set, this will most likely not give the correct value but it safes crashes
      if (leakDeviceConfig !== undefined && customerDeviceConfig !== undefined) {
        if (
          customerDeviceConfig.powerFactorInSec === true &&
          leakDeviceConfig.powerFactorInSec === false
        ) {
          powerFactor *= 60;
        }

        if (
          customerDeviceConfig.powerFactorInSec === false &&
          leakDeviceConfig.powerFactorInSec === true
        ) {
          powerFactor /= 60;
        }
      }
    }

    return powerFactor;
  },

  /**
   * calculate the emission per year
   * @return {float} emissionYear
   */
  emissionPerYear: (leak, customer) => {
    const energyConsumptionYear = LeakCalcDecibelService.energyConsumptionPerYear(leak, customer);
    let emissionYear;

    if (
      energyConsumptionYear !== undefined &&
      customer.parameter.emission !== undefined &&
      customer.parameter.emission !== ''
    ) {
      const emissionFactor = parseFloat(customer.parameter.emission);
      emissionYear = energyConsumptionYear * emissionFactor;
    }

    return emissionYear;
  },

  /**
   * calculate the energy costs per year
   * @return {float} energyCostsYear
   */
  energyCostsPerYear: (leak, customer) => {
    const energyConsumptionYear = LeakCalcDecibelService.energyConsumptionPerYear(leak, customer);
    let energyCostsYear;

    if (
      energyConsumptionYear !== undefined &&
      customer.parameter.electricPrice !== undefined &&
      customer.parameter.electricPrice !== ''
    ) {
      energyCostsYear = energyConsumptionYear * parseFloat(customer.parameter.electricPrice);
    }

    return energyCostsYear;
  },

  /**
   * calculate the energy consumption per year
   * @return {float} energyConsumptionYear
   */
  energyConsumptionPerYear: (leak, customer) => {
    const energyConsumption = LeakCalcDecibelService.energyConsumption(leak, customer);
    let energyConsumptionYear;

    if (energyConsumption) {
      energyConsumptionYear = energyConsumption * parseFloat(customer.parameter.operatingHours);
    }

    return energyConsumptionYear;
  },

  /**
   * calculate the energy consumption
   * @return {float} energyConsumption
   */
  energyConsumption: (leak, customer) => {
    let energyConsumption;
    const powerFactor = LeakCalcDecibelService.getPowerFactorValue(leak, customer);
    if (powerFactor !== undefined) {
      const airLossVolume = LeakCalcDecibelService.airLossVolume(leak, customer);
      energyConsumption = (airLossVolume / 60) * powerFactor;
    }

    return energyConsumption;
  },

  /**
   * calculate the air loss volume
   * @return {float} airLossVolume
   */
  airLossVolume: (leak) => {
    const airLoss = LeakCalcDecibelService.airLossBase(leak);
    const airLossVolume = airLoss / 1000;

    return airLossVolume;
  },

  /**
   * calculate the air loss based on the measured value
   * @return {float} airLoss
   */
  airLossBase: (leak) => {
    let airLoss = 0;
    const measured = parseFloat(leak.measuredValue);
    const factor1 = 0.001;
    const factor2 = 1.0615;
    const pressure = parseFloat(leak.operatingPressure);

    if (measured >= 10) {
      airLoss =
        (leak.correctionFactor / 4) *
        // eslint-disable-next-line no-restricted-properties
        (5 * Math.sqrt(10 ** (measured / 20) / (factor1 * factor2 ** (5 - pressure))));
    }

    return airLoss;
  },

  calculateValence: (leak) => {
    let valence = VALENCY_TYPES.low;
    const { measuredValue } = leak;

    if (measuredValue) {
      if (measuredValue <= 20) {
        valence = VALENCY_TYPES.low;
      } else if (measuredValue > 20 && measuredValue <= 40) {
        valence = VALENCY_TYPES.mid;
      } else {
        valence = VALENCY_TYPES.high;
      }
    }

    return valence;
  }
};

export default LeakCalcDecibelService;
