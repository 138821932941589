import { MEASURED_DEVICE_TYPES } from '@looxr/constants';
import { VALENCY_TYPES } from '../constants/Form';

const LeakCalcLiterMinPressureService = {
  /**
   * Power factor can be entered in sec or min
   * in order to return the correct value we compare the config value of leak and customer to adjust the value if needed
   *
   * @param {*} leak
   * @param {*} customer
   * @returns powerFactor
   */
  getPowerFactorValue: (leak, customer) => {
    let powerFactor;

    const leakDeviceType = leak.measuredDeviceType;
    const customerDeviceType = customer.parameter.measuredDeviceType;

    const leakDeviceConfig = MEASURED_DEVICE_TYPES[leakDeviceType];
    const customerDeviceConfig = MEASURED_DEVICE_TYPES[customerDeviceType];

    if (customer.parameter.powerFactor !== undefined && customer.parameter.powerFactor !== '') {
      powerFactor = parseFloat(customer.parameter.powerFactor);

      // if not set, this will most likely not give the correct value but it safes crashes
      if (leakDeviceConfig !== undefined && customerDeviceConfig !== undefined) {
        if (
          customerDeviceConfig.powerFactorInSec === true &&
          leakDeviceConfig.powerFactorInSec === false
        ) {
          powerFactor *= 60;
        }

        if (
          customerDeviceConfig.powerFactorInSec === false &&
          leakDeviceConfig.powerFactorInSec === true
        ) {
          powerFactor /= 60;
        }
      }
    }

    return powerFactor;
  },

  /**
   * calculate the emission per year
   * @return {float} emissionYear
   */
  emissionPerYear: (leak, customer) => {
    let emissionYear;

    if (customer.parameter.emission !== undefined && customer.parameter.emission !== '') {
      const energyConsumptionYear = LeakCalcLiterMinPressureService.energyConsumptionPerYear(
        leak,
        customer
      );

      if (energyConsumptionYear) {
        const emissionFactor = parseFloat(customer.parameter.emission);
        emissionYear = energyConsumptionYear * emissionFactor;
      }
    }

    return emissionYear;
  },

  /**
   * calculate the energy costs per year
   * @return {float} energyCostsYear
   */
  energyCostsPerYear: (leak, customer) => {
    const costsPerHour = LeakCalcLiterMinPressureService.pressureCostsPerHour(leak, customer);
    const energyCostsYear = costsPerHour * parseFloat(customer.parameter.operatingHours);

    return energyCostsYear;
  },

  /**
   * calculate the energy consumption per year
   * @return {float} energyConsumptionYear
   */
  energyConsumptionPerYear: (leak, customer) => {
    let energyConsumptionYear;
    const powerFactor = LeakCalcLiterMinPressureService.getPowerFactorValue(leak, customer);

    // Energiekosten €/Jahr / (Druckluftpreis / 1000 / spezifische Leistung)
    if (
      customer.parameter.airPressurePrice !== undefined &&
      customer.parameter.airPressurePrice !== '' &&
      powerFactor !== undefined
    ) {
      const energyCostYear = LeakCalcLiterMinPressureService.energyCostsPerYear(leak, customer);
      energyConsumptionYear =
        energyCostYear / (parseFloat(customer.parameter.airPressurePrice) / 1000 / powerFactor);
    }

    return energyConsumptionYear;
  },

  /**
   * calculate the energy consumption
   * @return {float} energyConsumption
   */
  energyConsumption: (leak, customer) => {
    let energyConsumption;

    const energyConsumptionPerYear = LeakCalcLiterMinPressureService.energyConsumptionPerYear(
      leak,
      customer
    );

    if (energyConsumptionPerYear !== undefined) {
      energyConsumption = energyConsumptionPerYear / parseFloat(customer.parameter.operatingHours);
    }

    return energyConsumption;
  },

  pressureCostsPerHour: (leak, customer) => {
    let costsPerHour = 0;

    if (
      customer.parameter.airPressurePrice !== undefined &&
      customer.parameter.airPressurePrice !== ''
    ) {
      const airLossVolume = LeakCalcLiterMinPressureService.airLossVolume(leak, customer);
      const pressurePrice = parseFloat(customer.parameter.airPressurePrice);
      costsPerHour = (airLossVolume * pressurePrice) / 1000;
    }

    return costsPerHour;
  },

  /**
   * calculate the air loss volume
   * @return {float} airLossVolume
   */
  airLossVolume: (leak, customer) => {
    const airLoss = LeakCalcLiterMinPressureService.airLossBase(leak, customer);
    const airLossVolume = airLoss / 1000;

    return airLossVolume;
  },

  /**
   * calculate the air loss based on the measured value
   * @return {float} airLoss
   */
  airLossBase: (leak) => {
    const measured = parseFloat(leak.measuredValue);
    const airLoss = measured * 60;
    return airLoss;
  },

  calculateValence: (leak) => {
    let valence = VALENCY_TYPES.low;
    const { measuredValue } = leak;

    if (measuredValue) {
      if (measuredValue <= 4) {
        valence = VALENCY_TYPES.low;
      } else if (measuredValue > 4 && measuredValue <= 10.2) {
        valence = VALENCY_TYPES.mid;
      } else {
        valence = VALENCY_TYPES.high;
      }
    }

    return valence;
  }
};

export default LeakCalcLiterMinPressureService;
