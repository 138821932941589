function useFlattenFirebaseDoc() {
  const flattenLeak = (doc) => {
    const item = { ...doc };

    delete item.id;
    delete item.ref;

    delete item.components;
    delete item.repairComponents;
    delete item.customer;
    delete item.qrCode;
    delete item.updatedAt;
    delete item.updatedBy;
    delete item.createdBy;
    delete item.repairedBy;

    const { createdAt } = doc;
    delete item.createdAt;
    item.createdAtTimestamp = createdAt.toDate().getTime();
    item.createdAtDate = createdAt.toDate();

    if (doc.repaired && doc.repairedAt !== null) {
      const { repairedAt } = doc;
      delete item.repairedAt;
      item.repairedAtTimestamp = repairedAt.toDate().getTime();
      item.repairedAtDate = repairedAt.toDate();
    } else {
      delete item.repairedAt;
    }

    return item;
  };

  const flattenCustomer = (doc) => {
    const item = { ...doc };

    delete item.id;
    delete item.ref;

    delete item.childIds;
    delete item.childMap;
    delete item.parent;
    delete item.createdAt;
    delete item.createdBy;
    delete item.updatedAt;
    delete item.updatedBy;

    return item;
  };

  return {
    flattenLeak,
    flattenCustomer
  };
}

export default useFlattenFirebaseDoc;
