import { MEASURED_DEVICE_TYPES, MEASURED_VALUE_CALC_METHODS } from '@looxr/constants';
import LeakCalcDecibelService from './LeakCalcDecibelService';
import LeakCalcLiterMinElectricService from './LeakCalcLiterMinElectricService';
import LeakCalcLiterMinPressureService from './LeakCalcLiterMinPressureService';

const LeakCalcService = {
  /**
   * Get the correct calc service for leak based on measured device
   * @param {*} leak
   * @param {*} customer
   */
  getCalcServiceForLeak: (leak, customer) => {
    let calcService = LeakCalcDecibelService;
    let deviceType = leak.measuredDeviceType;

    if (!deviceType && customer !== undefined) {
      deviceType = customer.parameter.measuredDeviceType;
      console.warn('leak should have info about device, using customer info insteas', leak);
    }

    const deviceConfig = MEASURED_DEVICE_TYPES[deviceType];

    switch (deviceConfig.calcMethod) {
      case MEASURED_VALUE_CALC_METHODS.dbmvWithElectric:
        calcService = LeakCalcDecibelService;
        break;
      case MEASURED_VALUE_CALC_METHODS.airBaseWithElectric:
        calcService = LeakCalcLiterMinElectricService;
        break;
      case MEASURED_VALUE_CALC_METHODS.airBaseWithPressure:
        calcService = LeakCalcLiterMinPressureService;
        break;
      default:
        console.warn('could not define correct calculation service, using default');
        calcService = LeakCalcDecibelService;
        break;
    }

    return calcService;
  },

  /**
   * Calculate and return all leak values
   *
   * @param {object} leak
   * @param {object} customer
   * @return {object} calcValues
   */
  calculateValues: (leak, customer) => {
    const calcValues = {};

    calcValues.airLoss = LeakCalcService.airLossBase(leak, customer);
    calcValues.energyConsumption = LeakCalcService.energyConsumption(leak, customer);
    calcValues.energyConsumptionYear = LeakCalcService.energyConsumptionPerYear(leak, customer);
    calcValues.energyCosts = LeakCalcService.energyCostsPerYear(leak, customer);
    calcValues.emissionPerYear = LeakCalcService.emissionPerYear(leak, customer);

    return calcValues;
  },

  /**
   * calculate the emission per year
   * @return {float} emissionYear
   */
  emissionPerYear: (leak, customer) => {
    const calcService = LeakCalcService.getCalcServiceForLeak(leak, customer);
    return calcService.emissionPerYear(leak, customer);
  },

  /**
   * calculate the energy costs per year
   * @return {float} energyCostsYear
   */
  energyCostsPerYear: (leak, customer) => {
    const calcService = LeakCalcService.getCalcServiceForLeak(leak, customer);
    return calcService.energyCostsPerYear(leak, customer);
  },

  /**
   * calculate the energy consumption per year
   * @return {float} energyConsumptionYear
   */
  energyConsumptionPerYear: (leak, customer) => {
    const calcService = LeakCalcService.getCalcServiceForLeak(leak, customer);
    return calcService.energyConsumptionPerYear(leak, customer);
  },

  /**
   * calculate the energy consumption
   * @return {float} energyConsumption
   */
  energyConsumption: (leak, customer) => {
    const calcService = LeakCalcService.getCalcServiceForLeak(leak, customer);
    return calcService.energyConsumption(leak, customer);
  },

  /**
   * calculate the air loss volume
   * @return {float} airLossVolume
   */
  airLossVolume: (leak, customer) => {
    const calcService = LeakCalcService.getCalcServiceForLeak(leak, customer);
    return calcService.airLossVolume(leak, customer);
  },

  /**
   * calculate the air loss based on the measured value
   * @return {float} airLoss
   */
  airLossBase: (leak, customer) => {
    const calcService = LeakCalcService.getCalcServiceForLeak(leak, customer);
    return calcService.airLossBase(leak, customer);
  },

  calculateValence: (leak) => {
    const calcService = LeakCalcService.getCalcServiceForLeak(leak);
    return calcService.calculateValence(leak);
  }
};

export default LeakCalcService;
