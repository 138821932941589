import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  Alert,
  Banner,
  Box,
  Button,
  ContentBox,
  Label,
  LoadingOverlay,
  SelectRH,
  Text,
  TextInput,
  TextInputRH
} from '@looxr/components';
import { getLeakRoleOptions, getPressureRoleOptions, getSalutationOptions } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { ApiService, AppStateService, FirebaseService } from '../../services';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

function Profile() {
  const tn = useTranslation();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: AppStateService.user
  });

  const [loading, setLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [passwordNew, setPasswordNew] = useState('');
  const [passwordNewRepeat, setPasswordNewRepeat] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);

  useEffect(() => {
    if (
      passwordNew &&
      passwordNew.length > 0 &&
      passwordNewRepeat &&
      passwordNewRepeat.length > 0 &&
      passwordNew === passwordNewRepeat
    ) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [passwordNew, passwordNewRepeat]);

  const save = async (formData) => {
    const userDoc = AppStateService.user;
    const userData = { ...userDoc };

    delete userData.id;
    delete userData.ref;

    const update = { ...userData, ...formData };

    // make sure email was not edited
    update.email = userData.email;

    setLoading(true);
    try {
      await FirebaseService.updateProfileData(userDoc.id, update);

      // @TODO update onelogin user ???
      // @TODO send update to druckluft API
      setLoading(false);
    } catch (e) {
      // @TODO handle error
      console.log(e);
    }
  };

  const changePassword = async () => {
    setLoading(true);
    const result = await ApiService.changePassword(passwordNew);

    if (result !== false) {
      setLoading(false);
      setShowSuccessPopup(true);
    } else {
      setLoading(false);
      setShowErrorPopup(true);
    }
  };

  const renderRoleText = (loginType) => {
    let text = '-';
    const data = AppStateService.user;

    if (loginType === 'leak' && data.logins && data.logins.leak === true && data.leakLogin.role) {
      const option = getLeakRoleOptions().filter(
        (option) => option.value === data.leakLogin.role
      )[0];

      if (option) {
        text = option.label;
      }
    } else if (
      loginType === 'pressure' &&
      data.logins &&
      data.logins.pressure === true &&
      data.pressureLogin.role
    ) {
      const option = getPressureRoleOptions().filter(
        (option) => option.value === data.pressureLogin.role
      )[0];

      if (option) {
        text = option.label;
      }
    }

    return text;
  };

  const renderRoleDateText = (loginType) => {
    let text = '-';
    const data = AppStateService.user;

    if (
      loginType === 'leak' &&
      data.logins &&
      data.logins.leak === true &&
      data.leakLogin.validUntil
    ) {
      text = moment(data.leakLogin.validUntil.toDate()).format('DD.MM.YYYY');
    } else if (
      loginType === 'pressure' &&
      data.logins &&
      data.logins.pressure === true &&
      data.pressureLogin.validUntil
    ) {
      text = moment(data.pressureLogin.validUntil.toDate()).format('DD.MM.YYYY');
    }
    return text;
  };

  return (
    <>
      <Banner
        text={tn('general.page.profile.headline')} // "Account Einstellungen"
      />
      <Box paddingX={10} paddingY={10} minHeight="calc(100vh - 143px)">
        <ContentBox
          title={tn('general.page.profile.general')} //  "Allgemein"
        >
          <form onSubmit={handleSubmit(save)} noValidate>
            <Box display="flex" direction="column">
              <StyledFormBox>
                <Box marginY={3} marginX={3} width={200}>
                  <Label htmlFor="salutation" required valid={errors.salutation === undefined}>
                    {tn('general.salutation')}
                    {/* Anrede */}
                  </Label>
                  <SelectRH
                    id="salutation"
                    name="salutation"
                    placeholder={tn('general.choose')} // "Bitte wählen"
                    options={getSalutationOptions()}
                    ref={register({ required: true })}
                  />
                </Box>

                <Box marginY={3} marginX={3} width={200}>
                  <Label htmlFor="firstname" required valid={errors.firstname === undefined}>
                    {tn('general.firstname')}
                    {/* Vorname */}
                  </Label>
                  <TextInputRH id="firstname" name="firstname" ref={register({ required: true })} />
                </Box>

                <Box marginY={3} marginX={3} width={200}>
                  <Label htmlFor="lastname" required valid={errors.lastname === undefined}>
                    {tn('general.lastname')}
                    {/* Nachname */}
                  </Label>
                  <TextInputRH id="lastname" name="lastname" ref={register({ required: true })} />
                </Box>
                <Box marginY={3} marginX={3} width={200}>
                  <Label htmlFor="email" required valid={errors.email === undefined}>
                    {tn('general.email')}
                    {/* E-Mail */}
                  </Label>
                  <TextInputRH
                    id="email"
                    name="email"
                    disabled={true}
                    ref={register({ required: true })}
                  />
                </Box>
                <Box
                  display="flex"
                  marginY={3}
                  marginX={3}
                  width={200}
                  height={69}
                  justify="flex-end"
                  alignItems="flex-end"
                  alignContent="center"
                >
                  <Button
                    background="green"
                    text={tn('general.save')} // "Speichern"
                    type="submit"
                  />
                </Box>
              </StyledFormBox>
            </Box>
          </form>
        </ContentBox>

        <ContentBox
          title={tn('general.page.profile.leakLogin')} // "Leckage Zugang"
          marginTop={5}
        >
          <Box display="flex" direction="column">
            <StyledFormBox>
              <Box marginY={3} marginX={3} width={200}>
                <Label htmlFor="leakRole">
                  {tn('general.role')}
                  {/* Rolle */}
                </Label>
                <Text>{renderRoleText('leak')}</Text>
              </Box>
              <Box marginY={3} marginX={3} width={200}>
                <Label htmlFor="leakValidUntil">
                  {tn('general.validUntil')}
                  {/* Gültig bis */}
                </Label>
                <Text>{renderRoleDateText('leak')}</Text>
              </Box>
            </StyledFormBox>
          </Box>
        </ContentBox>

        <ContentBox
          title={tn('general.page.profile.pressureLogin')} // "Druckluft Zugang"
          marginTop={5}
        >
          <Box display="flex" direction="column">
            <StyledFormBox>
              <Box marginY={3} marginX={3} width={200}>
                <Label htmlFor="leakRole">
                  {tn('general.role')}
                  {/* Rolle */}
                </Label>
                <Text>{renderRoleText('pressure')}</Text>
              </Box>
              <Box marginY={3} marginX={3} width={200}>
                <Label htmlFor="leakValidUntil">
                  {tn('general.validUntil')}
                  {/* Gültig bis */}
                </Label>
                <Text>{renderRoleDateText('pressure')}</Text>
              </Box>
            </StyledFormBox>
          </Box>
        </ContentBox>

        <ContentBox
          title={tn('general.page.profile.changePassword')} // "Passwort ändern"
          marginTop={5}
        >
          <Box display="flex" direction="column">
            <StyledFormBox>
              <Box marginY={3} marginX={3} width={200}>
                <Label htmlFor="passwordNew">
                  {tn('general.password')}
                  {/* Passwort */}
                </Label>
                <TextInput
                  id="passwordNew"
                  name="passwordNew"
                  value={passwordNew}
                  onChange={(e) => setPasswordNew(e.currentTarget.value)}
                />
              </Box>
              <Box marginY={3} marginX={3} width={200}>
                <Label htmlFor="passwordNewRepeat">
                  {tn('general.page.profile.repeatPassword')}
                  {/* Passwort (wiederholen) */}
                </Label>
                <TextInput
                  id="passwordNewRepeat"
                  name="passwordNewRepeat"
                  value={passwordNewRepeat}
                  onChange={(e) => setPasswordNewRepeat(e.currentTarget.value)}
                />
              </Box>
              <Box
                display="flex"
                marginY={3}
                marginX={3}
                width={200}
                height={69}
                justify="flex-end"
                alignItems="flex-end"
                alignContent="center"
              >
                <Button
                  background="purple"
                  text={tn('general.page.profile.buttons.submit')} // "Passwort ändern"
                  disabled={!passwordMatch}
                  onClick={() => changePassword()}
                />
              </Box>
            </StyledFormBox>
          </Box>
        </ContentBox>
      </Box>

      <Alert
        show={showErrorPopup}
        title={tn('general.alert.errorOnPasswordSave.title')} // "Fehler beim Speichern"
        message={tn('general.alert.errorOnPasswordSave.text')} // "Es ist ein Fehler beim speichern des Passworts aufgetreten."
        btnText={tn('general.alert.errorOnPasswordSave.button')} // "Ok"
        btnAction={() => setShowErrorPopup(false)}
      />

      <Alert
        show={showSuccessPopup}
        title={tn('general.alert.passwordChanged.title')} // "Password wurde erfolgreich geändert"
        message={tn('general.alert.passwordChanged.text')} // "Ihr Passwort wurde erfolgreich geändert."
        btnText={tn('general.alert.passwordChanged.button')} // "Ok"
        btnAction={() => setShowSuccessPopup(false)}
      />

      <LoadingOverlay isVisible={loading} />
    </>
  );
}

export default Profile;
