import PropTypes from 'prop-types';
import React from 'react';
import { Box, Text } from '@looxr/components';

function TextBlock({ label, value, width, children }) {
  return (
    <Box flexShrink="0" width={width}>
      <Text size="sm" bold>
        {label}
      </Text>
      {value ? <Text size="sm">{value}</Text> : children}
    </Box>
  );
}

TextBlock.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string
};

TextBlock.defaultProps = {
  children: null,
  label: '',
  value: '',
  width: '20%'
};

export default TextBlock;
